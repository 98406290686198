import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import TextArea from "../../../components/TextArea";
import Select from "react-select";
import classnames from "classnames";
import { Locations } from "../../../helpers/constant_variables";
import common from "../../../helpers/common";
import { WithContext as ReactTags} from "react-tag-input";

const EmploymentTypes = [
  {
    value: 1,
    label: "Full-Time Employee",
  },
  {
    value: 2,
    label: "Part-Time Employee",
  },
  {
    value: 3,
    label: "Temporary Employee",
  },
  {
    value: 4,
    label: "Seasonal Employee",
  },
];

const Domains = [
    {
    value: 1,
    label: 'IT/ITES',
    skills: [
      { "label": "Analytics", id: "Analytics" },
      { "label": "Application Development", id: "Application Development" },
      { "label": "Application Maintenance", id: "Application Maintenance" },
      { "label": "Artificial Intelligence and Big Data Analytics", id: "Artificial Intelligence and Big Data Analytics" },
      { "label": "Cloud Computing", id: "Cloud Computing" },
      { "label": "Data Scientists", id: "Data Scientists" },
      { "label": "Customer Relationship Management", id: "Customer Relationship Management" },
      { "label": "Editorial and DTP", id: "Editorial and DTP" },
      { "label": "Engineering Analysis", id: "Engineering Analysis" },
      { "label": "Finance and Accounting", id: "Finance and Accounting" },
      { "label": "Generic", id: "Generic" },
      { "label": "Hardware Development", id: "Hardware Development" },
      { "label": "Hardware Testing", id: "Hardware Testing" },
      { "label": "Human Resource Outsourcing", id: "Human Resource Outsourcing" },
      { "label": "Information Security", id: "Information Security" },
      { "label": "Information/Cyber Security", id: "Information/Cyber Security" },
      { "label": "Infrastructure Management Services (IMS)", id: "Infrastructure Management Services (IMS)" },
      { "label": "Internet of Things", id: "Internet of Things" },
      { "label": "IT Consulting", id: "IT Consulting" },
      { "label": "IT Support Services/Helpdesk", id: "IT Support Services/Helpdesk" },
      { "label": "IT Support Servies/Helpdesk", id: "IT Support Servies/Helpdesk" },
      { "label": "Knowledge Process Outsourcing - Research", id: "Knowledge Process Outsourcing - Research" },
      { "label": "Legal", id: "Legal" },
      { "label": "Legal BPO", id: "Legal BPO" },
      { "label": "Processing", id: "Processing" },
      { "label": "Product Engineering Design", id: "Product Engineering Design" },
      { "label": "Product Lifecycle Management", id: "Product Lifecycle Management" },
      { "label": "Product Manufacturing Support", id: "Product Manufacturing Support" },
      { "label": "Product Research and Design", id: "Product Research and Design" },
      { "label": "Product Support", id: "Product Support" },
      { "label": "Quality Assurance and Engineering", id: "Quality Assurance and Engineering" },
      { "label": "Sales & Marketing/Business Development", id: "Sales & Marketing/Business Development" },
      { "label": "Sales and Pre-Sales", id: "Sales and Pre-Sales" },
      { "label": "Software Development", id: "Software Development" },
      { "label": "Software Testing", id: "Software Testing" },
      { "label": "Technical Documentation Writing", id: "Technical Documentation Writing" },
      { "label": "Testing and QA", id: "Testing and QA" },
      { "label": "Web and Mobile Development", id: "Web and Mobile Development" }
    ]
  },
  {
    value: 2,
    label: 'Ecommerce',
    skills: [
      { "label": "Digital Marketing", id: "Digital Marketing" },
      { "label": "Search Engine Optimization", id: "Search Engine Optimization" },
      { "label": "Data Analytics", id: "Data Analytics" },
      { "label": "CRM", id: "CRM" },
      { "label": "Testing", id: "Testing" },
      { "label": "Website Design", id: "Website Design" },
      { "label": "Advertising", id: "Advertising" },
      { "label": "Artificial intelligence", id: "Artificial intelligence" },
      { "label": "Automation", id: "Automation" },
      { "label": "Software development", id: "Software development" },
      { "label": "Web hosting", id: "Web hosting" }
    ]
  },
  {
    value: 3,
    label: 'Pharma & Healthcare',
    skills: [
      { "label": "Clinical Research", id: "Clinical Research" },
      { "label": "Quality Assurance", id: "Quality Assurance" },
      { "label": "Bioinformatics", id: "Bioinformatics" },
      { "label": "Pharma Sales", id: "Pharma Sales" },
      { "label": "Product Development", id: "Product Development" },
      { "label": "Pharmacy Technicians", id: "Pharmacy Technicians" },
      { "label": "Healthcare Analytics", id: "Healthcare Analytics" },
      { "label": "Healthcare Sales", id: "Healthcare Sales" },
      { "label": "Healthcare Complaince", id: "Healthcare Complaince" },
      { "label": "Healthcare Consulting", id: "Healthcare Consulting" },
      { "label": "Basic Life Support(BFS)", id: "Basic Life Support(BFS)" }
    ]
  },
  {
    value: 5,
    label: 'Banking & Financials',
    skills: [
      { "label": "Securities & Stockbroking", id: "Securities & Stockbroking" },
      { "label": "Investment Banking", id: "Investment Banking" },
      { "label": "Retail Banking", id: "Retail Banking" },
      { "label": "Insurance", id: "Insurance" },
      { "label": "Assest Management", id: "Assest Management" },
      { "label": "Sales & marketing", id: "Sales & marketing" },
      { "label": "Commercial Banking", id: "Commercial Banking" },
      { "label": "Private Banking", id: "Private Banking" },
      { "label": "Core Banking", id: "Core Banking" },
      { "label": "Internet Banking", id: "Internet Banking" },
      { "label": "Banking Law", id: "Banking Law" },
      { "label": "Banking Relationships", id: "Banking Relationships" },
      { "label": "Banking Software", id: "Banking Software" },
      { "label": "SME Banking", id: "SME Banking" },
      { "label": "Accounting", id: "Accounting" },
      { "label": "Financial Advisory", id: "Financial Advisory" },
      { "label": "Financial Planning", id: "Financial Planning" },
      { "label": "Financial Risk", id: "Financial Risk" }
    ]
  }
];

const DiversityList = [
  {
    value: 1,
    label: "Male",
  },
  {
    value: 2,
    label: "Female",
  },
  {
    value: 3,
    label: "NA",
  },
];

const MaxAgeList = [
  {
    value: 1,
    label: "20",
  },
  {
    value: 2,
    label: "30",
  },
  {
    value: 3,
    label: "40",
  },
  {
    value: 4,
    label: "50",
  },
];

const MinAgeList = [
  {
    value: 1,
    label: "18",
  },
  {
    value: 2,
    label: "21",
  },
  {
    value: 3,
    label: "30",
  },
  {
    value: 4,
    label: "40",
  },
];

const EducationList = [
  {
    value: 1,
    label: "X",
  },
  {
    value: 2,
    label: "XII",
  },
  {
    value: 3,
    label: "Graduation and above",
  },
];

const JobForm = (props) => {
  const [editedData, setEditedData] = useState({});
  const [skillsMustArray, setSkillsMustArray] = useState([]);
  const [skillsGoodArray, setSkillsGoodArray] = useState([]);

  const KeyCodes = {
    tab: 9,
    enter: 13,
  };

  const delimiters = [KeyCodes.tab, KeyCodes.enter];

  const handleDelete = (i) => {
    const tags = [...props.jobfields?.skills_must];
    const tempTag = [...tags.filter((tag, index) => index !== i)];
    props.RemoveSkill(i, "mustskills");
  };

  const handleAddition = (tag) => {
    console.log("In");
    tag.label = tag.label.trim();
    if (/^[a-zA-Z\s]+$/.test(tag.label)) {
      console.log("INN");
      const tempTag = [...props.jobfields?.skills_must, tag.label];
      console.log(tempTag)
      props.onChangeTextareaHandler(tempTag, "mustskills");
    }
  };

  const handleDeleteG = (i) => {
    const tags = [...props.jobfields?.skills_good];
    const tempTag = [...tags.filter((tag, index) => index !== i)];
    props.RemoveSkill(i, "goodskills");
  };

  const handleAdditionG = (tag) => {
    console.log("In");
    tag.label = tag.label.trim();
    if (/^[a-zA-Z\s]+$/.test(tag.label)) {
      console.log("INN");
      const tempTag = [...props.jobfields?.skills_good, tag.label];
      console.log(tempTag)
      props.onChangeTextareaHandler(tempTag, "goodskills");
    }
  };

  console.log(props.jobfields);

  useEffect(()=>{
    
    if(props.jobfields.roleandResponsibilty.length>0 && props.roleCharComplete===0){
      var regex = /\s+/gi;
      var WordCount =
      props.jobfields.roleandResponsibilty === ""
          ? 0
          : props.jobfields.roleandResponsibilty.trim().replace(regex, " ").split(" ").length;
          props.setRoleCharComplete(WordCount);
    }
    if(props.jobfields.otherDetails.length>0 && props.otherDetailsCharComplete===0){
      var regex = /\s+/gi;
      var WordCount =
      props.jobfields.otherDetails === ""
          ? 0
          : props.jobfields.otherDetails.trim().replace(regex, " ").split(" ").length;
          props.setOtherDetailsCharComplete(WordCount);;
    }
    if(props.jobfields.specification.length>0 && props.specificationCharComplete===0){
      var regex = /\s+/gi;
      var WordCount =
      props.jobfields.specification === ""
          ? 0
          : props.jobfields.specification.trim().replace(regex, " ").split(" ").length;
          props.setSpecificationCharComplete(WordCount);;
    }
    if (props.jobfields.domain) {
      const getDomainIndex = props.findWithAttr(Domains, "label", props.jobfields.domain);
      const results = Domains[getDomainIndex].skills.filter((person) => {
        if (props.jobfields.mustSkillVal) {
          return person.label.toLowerCase().includes(props.jobfields.mustSkillVal);
        } else if (props.jobfields.goodSkillVal) {
          return person.label.toLowerCase().includes(props.jobfields.goodSkillVal);
        } else {
          return Domains[getDomainIndex].skills;
        }
      });
      props.setJobfields({
        ...props.jobfields,
        d_skills: results,
      });
    }
  },[props.jobfields.roleandResponsibilty,props.jobfields.otherDetails,props.jobfields.specification])

  // useEffect(() => {
  //     if(props.location && props.location.state){
  //         setEditedData({...props.location.state})
  //     }
  //   // console.log('props',props.location.state)
  // }, []);

  const ChangeSkills = (e, type) => {
    if (e.key === "Enter") {
      if (type === "mustskill") {
        const tempData = [...skillsMustArray];
        //let obj = {name:e.target.value,proficiency:'begineer'};
        tempData.push(e.target.value);
        setSkillsMustArray([...tempData]);
      } else {
        const tempData = [...skillsGoodArray];
        //let obj = {name:e.target.value,proficiency:'begineer'};
        tempData.push(e.target.value);
        setSkillsGoodArray([...tempData]);
      }
    }
  };

  const RemoveSkill = (index, type) => {
    if (type === "mustskill") {
      const tempData = [...skillsMustArray];
      tempData.splice(index, 1);
      setSkillsMustArray([...tempData]);
    } else {
      const tempData = [...skillsGoodArray];
      tempData.splice(index, 1);
      setSkillsGoodArray([...tempData]);
    }
  };

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={1} className="personal-details job-form">
        <Form>
          <Row>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">
                  Job Title <span className="label-required"> * </span>{" "}
                </Label>
                <Input
                  maxLength="40"
                  type="text"
                  value={props.jobfields.title}
                  onChange={(event) =>
                    props.onChangeTextareaHandler(event, "title")
                  }
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="mb-4 text-left">
                <Label>
                  Company <span className="label-required"> * </span>
                </Label>
                <Select
                  className="select-container"
                  value={props.orgObj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "company")
                  }
                  options={props.orgList}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            
            <Col lg="6">
              <FormGroup className="cust-age-width text-left">
                <Label for="basicpill-lastname-input2">
                  Location <span className="label-required"> * </span>
                </Label>
                <Select
                  className="select-container"
                  value={props.jobfields.locationObj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "location")
                  }
                  options={common.getSortedArray(Locations, "label")}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                  isDisabled={props.jobfields.locationDisabled}
                />
                <FormGroup className="pt-2" check>
                  <Label className="main" check>
                    <Input
                      type="checkbox"
                      checked={props.jobfields.isRemoteLoc}
                      onChange={(event) =>
                        props.onChangeTextareaHandler(event, "remote")
                      }
                    />{" "}
                    This is a remote job
                    <span className="geekmark"></span>
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">Domain</Label>
                <Select
                  className="select-container"
                  value={props.jobfields.domainObj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "domain")
                  }
                  options={Domains}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">Employment Type</Label>
                <Select
                  className="select-container"
                  value={props.jobfields.emp_type_obj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "emptype")
                  }
                  options={EmploymentTypes}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Skills - Must have{" "}
                </Label>
                <ReactTags
                  tags={props.jobfields?.skills_must?.map((e) => ({
                    id: e,
                    label: e,
                  }))}
                  suggestions={props.jobfields.d_skills || []}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  minQueryLength={0}
                  allowDragDrop={false}
                  placeholder="Type Skill and Click Enter If Not Found"
                  inputFieldPosition="top"
                  labelField={"label"}
                  renderSuggestion={({ label }, query) => (
                    <div style={{ marginLeft: "0px" }}>{label}</div>
                  )}
                />
                {/* <Input
                  type="text"
                  placeholder="Type skills and enter"
                  onKeyDown={(e) =>
                    props.onChangeTextareaHandler(e, "mustskills")
                  }
                  name="select"
                ></Input>
                {props.jobfields.skills_must &&
                  props.jobfields.skills_must.length > 0 && (
                    <ul className="skills-learns pl-0 mt-2 mb-0">
                      {props.jobfields.skills_must &&
                        props.jobfields.skills_must.map((item, idx) => {
                          return (
                            <li
                              className={classnames(
                                "d-inline-block mr-2 skills-items skill-container",
                                { "mb-1": skillsMustArray.length - 1 !== idx }
                              )}
                              key={idx}
                            >
                              <span className="mr-1"> {item} </span>
                              <span
                                className="img-close cursor-pointer"
                                onClick={() =>
                                  props.RemoveSkill(idx, "mustskills")
                                }
                              >
                                &times;
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  )} */}
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Compensation  
                                    <span className='ml-1 font-wight-light font-size-11 text-muted'>(Optional)</span></Label>
                                <Input type="select" name="select" >
                                    <option>Select</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                </Input>       
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Job Level</Label>
                                <Input type="select" name="select" >
                                    <option>Senior</option>
                                    <option>Junior</option>
                                </Input>       
                            </FormGroup>
                        </Col>
                    </Row>          */}
          <Row className="border-bottom">
            
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Skills - Good to have{" "}
                </Label>
                <ReactTags
                  tags={props.jobfields?.skills_good?.map((e) => ({
                    id: e,
                    label: e,
                  }))}
                  suggestions={props.jobfields.d_skills || []}
                  delimiters={delimiters}
                  handleDelete={handleDeleteG}
                  handleAddition={handleAdditionG}
                  minQueryLength={0}
                  allowDragDrop={false}
                  placeholder="Type Skill and Click Enter If Not Found"
                  inputFieldPosition="top"
                  labelField={"label"}
                  renderSuggestion={({ label }, query) => (
                    <div style={{ marginLeft: "0px" }}>{label}</div>
                  )}
                />
                {/* <Input
                  type="text"
                  placeholder="Type skills and enter"
                  onKeyDown={(e) =>
                    props.onChangeTextareaHandler(e, "goodskills")
                  }
                  name="select"
                ></Input>
                {props.jobfields.skills_good &&
                  props.jobfields.skills_good.length > 0 && (
                    <ul className="skills-learns pl-0 mt-2 mb-0">
                      {props.jobfields.skills_good &&
                        props.jobfields.skills_good.map((item, idx) => {
                          return (
                            <li
                              className={classnames(
                                "d-inline-block mr-2 skills-items skill-container",
                                { "mb-1": skillsGoodArray.length - 1 !== idx }
                              )}
                              key={idx}
                            >
                              <span className="mr-1"> {item} </span>
                              <span
                                className="img-close cursor-pointer"
                                onClick={() =>
                                  props.RemoveSkill(idx, "goodskills")
                                }
                              >
                                &times;
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  )} */}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left position-relative">
                <Label for="basicpill-lastname-input2">
                  Min Experience
                  <span className="ml-1 font-wight-light font-size-11 text-muted">
                    (Optional)
                  </span>
                </Label>
                <Input
                  type="tel"
                  maxLength={2}
                  value={props.jobfields.minexp || ""}
                  onChange={(event) =>
                    props.onChangeTextareaHandler(event, "minexp")
                  }
                ></Input>
                <span className="year-text">years</span>
              </FormGroup>
            </Col>
          </Row>
          <Row className="pb-2 mt-2">
            
            <Col lg="6">
              <FormGroup className="text-left position-relative">
                <Label for="basicpill-lastname-input2">
                  Max Experience
                  <span className="ml-1 font-wight-light font-size-11 text-muted">
                    (Optional)
                  </span>
                </Label>
                <Input
                  type="tel"
                  maxLength={2}
                  value={props.jobfields.maxexp || ""}
                  onChange={(event) =>
                    props.onChangeTextareaHandler(event, "maxexp")
                  }
                ></Input>
                <span className="year-text">years</span>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">Education Details</Label>
                <Select
                  className="select-container"
                  value={props.jobfields.edetailsObj}
                  onChange={(selectedOption) =>
                    props.onChangeTextareaHandler(selectedOption, "edetails")
                  }
                  options={EducationList}
                  isSearchable
                  innerProps={{ className: "test" }}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
          </Row>
          
          {/* <Row className="pt-2"> */}
            {/* <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Diversity</Label>
                                <Select
                                    className="select-container"
                                    value={props.jobfields.diversityObj}
                                    onChange={(selectedOption) => props.onChangeTextareaHandler(selectedOption, 'diversity')}
                                    options={DiversityList}
                                    isSearchable
                                    innerProps={{ className: "test" }}
                                    placeholder="Select"
                                />
                            </FormGroup>
                        </Col> */}
            
          {/* </Row> */}
          {/* <Row className='border-bottom'>
                        <Col lg="6">
                            <FormGroup className="text-left position-relative">
                                <Label for="basicpill-lastname-input2">Min Age
                                <span className='ml-1 font-wight-light font-size-11 text-muted'>(Optional)</span>
                                </Label>
                                <Select
                                    className="select-container"
                                    value={props.jobfields.minAgeObj}
                                    onChange={(selectedOption) => props.onChangeTextareaHandler(selectedOption, 'minage')}
                                    options={MinAgeList}
                                    isSearchable
                                    innerProps={{ className: "test" }}
                                    placeholder="Select"
                                />
                                <span className='year-text'>years</span>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="text-left position-relative">
                                <Label for="basicpill-lastname-input2">Max Age
                                    <span className='ml-1 font-wight-light font-size-11 text-muted'>(Optional)</span>
                                </Label>
                                <Select
                                    className="select-container"
                                    value={props.jobfields.maxAgeObj}
                                    onChange={(selectedOption) => props.onChangeTextareaHandler(selectedOption, 'maxage')}
                                    options={MaxAgeList}
                                    isSearchable
                                    innerProps={{ className: "test" }}
                                    placeholder="Select"
                                />
                                <span className='year-text'>years</span>
                            </FormGroup>
                        </Col>
                    </Row> */}
          <Row className="pt-2">
            <Col lg="12">
              <FormGroup className="text-left">
                <TextArea
                  labelName="Roles and Responsibilities"
                  onChangeTextareaHandler={(event) =>
                    props.onChangeTextareaHandler(event, "roles")
                  }
                  isCharLimitShow={true}
                  charlimitLength="500"
                  charComplete={props.roleCharComplete}
                  value={props.jobfields.roleandResponsibilty}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <TextArea
                  labelName="Other details related to the job"
                  isOptional={true}
                  onChangeTextareaHandler={(event) =>
                    props.onChangeTextareaHandler(event, "otherdetails")
                  }
                  isCharLimitShow={true}
                  charlimitLength="500"
                  charComplete={props.otherDetailsCharComplete}
                  value={props.jobfields.otherDetails}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <TextArea
                  labelName="Specifications related to additional benefits/perks/entitlements"
                  isOptional={true}
                  onChangeTextareaHandler={(event) =>
                    props.onChangeTextareaHandler(event, "specification")
                  }
                  isCharLimitShow={true}
                  charlimitLength="500"
                  charComplete={props.specificationCharComplete}
                  value={props.jobfields.specification}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default JobForm;
