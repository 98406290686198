import React, { useState } from 'react';
import { TabContent, TabPane, Row, Col } from "reactstrap";

import ApiService from '../../config/restapi';
import api from '../../config/axios';
import pdf_icon from '../../assets/images/pdf_icon.png'



const Cadidature = (props) => {

    const [resume_modal, setresume_modal] = useState(false);
    const [candidature_modal, setcandidature_modal] = useState(false);


    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const openResumeModal = () => {
        setresume_modal(!resume_modal);
        removeBodyCss();
    }

    const openCandidatureModal = () => {
        setcandidature_modal(!candidature_modal);
        removeBodyCss();
    }

    const updateResume = (file) => {
        api.post('api/jobs/upload/resume', file, {
            headers: {
                token: `${localStorage.getItem("token")}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then((result) => {
            ApiService.saveOnboardingPersonalDetails({ resume_url: result.data.data.Location }).then((res) => {
                if (res && res.status === 200 && res.data) {
                    props.getAllUserData();
                    setresume_modal(false);
                }
            });
        });
    }
    const deleteResume = () => {
        ApiService.saveOnboardingPersonalDetails({ resume_url: null }).then((res) => {
            if (res && res.status === 200 && res.data) {
                props.getAllUserData();
                setresume_modal(false);
            }
        });
    }
    const updateCandidature = (data) => {
        ApiService.saveOnboardingPersonalDetails({ video_url: data }).then((res) => {
            if (res && res.status === 200 && res.data) {
                props.getAllUserData();
                setcandidature_modal(false);
            }
        });
    }
    const deleteDoc = (data) => {
        const new_doc = props.userData.other_documents.filter(e => e !== data);
        ApiService.editProfileData({ other_documents: new_doc }).then((res) => {
            if (res && res.status === 200 && res.data) {
                props.getAllUserData();
                setcandidature_modal(false);
            }
        });
    }

    return (
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="4" className="candidature-tab">
                <Row>
                    <Col sm="12">
                        <div>
                            <h6 className="mb-1">
                                Candidature
                            </h6>
                        </div>
                        <div className="font-weight-light text-muted">
                            Showcase your resume and other documents that talk about your candidature and why you are a best fit
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p className="font-weight-normal pt-3 mb-1">Resume</p>

                        {props.userData.resume_url &&
                            <div className="apply_resume_wrapper">
                                <div className='company-logo-img'>
                                    <a href={props.userData.resume_url} target="_blank">


                                        <img
                                            src={pdf_icon}
                                            alt=""
                                            className="courses-cert-img"
                                            style={{ width: "auto" }}
                                        />
                                    </a>

                                </div>

                            </div>}
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" sm="12">
                        <p className="font-weight-normal pt-3 mb-1">Candidature Video</p>
                        <p className="font-weight-light text-muted mb-1">Upload a video talking about your how you can be a best fit for a company</p>
                        <p className="font-weight-light text-muted mb-1">{props.userData.video_url}</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" sm="12">
                        <p className="font-weight-normal pt-3 mb-1">Other Documents</p>
                        <Row>
                            {props.userData.other_documents && props.userData.other_documents.map((e) => {
                                return (
                                    <Col lg="2" sm="2">
                                        <div className="apply_resume_wrapper">
                                            <div className='company-logo-img'>
                                                <a href={e} target="_blank">

                                                    <img
                                                        src={pdf_icon}
                                                        alt=""
                                                        className="courses-cert-img"
                                                        style={{ width: "auto" }}
                                                    />
                                                </a>

                                            </div>
                                        </div>
                                    </Col>

                                )
                            })}
                        </Row>
                    </Col>
                </Row>
            </TabPane>

        </TabContent>
    )
}

export default Cadidature;