import React, { useState } from 'react';

import { Nav, Row, Col, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import classnames from 'classnames';
import ClientsTab from './ClientsTab';
import CandidatesTab from './CandidatesTab';
import StudentsTab from './StudentsTab';
import CollegesTab from './CollegesTab';
import AdminTab from './AdminTab';
const UsersTable = (props) => {

    const [activeTab, setactiveTab] = useState("1");
    const [menu, setMenu] = useState(false);

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    }
    return (
        <React.Fragment>
            <div className="job-details">
                <Row className='mt-0'>
                    <Col xl="12" className='pl-4 pr-4'>
                        <div className='job-tabs'>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames('cursor-pointer', {
                                            active: activeTab === "1"
                                        })}
                                        onClick={() => {
                                            toggle("1");
                                            props.changePage();
                                        }}
                                    >
                                        Candidates({props.stats.users.candidates})
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames('cursor-pointer', {
                                            active: activeTab === "2"
                                        })}
                                        onClick={() => {
                                            toggle("2");
                                            props.changePage();
                                        }}
                                    >
                                        Clients HR's({props.stats.users.clients})
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames('cursor-pointer', {
                                            active: activeTab === "3"
                                        })}
                                        onClick={() => {
                                            toggle("3");
                                            props.changePage();
                                        }}
                                    >
                                        Admins({props.stats.users.admin})
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames('cursor-pointer', {
                                            active: activeTab === "4"
                                        })}
                                        onClick={() => {
                                            toggle("4");
                                            props.changePage();
                                        }}
                                    >
                                        Colleges({props.stats.users.colleges})
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames('cursor-pointer', {
                                            active: activeTab === "5"
                                        })}
                                        onClick={() => {
                                            toggle("5");
                                            props.changePage();
                                        }}
                                    >
                                        Students({props.stats.users.students})
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        {activeTab === '1' && <CandidatesTab {...props} data={props.candidate ? props.candidate : []} fetchUser={props.fetchUser} activeTab={activeTab} loading={props.loading} loaded={props.loaded} />}
                        {activeTab === '2' && <ClientsTab {...props} data={props.client ? props.client : []} fetchUser={props.fetchUser} loading={props.loading} loaded={props.loaded} activeTab={activeTab} />}
                        {activeTab === '3' && <AdminTab {...props} data={props.admin ? props.admin : []} fetchUser={props.fetchUser} loading={props.loading} loaded={props.loaded} activeTab={activeTab} />}
                        {activeTab === '4' && <CollegesTab {...props} data={props.college ? props.college : []} fetchUser={props.fetchUser} loading={props.loading} loaded={props.loaded} activeTab={activeTab} />}
                        {activeTab === '5' && <StudentsTab {...props} data={props.student ? props.student : []} fetchUser={props.fetchUser} loading={props.loading} loaded={props.loaded} activeTab={activeTab} />}
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default UsersTable;