import React from 'react';
const Users=(props)=>{
return(
    <div className={`user-card ml-3 ${props.active==="Pending"? "b-g-green":""}`}>
<div className="container py-3">
<div className={`row ${props.active==="Pending"? "f-color-white":""}`}>
    <div className="ml-2 font-size-16">Pending Background Verification</div>

</div>
 <div className={`row mt-2 ${props.active==="Pending"? "f-color-white":""}`}>
    {/* <span className="mx-auto font-size-24 font-weight-bold">Comming Soon</span> */}
    <span className="mx-auto mt-2 font-size-14 font-weight-bold">Coming Soon</span>

</div>
{/*
<div className={` row pl-2 pt-3 ${props.active==="Pending"? "f-color-white":""}`}>
    <span className="mr-1"><b className="mx-1">4</b>Common</span>
    <span className="mx-1">|<b  className="mx-1">3</b>Requested by the client</span>

</div> */}
</div>
    </div>
)
}
export default Users;