import React from "react";
import { Redirect } from "react-router-dom";

//Components
import Login from "../pages/Authentication/Login";
import AccountSetting from '../pages/AccountSetting';
import Dashboard from "../pages/Dashboard/Dashboard";
import SetPassword from '../pages/Authentication/SetPassword';
import AboutYourself from "../pages/Authentication/AboutYourself";
import JobDetails from "../pages/JobDetails";
import JobWizard from "../pages/Authentication/JobAndAssesment";
import PublicProfile from '../pages/PublicProfile';
import Verification from '../pages/Authentication/ResetVerification';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import ResetVerification from "../pages/Authentication/ResetVerification";
import Notifications from "../pages/Notifications/Notifications";
import CollegeProfile from '../pages/PublicProfileCollege';
const userRoutes = [

    { path: "/setting", component: AccountSetting },
    { path: "/aboutyourself", component: AboutYourself },
    {path:"/dashboard",component:Dashboard},
    { path: "/jobdetails/:id", component: JobDetails },
    { path: "/jobwizard", component: JobWizard },
    {path:"/notifications",component:Notifications},
    { path: "/", exact: true, component: () => localStorage.getItem("token") ? <Redirect to="/dashboard" /> : <Redirect to="/login" /> }
];

const authRoutes = [
    { path: "/public/candidate/profile/:id", exact:true ,component: (props) => <PublicProfile {...props}/>},
    { path: "/public/college/profile/:id", exact:true ,component: (props) => <CollegeProfile {...props}/>},
    {path:"/login",component:Login},
    {path:"/setpassword",component:SetPassword},
    { path: "/resetverification", component: Verification },
    { path: "/forgotpassword", component: ForgotPassword },
    { path: "/passwordreset", component: ResetVerification }

    //Authentication
    // { path: "/login", component: Login },
    // { path: "/signup", component: SignUP }
]

export { userRoutes, authRoutes };