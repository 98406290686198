import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import BlockUserModal from "./BlockUserModal";

import Api from "../../config/restapi";
import Common from "../../helpers/common";

const ApplicationTab = (props) => {
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);

  function startSearch(value) {
    Api.getAdminSearchList(value).then((res) => {
      if (res && res.status === 200) {
        setSearchList({ ...res.data });
      } else if (res.data.success === "false") {
        console.log(res);
        // toastr.error(res.data.message, "", { timeOut: 2000 });
      } else {
        console.log(res);
        // toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  useEffect(() => {
    props.fetchUser(
      "admin",
      props.data.length % 10 == 0
        ? props.data.length / 10
        : Math.floor(props.data.length / 10) + 1
    );
  }, []);
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      props.loading ||
      props.loaded
    )
      return;
    if (!props.loading) {
      props.fetchUser(
        "admin",
        props.data.length % 10 == 0
          ? props.data.length / 10
          : Math.floor(props.data.length / 10) + 1
      );
    }
  };

  const [modal_center, setmodal_center] = useState(false);

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const rejectionSubmit = (data) => {
    tog_center();
  };

  return (
    <TabContent className="mt-3" activeTab={props.activeTab}>
      <TabPane tabId="3" className="application-tab">
        <Row>
          <Col xl="2" className="text-left">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Admin Email"
                  aria-label="Recipient's username"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    if (!search) {
                      console.log("  ");
                    } else {
                      startSearch(search);
                    }
                    console.log(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
          {props.isMobile ? (
            <Col xl="12" lg="12" className="text-left">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"> Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {search && searchList
                      ? search &&
                        searchList &&
                        searchList.admins &&
                        searchList.admins
                          // .filter((item) => {
                          //   if (search == "") {
                          //     return item;
                          //   } else if (
                          //     item.email
                          //       .toString()
                          //       .toLowerCase()
                          //       .includes(search.toLowerCase())
                          //   ) {
                          //     return item;
                          //   }
                          // })
                          .map((item, index) => {
                            console.log(item);
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="font-size-16">
                                    {item.email}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                      : props.data &&
                        props.data
                          // .filter((item) => {
                          //   if (search == "") {
                          //     return item;
                          //   } else if (
                          //     item.email
                          //       .toString()
                          //       .toLowerCase()
                          //       .includes(search.toLowerCase())
                          //   ) {
                          //     return item;
                          //   }
                          // })
                          .map((item, index) => {
                            console.log(item);
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="font-size-16">
                                    {item.email}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                  </tbody>
                </table>
              </div>
            </Col>
          ) : (
            <Col xl="12" lg="12" className="text-left">
              <Row className="row-header font-weight-medium font-size-16">
                <Col xl="3" lg="3" className="">
                  Email
                </Col>
                {/* <Col xl="2" className=''> 
                                    Date of Birth
                                </Col>
                                <Col xl="2" className=''> 
                                    Contact Info
                                </Col>
                                <Col xl="2" className=''> 
                                Location
                                </Col>
                                <Col xl="2" className=''> 
                                    Status
                                </Col>
                                <Col xl="1" className=''> 
                                    <i className="bx bx-cog font-size-17 align-middle mr-1"></i>
                                </Col> */}
              </Row>

              {search && searchList
                ? search &&
                  searchList &&
                  searchList.admins &&
                  searchList.admins
                    // .filter((item) => {
                    //   if (search == "") {
                    //     return item;
                    //   } else if (
                    //     item.email
                    //       .toString()
                    //       .toLowerCase()
                    //       .includes(search.toLowerCase())
                    //   ) {
                    //     return item;
                    //   }
                    // })
                    .map((item, index) => {
                      console.log(item);
                      return (
                        <Row key={index} className="mt-2 font-weight-normal">
                          <Col xl="12" lg="12" className="text-left">
                            <Row className="row-items green-text-color">
                              <Col xl="3" lg="3" className="">
                                <div className="font-size-16">{item.email}</div>
                              </Col>
                              {/* <Col>
                                                <div className='font-size-16'>
                                                    {item.applied_on}
                                                </div>
                                                </Col>
                                                <Col xl="2" className=''> 
                                                    <div className='font-size-16'>
                                                        {item.email}
                                                    </div>
                                                    <div className='font-size-12'>
                                                        {item.phone}
                                                    </div>
                                                </Col>
                                                <Col xl="2" className=''> 
                                            {item.location}
                                                </Col>
                                                <Col xl="2" className=''> 
                                                    <div className='font-size-16'>
                                                        <span>
                                                            <i className={`dripicons-dot ${item.req_verification_status === 'Verified' ? 'light-green-text' : 
                                                                (item.req_verification_status === 'Failed' ? 'failed-text' : 'pedning-text') }`}></i>  
                                                            <span className='light-green-text font-size-12 text'>{item.req_verification_status} </span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                
                                                <Col xl="1" className=''> 
                                                    <Dropdown isOpen={item.menu} toggle={() => changePendingMenuState(index)} className="d-inline-block" >
                                                        <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                                                                <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className='green-text-color' right>
                                                            <DropdownItem className='green-text-color' tag="a"  href="#">View Profile</DropdownItem>
                                                    
                                                            <DropdownItem className='green-text-color' tag="a"  href="#" onClick={(e)=>OpenRejectionModal(e)}>Block User</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </Col> */}
                            </Row>
                          </Col>
                        </Row>
                      );
                    })
                : props.data &&
                  props.data
                    // .filter((item) => {
                    //   if (search == "") {
                    //     return item;
                    //   } else if (
                    //     item.email
                    //       .toString()
                    //       .toLowerCase()
                    //       .includes(search.toLowerCase())
                    //   ) {
                    //     return item;
                    //   }
                    // })
                    .map((item, index) => {
                      console.log(item);
                      return (
                        <Row key={index} className="mt-2 font-weight-normal">
                          <Col xl="12" lg="12" className="text-left">
                            <Row className="row-items green-text-color">
                              <Col xl="3" lg="3" className="">
                                <div className="font-size-16">{item.email}</div>
                              </Col>
                              {/* <Col>
                                                <div className='font-size-16'>
                                                    {item.applied_on}
                                                </div>
                                                </Col>
                                                <Col xl="2" className=''> 
                                                    <div className='font-size-16'>
                                                        {item.email}
                                                    </div>
                                                    <div className='font-size-12'>
                                                        {item.phone}
                                                    </div>
                                                </Col>
                                                <Col xl="2" className=''> 
                                            {item.location}
                                                </Col>
                                                <Col xl="2" className=''> 
                                                    <div className='font-size-16'>
                                                        <span>
                                                            <i className={`dripicons-dot ${item.req_verification_status === 'Verified' ? 'light-green-text' : 
                                                                (item.req_verification_status === 'Failed' ? 'failed-text' : 'pedning-text') }`}></i>  
                                                            <span className='light-green-text font-size-12 text'>{item.req_verification_status} </span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                
                                                <Col xl="1" className=''> 
                                                    <Dropdown isOpen={item.menu} toggle={() => changePendingMenuState(index)} className="d-inline-block" >
                                                        <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                                                                <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className='green-text-color' right>
                                                            <DropdownItem className='green-text-color' tag="a"  href="#">View Profile</DropdownItem>
                                                    
                                                            <DropdownItem className='green-text-color' tag="a"  href="#" onClick={(e)=>OpenRejectionModal(e)}>Block User</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </Col> */}
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
            </Col>
          )}
        </Row>
      </TabPane>
      <BlockUserModal
        modal_center={modal_center}
        centered={true}
        tog_center={() => tog_center()}
        onModalSubmit={rejectionSubmit}
      />
    </TabContent>
  );
};

export default ApplicationTab;
