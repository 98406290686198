import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import ApiService from "../../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import getFormatedDate from "../../../helpers/getFormatedDate";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDashboard } from "../../../redux/actions/dashboard";
import Common from "../../../helpers/common";

const JobDashboard = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const [jobList, setJobList] = useState({});
  const [pagenumber, setPagenumber] = useState(0);
  const [hasMore, sethasMore] = useState(true);
  const [jobcount, setJobCount] = useState({});
  const [search, setSearch] = useState("");
  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
      setPagenumber(0);
      setJobList({});
      sethasMore(true);
    }
  }

  function changeMenuState(index) {
    let tempData = { ...jobList };
    tempData.jobs[index].menu = !tempData.jobs[index]["menu"];
    setJobList({ ...tempData });
  }

  function getJobListByStatus(status, type) {
    ApiService.getJobListByStatus(status).then((res) => {
      if (res && res.status === 200 && res.data) {
        if (res.data.jobs.length !== 0) {
          setPagenumber(pagenumber + 1);
        }
        if (type !== "reload") {
          if (jobList && jobList.jobs) {
            const updtaedList = [...jobList.jobs, ...res.data.jobs];
            setJobList({
              ...res.data,
              jobs: updtaedList,
            });
            if (res.data.jobs.length === 0) sethasMore(false);
          } else {
            setJobList({
              ...res.data,
            });
          }
        } else {
          setJobList({
            ...res.data,
          });
          getUpdatedStatusCount();
        }
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function closePost(data) {
    let Obj = {
      id: data.id,
      status: "close",
      organizationId: data.organizationId,
    };
    ApiService.updateJobStatus(Obj).then((res) => {
      if (res && res.status === 200 && res.data && res.data.success) {
        getJobListByStatus({ status: "open", pagenumber: 0 }, "reload");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function openJobPost(data) {
    let Obj = {
      id: data.id,
      status: "open",
      organizationId: data.organizationId,
    };
    ApiService.updateJobStatus(Obj).then((res) => {
      if (res && res.status === 200 && res.data && res.data.success) {
        getJobListByStatus({ status: "close", pagenumber: 0 }, "reload");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function deleteDraft(data) {
    let Obj = {
      id: data.id,
    };
    ApiService.deleteJobPost(Obj).then((res) => {
      if (res && res.status === 200) {
        getJobListByStatus({ status: "incomplete", pagenumber: 0 }, "reload");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      !hasMore
    )
      return;
    if (activeTab === "1") {
      getJobListByStatus({ status: "open", pagenumber });
    } else if (activeTab === "2") {
      getJobListByStatus({ status: "incomplete", pagenumber });
    } else {
      getJobListByStatus({ status: "close", pagenumber });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    if (activeTab === "1") {
      getJobListByStatus({ status: "open", pagenumber });
    } else if (activeTab === "2") {
      getJobListByStatus({ status: "incomplete", pagenumber });
    } else {
      getJobListByStatus({ status: "close", pagenumber });
    }
  }, [activeTab]);

  function getUpdatedStatusCount() {
    ApiService.getUpdatedStatusCount().then((res) => {
      if (res && res.status === 200) {
        setJobCount({ ...res.data });
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  useEffect(() => {
    getUpdatedStatusCount();
  }, []);

  return (
    <React.Fragment>
      {console.log("props")}
      {console.log(props.history)}
      <div className="page-content job-dashbord mt-0 pt-0">
        <Row className="mt-0">
          <Col xl="12" lg="12" className="pl-4 pr-4 mobile_job_side_padding">
            <div className="job-tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Open (
                    {jobcount.jobs && jobcount.jobs.open
                      ? jobcount.jobs.open
                      : 0}
                    )
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Incomplete (
                    {jobcount.jobs && jobcount.jobs.incomplete
                      ? jobcount.jobs.incomplete
                      : 0}
                    )
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Closed (
                    {jobcount.jobs && jobcount.jobs.close
                      ? jobcount.jobs.close
                      : 0}
                    )
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
          <Col xl="2" className="pl-4 pr-4 text-left mobile_job_side_padding">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Jobs Title"
                  aria-label="Recipient's username"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    console.log(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ minWidth: "1600px", overflow: "auto" }} className="mt-2 ">
          {props.isMobile ? (
            <Col xl="12" lg="12" className="text-left">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Job Title</th>
                      <th scope="col"> Company/ Location</th>
                      <th scope="col"> Domain</th>
                      <th scope="col"> Skill Tags</th>
                      <th scope="col"> Employment Type</th>
                      <th scope="col"> Total Appointments</th>
                      <th scope="col">
                        {activeTab === "1" && "Added On"}
                        {activeTab === "2" && "Created On"}
                        {activeTab === "3" && "Closed On"}
                      </th>
                      {/* <th className="text-center">
                        <i className="bx bx-cog font-size-17 align-middle"></i>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {jobList &&
                      jobList.jobs &&
                      jobList.jobs.length > 0 &&
                      jobList.jobs
                        .filter((item) => {
                          if (search == "") {
                            return item;
                          } else if (
                            item.title
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          console.log(item);
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                item.status !== "incomplete"
                                  ? props.history.push(`/jobdetails/${item.id}`)
                                  : props.history.push({
                                      pathname: "/jobwizard",
                                      state: item,
                                    });
                              }}
                            >
                              <td>
                                <div className="font-size-16">{item.title}</div>
                                <div className="font-size-12">#{item.id}</div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.company}
                                </div>
                                <div className="font-size-12">
                                  {item.location}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.domain}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16 d-flex">
                                  {item && item.skills_must && (
                                    <ul className="skills-learns pl-0 mb-0">
                                      {item.skills_must &&
                                        item.skills_must
                                          .slice(0, 2)
                                          .map((item, idx) => {
                                            return (
                                              <li
                                                className={classnames(
                                                  "d-inline-block mr-2 skills-items skill-container"
                                                )}
                                                key={idx}
                                              >
                                                <span> {item} </span>
                                              </li>
                                            );
                                          })}
                                    </ul>
                                  )}
                                  {item.skills_must &&
                                    item.skills_must.length > 2 && (
                                      <span className="font-size-12 light-green-text mt-1">
                                        +
                                        {
                                          item.skills_must.slice(
                                            2,
                                            item.skills_must.length
                                          ).length
                                        }
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.employment_type
                                    ? item.employment_type
                                    : "--"}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="font-size-16"
                                  style={{ textAlign: "center" }}
                                >
                                  {item.total_appointments
                                    ? item.total_appointments
                                    : 0}{" "}
                                  {/* <span>
                                    <i className="dripicons-dot light-green-text"></i>
                                    <span className="light-green-text font-size-12 text">
                                      {" "}
                                      0{" "}
                                    </span>{" "}
                                  </span> */}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {activeTab === "1" &&
                                    (item.createdAt
                                      ? getFormatedDate(item.createdAt, "ll")
                                      : "--")}
                                  {activeTab === "2" &&
                                    (item.createdAt
                                      ? getFormatedDate(item.createdAt, "ll")
                                      : "--")}
                                  {activeTab === "3" &&
                                    (item.updatedAt
                                      ? getFormatedDate(item.updatedAt, "ll")
                                      : "--")}
                                </div>
                              </td>
                              <td>
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={(event) => {
                                    event.stopPropagation();
                                    changeMenuState(index);
                                  }}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    {activeTab === "2" && (
                                      <DropdownItem
                                        className="green-text-color"
                                        tag="a"
                                        href="#"
                                        onClick={() => deleteDraft(item, index)}
                                      >
                                        Delete Draft
                                      </DropdownItem>
                                    )}
                                    {activeTab === "1" && (
                                      <DropdownItem
                                        className="green-text-color"
                                        tag="a"
                                        href="#"
                                        onClick={() => {
                                          closePost(item, index);
                                        }}
                                      >
                                        Close Job Post
                                      </DropdownItem>
                                    )}
                                    {activeTab === "3" && (
                                      <DropdownItem
                                        className="green-text-color"
                                        tag="a"
                                        href="#"
                                        onClick={() => {
                                          openJobPost(item, index);
                                        }}
                                      >
                                        Open Job Post
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                    {jobList && jobList.jobs && jobList.jobs.length === 0 && (
                      <tr>
                        <td colSpan="8">
                          <div className="no-result text-center">No Result</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
          ) : (
            <Col xl="12" lg="12" className="pl-4 pr-4 text-left">
              <Row className="row-header font-weight-medium font-size-16">
                <Col xl="2" lg="2" className="">
                  Job Title
                </Col>
                <Col xl="2" lg="2" className="">
                  Company/ Location
                </Col>
                <Col xl="1" lg="1" className="">
                  Domain
                </Col>
                <Col xl="2" lg="2" className="">
                  Skill Tags
                </Col>
                <Col xl="2" lg="2" className="">
                  Employment Type
                </Col>
                <Col xl="1" lg="1" className="" style={{ textAlign: "center" }}>
                  Appointments
                </Col>
                <div className="job-w">
                  {activeTab === "1" && "Added On"}
                  {activeTab === "2" && "Created On"}
                  {activeTab === "3" && "Closed On"}
                </div>
                {/* <Col className="job_setting_w">
                  <i className="bx bx-cog font-size-17 align-middle"></i>
                </Col> */}
              </Row>
              {jobList &&
                jobList.jobs &&
                jobList.jobs.length > 0 &&
                jobList.jobs
                  .filter((item) => {
                    if (search == "") {
                      return item;
                    } else if (
                      item.title.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    console.log(item);
                    return (
                      <Row key={index} className="mt-2 font-weight-normal">
                        <Col xl="12" lg="12" className=" text-left">
                          <Row
                            className="row-items green-text-color cursor-pointer"
                            onClick={() => {
                              item.status !== "incomplete"
                                ? props.history.push(`/jobdetails/${item.id}`)
                                : props.history.push({
                                    pathname: "/jobwizard",
                                    state: item,
                                  });
                            }}
                          >
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16 overflow-ellipsis">
                                {item.title}
                              </div>
                              <div className="font-size-12 overflow-ellipsis">
                                #{item.id}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16 overflow-ellipsis">
                                {item.company}
                              </div>
                              <div className="font-size-12 overflow-ellipsis">
                                {item.location}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="text-truncate">
                              <div className="font-size-16 overflow-ellipsis">
                                {item.domain}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="overflow-ellipsis">
                              <div className="font-size-16 d-flex">
                                {item && item.skills_must && (
                                  <ul className="skills-learns pl-0 mb-0">
                                    {item.skills_must &&
                                      item.skills_must
                                        .slice(0, 2)
                                        .map((item, idx) => {
                                          return (
                                            <li
                                              className={classnames(
                                                "d-inline-block mr-2 skills-items skill-container"
                                              )}
                                              key={idx}
                                            >
                                              <span> {item} </span>
                                            </li>
                                          );
                                        })}
                                  </ul>
                                )}
                                {item.skills_must &&
                                  item.skills_must.length > 2 && (
                                    <span className="font-size-12 light-green-text mt-1">
                                      {" "}
                                      +
                                      {
                                        item.skills_must.slice(
                                          2,
                                          item.skills_must.length
                                        ).length
                                      }
                                    </span>
                                  )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16 overflow-ellipsis">
                                {item.employment_type
                                  ? item.employment_type
                                  : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div
                                className="font-size-16"
                                style={{ textAlign: "center" }}
                              >
                                {item.total_appointments
                                  ? item.total_appointments
                                  : 0}{" "}
                                {/* <span>
                                  <i className="dripicons-dot light-green-text"></i>
                                  <span className="light-green-text font-size-12 text">
                                    0{" "}
                                  </span>{" "}
                                </span> */}
                              </div>
                            </Col>
                            <div className="job-w">
                              <div className="font-size-16">
                                {activeTab === "1" &&
                                  (item.createdAt
                                    ? getFormatedDate(item.createdAt, "ll")
                                    : "--")}
                                {activeTab === "2" &&
                                  (item.createdAt
                                    ? getFormatedDate(item.createdAt, "ll")
                                    : "--")}
                                {activeTab === "3" &&
                                  (item.updatedAt
                                    ? getFormatedDate(item.updatedAt, "ll")
                                    : "--")}
                              </div>
                            </div>
                            <Col className="job_setting_w">
                              <Dropdown
                                isOpen={item.menu}
                                toggle={(event) => {
                                  event.stopPropagation();
                                  changeMenuState(index);
                                }}
                                className="d-inline-block"
                              >
                                <DropdownToggle
                                  className="btn header-item waves-effect"
                                  id="page-header-user-dropdown"
                                  tag="button"
                                >
                                  <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="green-text-color"
                                  right
                                >
                                  {activeTab === "2" && (
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() => deleteDraft(item, index)}
                                    >
                                      Delete Draft
                                    </DropdownItem>
                                  )}
                                  {activeTab === "1" && (
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() => {
                                        closePost(item, index);
                                      }}
                                    >
                                      Close Job Post
                                    </DropdownItem>
                                  )}
                                  {activeTab === "3" && (
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() => {
                                        openJobPost(item, index);
                                      }}
                                    >
                                      Open Job Post
                                    </DropdownItem>
                                  )}
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    onClick={() => {
                                      props.history.push({
                                        pathname: "/jobwizard",
                                        state: item,
                                      });
                                    }}
                                  >
                                    Edit Job Post
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
              {jobList && jobList.jobs && jobList.jobs.length === 0 && (
                <Row className="mt-2 font-weight-normal">
                  <Col xl="12" className="pl-4 pr-4 text-center">
                    <div className="no-result">No Result</div>
                  </Col>
                </Row>
              )}
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state.dashboard,
  };
};
export default withRouter(
  connect(mapStateToProps, { fetchDashboard })(JobDashboard)
);
