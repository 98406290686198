import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import resume from "../../assets/images/resume.png";
import RejectedModal from "./RejectedModal";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ApiService from "../../config/restapi";
import getFormatedDate from "../../helpers/getFormatedDate";
import Common from "../../helpers/common";

const ApplicationTab = (props) => {
  const [penddingList, setPenddingList] = useState([]);
  const [holdList, setHoldList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [modal_center, setmodal_center] = useState(false);
  const [rejectedData, setRejectedData] = useState();
  const [applicationCount, setApplicationCount] = useState({});

  const [search, setSearch] = useState("");
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  const OpenRejectionModal = (event, item) => {
    event.stopPropagation();
    tog_center();
    setRejectedData({
      ...item,
    });
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const rejectionSubmit = (data, status) => {
    if (rejectedData && rejectedData.id) {
      updateApplicationStatus(rejectedData.id, status);
      tog_center();
    }
  };

  function changePendingMenuState(index) {
    let tempData = [...penddingList];
    tempData[index].menu = !tempData[index]["menu"];
    setPenddingList([...tempData]);
  }

  function changeHoldMenuState(index) {
    let tempData = [...holdList];
    tempData[index].menu = !tempData[index]["menu"];
    setHoldList([...tempData]);
  }

  function changeSelectedMenuState(index) {
    let tempData = [...selectedList];
    tempData[index].menu = !tempData[index]["menu"];
    setSelectedList([...tempData]);
  }

  function changeRejectedMenuState(index) {
    let tempData = [...rejectedList];
    tempData[index].menu = !tempData[index]["menu"];
    setRejectedList([...tempData]);
  }

  function getJobApplicationByStatus(id, status) {
    ApiService.getJobApplicationByStatus(id, status).then((res) => {
      if (res && res.status === 200 && res.data) {
        if (status === "applied") {
          setPenddingList(res.data.applications);
          setApplicationCount({
            acceptedCount: res.data.acceptedCount,
            appliedCount: res.data.appliedCount,
            onholdCount: res.data.onholdCount,
            rejectedCount: res.data.rejectedCount,
          });
        } else if (status === "hold") {
          setHoldList(res.data.applications);
        } else if (status === "select") {
          setSelectedList(res.data.applications);
        } else {
          setRejectedList(res.data.applications);
        }
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  useEffect(() => {
    getJobApplicationByStatus(props.id, "applied");
    getJobApplicationByStatus(props.id, "hold");
    getJobApplicationByStatus(props.id, "select");
    getJobApplicationByStatus(props.id, "reject");
  }, []);

  function updateApplicationStatus(id, status) {
    ApiService.updateJobApplicationStatus({ id, status }).then((res) => {
      if (res && res.status === 200 && res.data.success) {
        getJobApplicationByStatus(props.id, "applied");
        getJobApplicationByStatus(props.id, "hold");
        getJobApplicationByStatus(props.id, "select");
        getJobApplicationByStatus(props.id, "reject");
        toastr.success("Job Application Status Updated Successfully", "", {
          timeOut: 2000,
        });
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  return (
    <TabContent className="mt-3" activeTab={props.activeTab}>
      <TabPane tabId="2" className="application-tab">
        <Row>
          {console.log(penddingList)}
          <Col xl="2" lg="2" className="text-left">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Applicants"
                  aria-label="Recipient's username"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    console.log(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {props.isMobile ? (
          <Row
            className="mt-2"
            style={{ minWidth: "1600px", overflow: "auto" }}
          >
            <Col lg="12" xl="12" className="text-left">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"> Candidate Name</th>
                      <th scope="col"> Resume</th>
                      <th scope="col"> Skills</th>
                      <th scope="col"> Contact Info</th>
                      <th scope="col"> Background Check</th>
                      <th scope="col"> Applied On</th>
                      <th className="text-center">
                        <i className="bx bx-cog font-size-17 align-middle mr-1"></i>
                      </th>
                    </tr>
                  </thead>

                  {/* Pendding Section  */}

                  <tbody>
                    <tr className="application_status_header">
                      <td colSpan="7" className="text-left">
                        <div className="form-group m-0 ">
                          Pending ({applicationCount.appliedCount}){" "}
                          <span className="arrow-down font-size-18"></span>
                        </div>
                      </td>
                    </tr>
                    {penddingList &&
                      penddingList
                        .filter((item) => {
                          if (search == "") {
                            return item;
                          } else if (
                            item.user.name
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="font-size-16">
                                  {item.user.name ? item.user.name : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  <a
                                    href={item.link ? item.link : "#"}
                                    target="_blank"
                                  >
                                    <img src={resume} alt="test" />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16 d-flex">
                                  {item.user && item.user.skills && (
                                    <ul className="skills-learns pl-0 mb-0">
                                      {item.user.skills &&
                                        item.user.skills
                                          .slice(0, 2)
                                          .map((item1, idx) => {
                                            const i = JSON.parse(item1);
                                            return (
                                              <li
                                                className={
                                                  "d-inline-block mr-2 skills-items skill-container"
                                                }
                                                key={idx}
                                              >
                                                <span> {i.label} </span>
                                              </li>
                                            );
                                          })}
                                    </ul>
                                  )}
                                  {item.user &&
                                    item.user.skills &&
                                    item.user.skills.length > 2 && (
                                      <span className="font-size-12 light-green-text mt-1">
                                        {" "}
                                        +
                                        {
                                          item.user.skills.slice(
                                            2,
                                            item.user.skills.length
                                          ).length
                                        }
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.email ? item.email : "--"}
                                </div>
                                <div className="font-size-12">
                                  {item.contact ? item.contact : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.user.is_verified ? (
                                    <span>
                                      <i
                                        className={`dripicons-dot ${
                                          item.user.is_verified === "Verified"
                                            ? "light-green-text"
                                            : item.user.is_verified === "Failed"
                                            ? "failed-text"
                                            : "pedning-text"
                                        }`}
                                      ></i>
                                      <span className="light-green-text font-size-12 text">
                                        {item.user.is_verified}{" "}
                                      </span>
                                    </span>
                                  ) : (
                                    "--"
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.createdAt
                                    ? getFormatedDate(item.createdAt, "ll")
                                    : "--"}
                                </div>
                              </td>
                              <td>
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={() => changePendingMenuState(index)}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      target="_blank"
                                      href={`http://pro.zunavish.com/public/profile/${item.UserId}`}
                                    >
                                      View Profile
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() =>
                                        updateApplicationStatus(
                                          item.id,
                                          "select"
                                        )
                                      }
                                    >
                                      Select Candidate
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() =>
                                        updateApplicationStatus(item.id, "hold")
                                      }
                                    >
                                      Keep on hold
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={(e) =>
                                        OpenRejectionModal(e, item)
                                      }
                                    >
                                      Reject Application
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>

                  {penddingList && penddingList.length === 0 && (
                    <tbody className="mt-2 font-weight-normal">
                      <tr>
                        <td colSpan="7" className="text-center">
                          <div className="no-result">
                            <div className="font-size-14">No Result</div>
                          </div>
                        </td>
                        {/* <td colSpan="7" className='text-center'>
                                                    <div className='no-result' >
                                                        <div className='font-size-14'>
                                                            There are no new application to view. Request Zunavish for new applications
                                                        </div>
                                                        <button
                                                            className='font-size-12 mt-2 btn btn-outline-shadow waves-effect waves-light w-md'
                                                            type="button"
                                                            onClick={() => sendRequestForApplications()}
                                                        > 
                                                            REQUEST FOR APPLICATIONS 
                                                        </button>
                                                    </div>
                                                </td> */}
                      </tr>
                    </tbody>
                  )}

                  {/* Hold Section Mobile*/}

                  <tbody>
                    <tr className="application_status_header">
                      <td colSpan="7" className="text-left">
                        <div className="form-group m-0 ">
                          On Hold ({applicationCount.onholdCount}){" "}
                          <span className="arrow-down font-size-18"></span>
                        </div>
                      </td>
                    </tr>
                    {holdList &&
                      holdList
                        .filter((item) => {
                          if (search == "") {
                            return item;
                          } else if (
                            item.user.name
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="font-size-16">
                                  {item.user.name ? item.user.name : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  <a
                                    href={item.link ? item.link : "#"}
                                    target="_blank"
                                  >
                                    <img src={resume} alt="test" />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16 d-flex">
                                  {item.user && item.user.skills && (
                                    <ul className="skills-learns pl-0 mb-0">
                                      {item.user.skills &&
                                        item.user.skills
                                          .slice(0, 2)
                                          .map((item1, idx) => {
                                            var i = JSON.parse(item1);
                                            return (
                                              <li
                                                className={
                                                  "d-inline-block mr-2 skills-items skill-container"
                                                }
                                                key={idx}
                                              >
                                                <span> {i.label} </span>
                                              </li>
                                            );
                                          })}
                                    </ul>
                                  )}
                                  {item.user &&
                                    item.user.skills &&
                                    item.user.skills.length > 2 && (
                                      <span className="font-size-12 light-green-text mt-1">
                                        {" "}
                                        +
                                        {
                                          item.user.skills.slice(
                                            2,
                                            item.user.skills.length
                                          ).length
                                        }
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.email ? item.email : "--"}
                                </div>
                                <div className="font-size-12">
                                  {item.contact ? item.contact : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.user.is_verified ? (
                                    <span>
                                      <i
                                        className={`dripicons-dot ${
                                          item.user.is_verified === "Verified"
                                            ? "light-green-text"
                                            : item.user.is_verified === "Failed"
                                            ? "failed-text"
                                            : "pedning-text"
                                        }`}
                                      ></i>
                                      <span className="light-green-text font-size-12 text">
                                        {item.user.is_verified}{" "}
                                      </span>
                                    </span>
                                  ) : (
                                    "--"
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.createdAt
                                    ? getFormatedDate(item.createdAt, "ll")
                                    : "--"}
                                </div>
                              </td>
                              <td>
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={() => changeHoldMenuState(index)}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      target="_blank"
                                      href={`http://pro.zunavish.com/public/profile/${item.UserId}`}
                                    >
                                      View Profile
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() =>
                                        updateApplicationStatus(
                                          item.id,
                                          "select"
                                        )
                                      }
                                    >
                                      Select Candidate
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={(e) =>
                                        OpenRejectionModal(e, item)
                                      }
                                    >
                                      Reject Application
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>

                  {holdList && holdList.length === 0 && (
                    <tbody className="mt-2 font-weight-normal">
                      <tr>
                        <td colSpan="7" className="text-center">
                          <div className="no-result">
                            <div className="font-size-14">No Result</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}

                  {/* Selected Section Mobile*/}

                  <tbody>
                    <tr className="application_status_header">
                      <td colSpan="7" className="text-left">
                        <div className="form-group m-0 ">
                          Selected ({applicationCount.acceptedCount}){" "}
                          <span className="arrow-down font-size-18"></span>
                        </div>
                      </td>
                    </tr>
                    {selectedList &&
                      selectedList
                        .filter((item) => {
                          if (search == "") {
                            return item;
                          } else if (
                            item.user.name
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="font-size-16">
                                  {item.user.name ? item.user.name : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  <a
                                    href={item.link ? item.link : "#"}
                                    target="_blank"
                                  >
                                    <img src={resume} alt="test" />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16 d-flex">
                                  {item.user && item.user.skills && (
                                    <ul className="skills-learns pl-0 mb-0">
                                      {item.user.skills &&
                                        item.user.skills
                                          .slice(0, 2)
                                          .map((item1, idx) => {
                                            var i = JSON.parse(item1);
                                            return (
                                              <li
                                                className={
                                                  "d-inline-block mr-2 skills-items skill-container"
                                                }
                                                key={idx}
                                              >
                                                <span> {i.label} </span>
                                              </li>
                                            );
                                          })}
                                    </ul>
                                  )}
                                  {item.user &&
                                    item.user.skills &&
                                    item.user.skills.length > 2 && (
                                      <span className="font-size-12 light-green-text mt-1">
                                        {" "}
                                        +
                                        {
                                          item.user.skills.slice(
                                            2,
                                            item.user.skills.length
                                          ).length
                                        }
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.email ? item.email : "--"}
                                </div>
                                <div className="font-size-12">
                                  {item.contact ? item.contact : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.user.is_verified ? (
                                    <span>
                                      <i
                                        className={`dripicons-dot ${
                                          item.user.is_verified === "Verified"
                                            ? "light-green-text"
                                            : item.user.is_verified === "Failed"
                                            ? "failed-text"
                                            : "pedning-text"
                                        }`}
                                      ></i>
                                      <span className="light-green-text font-size-12 text">
                                        {item.user.is_verified}{" "}
                                      </span>
                                    </span>
                                  ) : (
                                    "--"
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.createdAt
                                    ? getFormatedDate(item.createdAt, "ll")
                                    : "--"}
                                </div>
                              </td>
                              <td>
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={() => changeSelectedMenuState(index)}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      target="_blank"
                                      href={`http://pro.zunavish.com/public/profile/${item.UserId}`}
                                    >
                                      View Profile
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() =>
                                        updateApplicationStatus(item.id, "hold")
                                      }
                                    >
                                      Keep on hold
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={(e) =>
                                        OpenRejectionModal(e, item)
                                      }
                                    >
                                      Reject Application
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>

                  {selectedList && selectedList.length === 0 && (
                    <tbody className="mt-2 font-weight-normal">
                      <tr>
                        <td colSpan="7" className="text-center">
                          <div className="no-result">
                            <div className="font-size-14">No Result</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}

                  {/* Rejected Section Mobile*/}

                  <tbody>
                    <tr className="application_status_header">
                      <td colSpan="7" className="text-left">
                        <div className="form-group m-0 ">
                          Rejected ({applicationCount.rejectedCount}){" "}
                          <span className="arrow-down font-size-18"></span>
                        </div>
                      </td>
                    </tr>
                    {rejectedList &&
                      rejectedList
                        .filter((item) => {
                          if (search == "") {
                            return item;
                          } else if (
                            item.user.name
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="font-size-16">
                                  {item.user.name ? item.user.name : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  <a
                                    href={item.link ? item.link : "#"}
                                    target="_blank"
                                  >
                                    <img src={resume} alt="test" />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16 d-flex">
                                  {item.user && item.user.skills && (
                                    <ul className="skills-learns pl-0 mb-0">
                                      {item.user.skills &&
                                        item.user.skills
                                          .slice(0, 2)
                                          .map((item1, idx) => {
                                            var i = JSON.parse(item1);
                                            return (
                                              <li
                                                className={
                                                  "d-inline-block mr-2 skills-items skill-container"
                                                }
                                                key={idx}
                                              >
                                                <span> {i.label} </span>
                                              </li>
                                            );
                                          })}
                                    </ul>
                                  )}
                                  {item.user &&
                                    item.user.skills &&
                                    item.user.skills.length > 2 && (
                                      <span className="font-size-12 light-green-text mt-1">
                                        {" "}
                                        +
                                        {
                                          item.user.skills.slice(
                                            2,
                                            item.user.skills.length
                                          ).length
                                        }
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.email ? item.email : "--"}
                                </div>
                                <div className="font-size-12">
                                  {item.contact ? item.contact : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.user.is_verified ? (
                                    <span>
                                      <i
                                        className={`dripicons-dot ${
                                          item.user.is_verified === "Verified"
                                            ? "light-green-text"
                                            : item.user.is_verified === "Failed"
                                            ? "failed-text"
                                            : "pedning-text"
                                        }`}
                                      ></i>
                                      <span className="light-green-text font-size-12 text">
                                        {item.user.is_verified}{" "}
                                      </span>
                                    </span>
                                  ) : (
                                    "--"
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item.createdAt
                                    ? getFormatedDate(item.createdAt, "ll")
                                    : "--"}
                                </div>
                              </td>
                              <td>
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={() => changeRejectedMenuState(index)}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      target="_blank"
                                      href={`http://pro.zunavish.com/public/profile/${item.UserId}`}
                                    >
                                      View Profile
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() =>
                                        updateApplicationStatus(
                                          item.id,
                                          "select"
                                        )
                                      }
                                    >
                                      Select Candidate
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href="#"
                                      onClick={() =>
                                        updateApplicationStatus(item.id, "hold")
                                      }
                                    >
                                      Keep on hold
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>

                  {rejectedList && rejectedList.length === 0 && (
                    <tbody className="mt-2 font-weight-normal">
                      <tr>
                        <td colSpan="7" className="text-center">
                          <div className="no-result">
                            <div className="font-size-14">No Result</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </Col>
          </Row>
        ) : (
          <Row
            className="mt-2"
            style={{ minWidth: "1600px", overflow: "auto" }}
          >
            <Col xl="12" lg="12" className="text-left">
              <Row className="row-header font-weight-medium font-size-16">
                <Col xl="3" lg="3" className="">
                  Candidate Name
                </Col>
                <Col xl="1" lg="1" className="">
                  Resume
                </Col>
                <Col xl="2" lg="2" className="">
                  Skills
                </Col>
                <Col xl="2" lg="2" className="">
                  Contact Info
                </Col>
                <Col xl="1" lg="1" className="">
                  Background Check
                </Col>
                <Col xl="2" lg="2" className="">
                  Applied On
                </Col>
                <Col xl="1" lg="1" className="">
                  <i className="bx bx-cog font-size-17 align-middle mr-1"></i>
                </Col>
              </Row>

              {/* Pendding Section  */}
              <Row className="mt-2 font-weight-medium">
                <Col xl="12" lg="12" className="text-left">
                  <div className="form-group m-0 ">
                    Pending ({applicationCount.appliedCount}){" "}
                    <span className="arrow-down font-size-18"></span>
                  </div>
                </Col>
              </Row>
              {penddingList &&
                penddingList
                  .filter((item) => {
                    if (search == "") {
                      return item;
                    } else if (
                      (item.user.name && item.user.name
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())) || (item.user.skills.findIndex(function(post, index) {
                          if(post.toLowerCase().includes(search.toLowerCase()))
                            return true;
                        })>-1)
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <Row key={index} className="mt-2 font-weight-normal">
                        <Col xl="12" lg="12" className="text-left">
                          <Row className="row-items green-text-color">
                            <Col xl="3" lg="3" className="">
                              <div className="font-size-16">
                                {item.user.name ? item.user.name : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                <a
                                  href={item.link ? item.link : "#"}
                                  target="_blank"
                                >
                                  <img src={resume} alt="test" />
                                </a>
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16 d-flex">
                                {item.user && item.user.skills && (
                                  <ul className="skills-learns pl-0 mb-0">
                                    {item.user.skills &&
                                      item.user.skills
                                        .slice(0, 2)
                                        .map((item1, idx) => {
                                          var i = JSON.parse(item1);
                                          return (
                                            <li
                                              className={
                                                "d-inline-block mr-2 skills-items skill-container"
                                              }
                                              key={idx}
                                            >
                                              <span> {i.label} </span>
                                            </li>
                                          );
                                        })}
                                  </ul>
                                )}
                                {item.user &&
                                  item.user.skills &&
                                  item.user.skills.length > 2 && (
                                    <span className="font-size-12 light-green-text mt-1">
                                      {" "}
                                      +
                                      {
                                        item.user.skills.slice(
                                          2,
                                          item.user.skills.length
                                        ).length
                                      }
                                    </span>
                                  )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16">
                                {item.email ? item.email : "--"}
                              </div>
                              <div className="font-size-12">
                                {item.contact ? item.contact : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                {item.user.is_verified ? (
                                  <span>
                                    <i
                                      className={`dripicons-dot ${
                                        item.user.is_verified === "Verified"
                                          ? "light-green-text"
                                          : item.user.is_verified === "Failed"
                                          ? "failed-text"
                                          : "pedning-text"
                                      }`}
                                    ></i>
                                    <span className="light-green-text font-size-12 text">
                                      {item.user.is_verified}{" "}
                                    </span>
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16">
                                {item.createdAt
                                  ? getFormatedDate(item.createdAt, "ll")
                                  : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <Dropdown
                                isOpen={item.menu}
                                toggle={() => changePendingMenuState(index)}
                                className="d-inline-block"
                              >
                                <DropdownToggle
                                  className="btn header-item waves-effect"
                                  id="page-header-user-dropdown"
                                  tag="button"
                                >
                                  <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="green-text-color"
                                  right
                                >
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    target="_blank"
                                    href={`http://pro.zunavish.com/public/profile/${item.UserId}`}
                                  >
                                    View Profile
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() =>
                                      updateApplicationStatus(item.id, "select")
                                    }
                                  >
                                    Select Candidate
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() =>
                                      updateApplicationStatus(item.id, "hold")
                                    }
                                  >
                                    Keep on hold
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e, item)}
                                  >
                                    Reject Application
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}

              {penddingList && penddingList.length === 0 && (
                <Row className="mt-2 font-weight-normal">
                  <Col xl="12" lg="12" className="text-center">
                    <div className="no-result">
                      <div className="font-size-14">
                        There are no new application to view. Request Zunavish
                        for new applications
                      </div>
                      <button
                        className="font-size-12 mt-2 btn btn-outline-shadow waves-effect waves-light w-md"
                        type="button"
                      >
                        {" "}
                        REQUEST FOR APPLICATIONS
                      </button>
                    </div>
                  </Col>
                </Row>
              )}

              {/* Hold Section */}
              <Row className="mt-2 font-weight-medium">
                <Col xl="12" lg="12" className="text-left">
                  <div className="form-group m-0 ">
                    On Hold ({applicationCount.onholdCount}){" "}
                    <span className="arrow-down font-size-18"></span>
                  </div>
                </Col>
              </Row>
              {holdList &&
                holdList
                  .filter((item) => {
                    if (search == "") {
                      return item;
                    } else if (
                      item.user.name && item.user.name
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <Row key={index} className="mt-2 font-weight-normal">
                        <Col xl="12" lg="12" className="text-left">
                          <Row className="row-items green-text-color">
                            <Col xl="3" lg="3" className="">
                              <div className="font-size-16">
                                {item.user.name ? item.user.name : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                <a
                                  href={item.link ? item.link : "#"}
                                  target="_blank"
                                >
                                  <img src={resume} alt="test" />
                                </a>
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16 d-flex">
                                {item.user && item.user.skills && (
                                  <ul className="skills-learns pl-0 mb-0">
                                    {item.user.skills &&
                                      item.user.skills
                                        .slice(0, 2)
                                        .map((item1, idx) => {
                                          var i = JSON.parse(item1);
                                          return (
                                            <li
                                              className={
                                                "d-inline-block mr-2 skills-items skill-container"
                                              }
                                              key={idx}
                                            >
                                              <span> {i.label} </span>
                                            </li>
                                          );
                                        })}
                                  </ul>
                                )}
                                {item.user &&
                                  item.user.skills &&
                                  item.user.skills.length > 2 && (
                                    <span className="font-size-12 light-green-text mt-1">
                                      {" "}
                                      +
                                      {
                                        item.user.skills.slice(
                                          2,
                                          item.user.skills.length
                                        ).length
                                      }
                                    </span>
                                  )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16">
                                {item.email ? item.email : "--"}
                              </div>
                              <div className="font-size-12">
                                {item.contact ? item.contact : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                {item.user.is_verified ? (
                                  <span>
                                    <i
                                      className={`dripicons-dot ${
                                        item.user.is_verified === "Verified"
                                          ? "light-green-text"
                                          : item.user.is_verified === "Failed"
                                          ? "failed-text"
                                          : "pedning-text"
                                      }`}
                                    ></i>
                                    <span className="light-green-text font-size-12 text">
                                      {item.user.is_verified}{" "}
                                    </span>
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16">
                                {item.createdAt
                                  ? getFormatedDate(item.createdAt, "ll")
                                  : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <Dropdown
                                isOpen={item.menu}
                                toggle={() => changeHoldMenuState(index)}
                                className="d-inline-block"
                              >
                                <DropdownToggle
                                  className="btn header-item waves-effect"
                                  id="page-header-user-dropdown"
                                  tag="button"
                                >
                                  <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="green-text-color"
                                  right
                                >
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    target="_blank"
                                    href={`http://pro.zunavish.com/public/profile/${item.UserId}`}
                                  >
                                    View Profile
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() =>
                                      updateApplicationStatus(item.id, "select")
                                    }
                                  >
                                    Select Candidate
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e, item)}
                                  >
                                    Reject Application
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}

              {holdList && holdList.length === 0 && (
                <Row className="mt-2 font-weight-normal">
                  <Col xl="12" lg="12" className="text-center">
                    <div className="no-result">
                      <div className="font-size-14">No Result</div>
                    </div>
                  </Col>
                </Row>
              )}

              {/* Selected Section */}
              <Row className="mt-2 font-weight-medium">
                <Col xl="12" lg="12" className="text-left">
                  <div className="form-group m-0 ">
                    Selected ({applicationCount.acceptedCount}){" "}
                    <span className="arrow-down font-size-18"></span>
                  </div>
                </Col>
              </Row>
              {selectedList &&
                selectedList
                  .filter((item) => {
                    if (search == "") {
                      return item;
                    } else if (
                      item.user.name && item.user.name
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <Row key={index} className="mt-2 font-weight-normal">
                        <Col xl="12" lg="12" className="text-left">
                          <Row className="row-items green-text-color">
                            <Col xl="3" lg="3" className="">
                              <div className="font-size-16">
                                {item.user.name ? item.user.name : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                <a
                                  href={item.link ? item.link : "#"}
                                  target="_blank"
                                >
                                  <img src={resume} alt="test" />
                                </a>
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16 d-flex">
                                {item.user && item.user.skills && (
                                  <ul className="skills-learns pl-0 mb-0">
                                    {item.user.skills &&
                                      item.user.skills
                                        .slice(0, 2)
                                        .map((item1, idx) => {
                                          var i = JSON.parse(item1);
                                          return (
                                            <li
                                              className={
                                                "d-inline-block mr-2 skills-items skill-container"
                                              }
                                              key={idx}
                                            >
                                              <span> {i.label} </span>
                                            </li>
                                          );
                                        })}
                                  </ul>
                                )}
                                {item.user &&
                                  item.user.skills &&
                                  item.user.skills.length > 2 && (
                                    <span className="font-size-12 light-green-text mt-1">
                                      {" "}
                                      +
                                      {
                                        item.user.skills.slice(
                                          2,
                                          item.user.skills.length
                                        ).length
                                      }
                                    </span>
                                  )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16">
                                {item.email ? item.email : "--"}
                              </div>
                              <div className="font-size-12">
                                {item.contact ? item.contact : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                {item.user.is_verified ? (
                                  <span>
                                    <i
                                      className={`dripicons-dot ${
                                        item.user.is_verified === "Verified"
                                          ? "light-green-text"
                                          : item.user.is_verified === "Failed"
                                          ? "failed-text"
                                          : "pedning-text"
                                      }`}
                                    ></i>
                                    <span className="light-green-text font-size-12 text">
                                      {item.user.is_verified}{" "}
                                    </span>
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16">
                                {item.createdAt
                                  ? getFormatedDate(item.createdAt, "ll")
                                  : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <Dropdown
                                isOpen={item.menu}
                                toggle={() => changeSelectedMenuState(index)}
                                className="d-inline-block"
                              >
                                <DropdownToggle
                                  className="btn header-item waves-effect"
                                  id="page-header-user-dropdown"
                                  tag="button"
                                >
                                  <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="green-text-color"
                                  right
                                >
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    target="_blank"
                                    href={`http://pro.zunavish.com/public/profile/${item.UserId}`}
                                  >
                                    View Profile
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() =>
                                      updateApplicationStatus(item.id, "hold")
                                    }
                                  >
                                    Keep on hold
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e, item)}
                                  >
                                    Reject Application
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}

              {selectedList && selectedList.length === 0 && (
                <Row className="mt-2 font-weight-normal">
                  <Col xl="12" lg="12" className="text-center">
                    <div className="no-result">
                      <div className="font-size-14">No Result</div>
                    </div>
                  </Col>
                </Row>
              )}

              {/* Rejected Section */}
              <Row className="mt-2 font-weight-medium">
                <Col xl="12" lg="12" className="text-left">
                  <div className="form-group m-0 ">
                    Rejected ({applicationCount.rejectedCount}){" "}
                    <span className="arrow-down font-size-18"></span>
                  </div>
                </Col>
              </Row>
              {rejectedList &&
                rejectedList
                  .filter((item) => {
                    if (search == "") {
                      return item;
                    } else if (
                      item.user.name && item.user.name
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <Row key={index} className="mt-2 font-weight-normal">
                        <Col xl="12" lg="12" className="text-left">
                          <Row className="row-items green-text-color">
                            <Col xl="3" lg="3" className="">
                              <div className="font-size-16">
                                {item.user.name ? item.user.name : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                <a
                                  href={item.link ? item.link : "#"}
                                  target="_blank"
                                >
                                  <img src={resume} alt="test" />
                                </a>
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16 d-flex">
                                {item.user && item.user.skills && (
                                  <ul className="skills-learns pl-0 mb-0">
                                    {item.user.skills &&
                                      item.user.skills
                                        .slice(0, 2)
                                        .map((item1, idx) => {
                                          var i = JSON.parse(item1);
                                          return (
                                            <li
                                              className={
                                                "d-inline-block mr-2 skills-items skill-container"
                                              }
                                              key={idx}
                                            >
                                              <span> {i.label} </span>
                                            </li>
                                          );
                                        })}
                                  </ul>
                                )}
                                {item.user &&
                                  item.user.skills &&
                                  item.user.skills.length > 2 && (
                                    <span className="font-size-12 light-green-text mt-1">
                                      {" "}
                                      +
                                      {
                                        item.user.skills.slice(
                                          2,
                                          item.user.skills.length
                                        ).length
                                      }
                                    </span>
                                  )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16">
                                {item.email ? item.email : "--"}
                              </div>
                              <div className="font-size-12">
                                {item.contact ? item.contact : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                {item.user.is_verified ? (
                                  <span>
                                    <i
                                      className={`dripicons-dot ${
                                        item.user.is_verified === "Verified"
                                          ? "light-green-text"
                                          : item.user.is_verified === "Failed"
                                          ? "failed-text"
                                          : "pedning-text"
                                      }`}
                                    ></i>
                                    <span className="light-green-text font-size-12 text">
                                      {item.user.is_verified}{" "}
                                    </span>
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-16">
                                {item.createdAt
                                  ? getFormatedDate(item.createdAt, "ll")
                                  : "--"}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <Dropdown
                                isOpen={item.menu}
                                toggle={() => changeRejectedMenuState(index)}
                                className="d-inline-block"
                              >
                                <DropdownToggle
                                  className="btn header-item waves-effect"
                                  id="page-header-user-dropdown"
                                  tag="button"
                                >
                                  <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="green-text-color"
                                  right
                                >
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    target="_blank"
                                    href={`http://pro.zunavish.com/public/profile/${item.UserId}`}
                                  >
                                    View Profile
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() =>
                                      updateApplicationStatus(item.id, "select")
                                    }
                                  >
                                    Select Candidate
                                  </DropdownItem>
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={() =>
                                      updateApplicationStatus(item.id, "hold")
                                    }
                                  >
                                    Keep on hold
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}

              {rejectedList && rejectedList.length === 0 && (
                <Row className="mt-2 font-weight-normal">
                  <Col xl="12" lg="12" className="text-center">
                    <div className="no-result">
                      <div className="font-size-14">No Result</div>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )}
      </TabPane>
      <RejectedModal
        modal_center={modal_center}
        centered={true}
        tog_center={() => tog_center()}
        onModalSubmit={rejectionSubmit}
      />
    </TabContent>
  );
};

export default ApplicationTab;
