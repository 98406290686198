import React from "react";
import { Row, Col } from "reactstrap";
import eduCation_bg_img from "../../assets/images/ed_bg.png";
import location_img from "../../assets/images/location.png";
import company_img from "../../assets/images/company.png";

const EmpRowUI = (props) => {
    return (
        <Col lg="8" className="">
            <h5 className="font-size-22"> {props.data && props.data.role} </h5>

            <div className="font-weight-medium">
                {props.data && props.data.company && (
                    <span>
                        {" "}
                        <img src={company_img} alt="company" /> {props.data.company}{" "}
                    </span>
                )}
                {props.data && props.data.city && (
                    <span className="pl-3">
                        {" "}
                        <img src={location_img} alt="location" /> {props.data.city}{" "}
                    </span>
                )}
            </div>

            <div className="text-muted font-weight-light font-size-11 mt-3">
                {props.data && (props.data.start_year || props.data.grad_year) && (
                    <i className="mdi mdi-calendar-month-outline mr-2 green-text-color" />
                )}
                {props.data && props.data.start_year && CheckAndPrint(props.data.start_year)}
                {props.data && props.data.grad_year && <span> - </span>}
                {props.data && props.data.grad_year && CheckAndPrint(props.data.grad_year)}{" "}
                {props.data && props.data.duration ? ` | ${props.data.duration}` : ""}
                {props.data && props.data.stillWorking && <span>- present</span>}
            </div>
        </Col>
    );
};

function CheckAndPrint(date) {
    if (date && typeof date === "string") {
        return date.split("-")[0];
    } else if (date && typeof date === "object") {
        return date.getFullYear().toString();
    }
}

const EducationalRowUI = (props) => {
    // console.log('props',props);
    return (
        <Col lg="8" className="">
            <h5 className="font-size-22">{props.data && props.data.college}</h5>

            <div className="font-weight-medium">
                <span>{props.data && props.data.degree}</span>{" "}
                {props.data && props.data.course && <span> | {props.data.course} </span>}
            </div>

            <div className="text-muted font-weight-light font-size-11 mt-3">
                {props.data && (props.data.start_year || props.data.grad_year) && (
                    <i className="mdi mdi-calendar-month-outline mr-2 green-text-color" />
                )}
                {props.data && props.data.start_year && CheckAndPrint(props.data.start_year)}
                {props.data && props.data.grad_year && <span> - </span>}
                {props.data && props.data.grad_year && CheckAndPrint(props.data.grad_year)}
                {props.data && props.data.stillStudying && <span>- present</span>}
            </div>
        </Col>
    );
};

const SavedRows = (props) => {
    return (
        <Row className="m-0 mt-2 mb-4">
            <Col lg="12" className="added-row">
                <Row>
                    <Col lg="2">
                        <div>
                            <img src={eduCation_bg_img} alt="" className="avatar-md img-thumbnail rounded-circle" />
                        </div>
                    </Col>
                    {props.type === "educational" && <EducationalRowUI data={props.data} />}
                    {props.type === "employment" && <EmpRowUI data={props.data} />}
                </Row>
            </Col>
        </Row>
    );
};

export default SavedRows;
