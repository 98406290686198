import React, { useEffect } from "react";
import image from "../../assets/images/userdummyimage/pngtree-user-vector-avatar-png-image_1541962 (1).jpg";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
const SidebarContent = (props) => {
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously

    return (
        <React.Fragment>
            <div id="sidebar-menu">
                <div className="basic-details">
                    <div className="green-bg"></div>
                    <div className="image-profile">
                        <img src={image} className="user-profile" alt="image-1"></img>
                    </div>
                    <div className="f-20 text-center shift-50px">Welcome, Kanak!</div>
                    <div className="basic-email f-12">
                        <i className="far fa-envelope mr-1"></i>kanak1411@gmail.com
                        <div className="float-right">
                            <i className="fas fa-phone mx-1"></i>8981174826
                        </div>
                    </div>
                </div>
                <div className="profile-section">
                    <div className="profile">
                        <span className="f-20 shift-top-margin">Profile</span>
                        <span className="float-right">
                            <span className="round-circle f-g">50%</span>
                            Complete
                        </span>
                    </div>
                    <div className="mb-2">
                        <span className="small-blurred-text pl-1">Help recruiters recognize you</span>
                        <span className="float-right f-g pr-1 f-12 f-w-200">Add Photo</span>
                    </div>
                    <div className="mb-2">
                        <span className="small-blurred-text pl-1">Tell us what you are good at</span>
                        <span className="float-right f-g pr-1 f-12 f-w-200">Add Skills</span>
                    </div>
                    <div className="mb-2">
                        <span className="small-blurred-text pl-1">Become a verified user</span>
                        <span className="float-right f-g pr-1 f-12 f-w-200">Begin</span>
                    </div>
                </div>
                <div className="active-applications mt-3">
                    <ul>
                        <li className="f-16">Active Applications (2)</li>
                        <li className="f-16 mt-1 px-2">UX Designer</li>
                        <li className="mt-1 f-14 mb-2 px-2 mx-1">
                            <i className="far fa-building"></i>Cartel Infotech LTD.
                        </li>
                        <li className="px-2">
                            <i className="far fa-clock f-12 mx-1"></i>2 minutes ago
                        </li>
                    </ul>
                </div>
                <div className="last-section mt-4">
                    <div className="f-16 px-2">UX Designer</div>
                    <div>
                        <div className="f-14 mb-2 mt-1 px-2">
                            <i className="far fa-building"></i>Cartel Infotech LTD.
                        </div>
                        <div>
                            <i className="fas fa-map-marker-alt mb-2 px-2"></i>
                            Hyderabad,Tealngana
                        </div>
                        <div>
                            <i className="far fa-clock mb-2 px-2"></i>2 minutes ago
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(SidebarContent);
