import React, { useState, useEffect } from "react";

import {
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import classnames from "classnames";
import image from "../../assets/images/userdummyimage/attachment_56777363-e1485987246720.jpg";
import ApplicationTab from "./ApplicationTab";
import JobDetailTab from "./JobDetailsTab";
import ApiService from "../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import timeSince from "../../helpers/timeSinceFormatter.js";
import dots from "../../assets/images/dots-image.png";
const JobDetails = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const [menu, setMenu] = useState(false);
  const [jobDetails, setJobDetails] = useState({});

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  useEffect(() => {
    ApiService.getJobDetails(props.match.params.id).then((res) => {
      if (res && res.status === 200 && res.data) {
        setJobDetails(res.data.foundJob);
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }, [props]);

  function closeJobPost() {
    console.log(jobDetails.organizationId);
    let Obj = {
      id: props.match.params.id,
      status: "close",
      organizationId: jobDetails.organizationId
    };
    ApiService.updateJobStatus(Obj).then((res) => {
      if (res && res.status === 200 && res.data && res.data.success) {
        toastr.success("Job Post Closed Successfully", "", { timeOut: 2000 });
        props.history.push("/dashboard");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function EditJobPost() {
    let Obj = {
      id: props.match.params.id,
      status: "close",
    };
    ApiService.updateJobStatus(Obj).then((res) => {
      if (res && res.status === 200 && res.data && res.data.success) {
        toastr.success("Job Post Closed Successfully", "", { timeOut: 2000 });
        props.history.push("/dashboard");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function openJobPost() {
    let Obj = {
      id: props.match.params.id,
      status: "open",
      organizationId: jobDetails.organizationId
    };
    ApiService.updateJobStatus(Obj).then((res) => {
      if (res && res.status === 200 && res.data && res.data.success) {
        toastr.success("Job Post Open Successfully", "", { timeOut: 2000 });
        props.history.push("/dashboard");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function deleteDraft() {
    let Obj = {
      id: props.match.params.id,
    };
    ApiService.deleteJobPost(Obj).then((res) => {
      if (res && res.status === 200) {
        toastr.success("Job Post Deleted Successfully", "", { timeOut: 2000 });
        props.history.push("/dashboard");
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content job-details">
        <Row>
          <Col xl="12" className="pl-4 pr-4">
            <div className="top-container">
              <div className="ux-designer">
                {" "}
                {jobDetails.title ? jobDetails.title : "--"}
              </div>
              <div className="profile-logo-container">
                <img
                  className="profile-logo-content ml-4 shift-image rounded-circle avatar-md"
                  src={image}
                  alt="Header Avatar"
                />
              </div>
              <div className="web_display justify-content-between company-info">
                <div className="d-flex ml-5 px-4 green-text-color mobile_job_detail_header">
                  <div className="mr-3 mt-2">
                    <div className="f-14 mb-2 px-2 mob_display">
                      <i className="far fa-building mr-1"></i>{" "}
                      {jobDetails.company ? jobDetails.company : "--"}
                    </div>
                    <div>
                      <i className="far fa-clock mb-2 px-2"></i>
                      {jobDetails.posted_at
                        ? timeSince(new Date(jobDetails.posted_at)) + " ago"
                        : ""}
                    </div>
                  </div>
                  <div className="mt-2">
                    {(jobDetails.location || jobDetails.is_remote_loc) && (
                      <i className="fas fa-map-marker-alt mb-2 mr-1 px-2"></i>
                    )}
                    {jobDetails.is_remote_loc
                      ? "Work from home"
                      : jobDetails.location}
                  </div>
                </div>
                <div className="d-flex align-items-start mt-2 mobile_jobdetail_status_text">
                  {jobDetails.status && (
                    <span className="green-status-text">
                      {" "}
                      {jobDetails.status}{" "}
                    </span>
                  )}
                  <Dropdown
                    isOpen={menu}
                    toggle={() => setMenu(!menu)}
                    className="d-inline-block"
                  >
                    <DropdownToggle
                      className="btn header-item waves-effect"
                      id="page-header-user-dropdown"
                      tag="button"
                    >
                      {/* <i className="bx bx-dots-vertical-rounded green-text-color font-size-24 cursor-pointer mr-2"></i> */}
                      <img src={dots} alt="dot-icon" />
                    </DropdownToggle>
                    <DropdownMenu className="green-text-color" right>
                      {/* <DropdownItem className='green-text-color' tag="a"  href="#"> Add/ Manage Background Verification </DropdownItem>
                                            <DropdownItem className='green-text-color' tag="a" href="#"> Manage Assessment Tests </DropdownItem> */}
                      {jobDetails.status === "open" && (
                        <DropdownItem
                          className="green-text-color"
                          tag="a"
                          href="#"
                          onClick={() => closeJobPost()}
                        >
                          {" "}
                          Close Job Post{" "}
                        </DropdownItem>
                      )}
                      {jobDetails.status === "incomplete" && (
                        <DropdownItem
                          className="green-text-color"
                          tag="a"
                          href="#"
                          onClick={() => deleteDraft()}
                        >
                          {" "}
                          Delete Draft{" "}
                        </DropdownItem>
                      )}
                      {jobDetails.status === "close" && (
                        <DropdownItem
                          className="green-text-color"
                          tag="a"
                          href="#"
                          onClick={() => openJobPost()}
                        >
                          {" "}
                          Open Job Post{" "}
                        </DropdownItem>
                      )}
                      <DropdownItem
                        className="green-text-color"
                        tag="a"
                        href="#"
                        onClick={() => {
                          props.history.push({
                            pathname: "/jobwizard",
                            state: jobDetails,
                          });
                        }}
                      >
                        {" "}
                        Edit Job Post{" "}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl="12" className="pl-4 pr-4">
            <div className="job-tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Applications
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            {activeTab === "1" && (
              <JobDetailTab jobDetails={jobDetails} activeTab={activeTab} />
            )}
            {activeTab === "2" && (
              <ApplicationTab
                {...props}
                activeTab={activeTab}
                id={props.match.params.id}
                jobDetails={jobDetails}
              />
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default JobDetails;
