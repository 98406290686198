import React, { useState, useEffect } from "react";
import { Row, Col, TabContent, TabPane } from "reactstrap";
import SavedRows from "./SavedRow";

const Education = (props) => {
    const [savedRows, setSavedRows] = useState([]);
    const [modal_center, setmodal_center] = useState(false);

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    //.log('Education Data', props);

    return (
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="2" className="educational-tab">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>Educational details</h6>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        {props.savedRowsEducation &&
                            props.savedRowsEducation.length > 0 &&
                            props.savedRowsEducation.map((data, index) => {
                                return <SavedRows data={data} index={index} type="educational" key={index} />;
                            })}
                    </Col>
                </Row>
            </TabPane>
        </TabContent>
    );
};
export default Education;
