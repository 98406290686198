import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BlockUserModal from "./BlockUserModal";
import profile from "../../assets/images/users/blank_profile.png";
import Api from "../../config/restapi";
import Common from "../../helpers/common";

const ApplicationTab = (props) => {
  const [penddingList, setPenddingList] = useState([]);
  const [modal_center, setmodal_center] = useState(false);
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);

  function startSearch(value) {
    Api.getCollegeSearchList(value).then((res) => {
      if (res && res.status === 200) {
        setSearchList({ ...res.data });
      } else if (res.data.success === "false") {
        console.log(res);
        // toastr.error(res.data.message, "", { timeOut: 2000 });
      } else {
        console.log(res);
        // toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    props.fetchUser(
      "college",
      props.data.length % 10 == 0
        ? props.data.length / 10
        : Math.floor(props.data.length / 10) + 1
    );
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      props.loading ||
      props.loaded
    )
      return;
    if (!props.loading) {
      props.fetchUser(
        "college",
        props.data.length % 10 == 0
          ? props.data.length / 10
          : Math.floor(props.data.length / 10) + 1
      );
    }
  };

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const rejectionSubmit = (data) => {
    tog_center();
  };

  function changePendingMenuState(index) {
    let tempData = props.data ? [...props.data] : [];
    tempData[index].menu = !tempData[index]["menu"];
    setPenddingList([...tempData]);
  }

  return (
    <TabContent className="mt-3" activeTab={props.activeTab}>
      <TabPane tabId="4" className="application-tab">
        <Row>
          <Col xl="2" className="text-left">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Colleges Name"
                  aria-label="Recipient's username"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    if (!search) {
                      console.log("  ");
                    } else {
                      startSearch(search);
                    }
                    console.log(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
          {props.isMobile ? (
            <Col xl="12" lg="12" className="text-left">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"> College Name</th>
                      <th scope="col"> Role</th>
                      <th scope="col"> Degrees</th>
                      <th scope="col"> Courses</th>
                      <th scope="col"> Email</th>
                      <th scope="col"> Phone No.</th>
                      <th className="text-center">
                        <i className="bx bx-cog font-size-17 align-middle"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {search && searchList
                      ? search &&
                        searchList &&
                        searchList.colleges &&
                        searchList.colleges.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <span>
                                  <img
                                    src={
                                      item.image_url ? item.image_url : profile
                                    }
                                    style={{ width: "36px", height: "36px" }}
                                  />
                                </span>
                                <div className="font-size-14 mobile_candidate_name">
                                  {item.name}
                                </div>
                              </td>
                              <td></td>
                              <td>
                                <div className="font-size-14">
                                  {item.ActiveJob}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-14">
                                  {item.HighestDegree}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-14">
                                  {item.designation ? item.designation : ""}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-14">{item.email}</div>
                                <div className="font-size-14">{item.phone}</div>
                              </td>
                              <td>
                                <div className="font-size-14">{item.city}</div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  <span>
                                    <i
                                      className={`dripicons-dot ${
                                        item.req_verification_status ===
                                        "Verified"
                                          ? "light-green-text"
                                          : item.req_verification_status ===
                                            "Failed"
                                          ? "failed-text"
                                          : "pedning-text"
                                      }`}
                                    ></i>
                                    <span className="light-green-text font-size-12 px-1 text">
                                      {item.req_verification_status}{" "}
                                    </span>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={() => changePendingMenuState(index)}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href={`/public/college/profile/${item.UserId}`}
                                      target="_blank"
                                    >
                                      View Profile
                                    </DropdownItem>

                                    {/* <DropdownItem
                                              className="green-text-color"
                                              tag="a"
                                              href="#"
                                              onClick={(e) => OpenRejectionModal(e)}
                                            >
                                              Block User
                                            </DropdownItem> */}
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      : penddingList &&
                        props.data.map((item, index) => {
                          console.log(item);
                          return (
                            <tr key={index}>
                              <td>
                                <span>
                                  <img
                                    src={
                                      item.image_url ? item.image_url : profile
                                    }
                                    style={{ width: "36px", height: "36px" }}
                                  />
                                </span>
                                <div className="font-size-14 mobile_candidate_name">
                                  {item.name}
                                </div>
                              </td>
                              <td></td>
                              <td>
                                <div className="font-size-14">
                                  {item.ActiveJob}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-14">
                                  {item.HighestDegree}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-14">
                                  {item.designation ? item.designation : ""}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-14">{item.email}</div>
                                <div className="font-size-14">{item.phone}</div>
                              </td>
                              <td>
                                <div className="font-size-14">{item.city}</div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  <span>
                                    <i
                                      className={`dripicons-dot ${
                                        item.req_verification_status ===
                                        "Verified"
                                          ? "light-green-text"
                                          : item.req_verification_status ===
                                            "Failed"
                                          ? "failed-text"
                                          : "pedning-text"
                                      }`}
                                    ></i>
                                    <span className="light-green-text font-size-12 px-1 text">
                                      {item.req_verification_status}{" "}
                                    </span>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={() => changePendingMenuState(index)}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    <DropdownItem
                                      className="green-text-color"
                                      tag="a"
                                      href={`/public/college/profile/${item.UserId}`}
                                      target="_blank"
                                    >
                                      View Profile
                                    </DropdownItem>

                                    {/* <DropdownItem
                                              className="green-text-color"
                                              tag="a"
                                              href="#"
                                              onClick={(e) => OpenRejectionModal(e)}
                                            >
                                              Block User
                                            </DropdownItem> */}
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </Col>
          ) : (
            <Col xl="12" lg="12" className="text-left">
              <Row className="row-header font-weight-medium font-size-16">
                <Col xl="2" lg="2" className="font-size-14">
                  College Name
                </Col>
                <Col xl="2" lg="2" className="font-size-14">
                  Role
                </Col>
                <Col xl="1" lg="1" className="font-size-14">
                  Degrees
                </Col>
                <Col xl="1" lg="1" className="font-size-14">
                  Location
                </Col>
                <Col xl="2" lg="2" className="font-size-14">
                  Email
                </Col>
                <Col xl="2" lg="2" className="font-size-14">
                  Phone No.
                </Col>

                {/* <Col className="setting_w">
                                    <i className="bx bx-cog font-size-17 align-middle"></i>
                                </Col> */}
              </Row>

              {search && searchList
                ? search &&
                  searchList &&
                  searchList.colleges &&
                  searchList.colleges.map((item, index) => {
                    return (
                      <Row key={index} className="mt-2 font-weight-normal">
                        <Col xl="12" lg="12" className="text-left">
                          <Row className="row-items green-text-color">
                            <Col xl="2" lg="2" className="">
                              <span>
                                <img
                                  src={
                                    item.image_url ? item.image_url : profile
                                  }
                                  style={{ width: "36px", height: "36px" }}
                                />
                              </span>
                              <span className="font-size-14 ml-2">
                                {item.name}
                              </span>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-12">
                                {/* <span className="f-color-white b-g-green px-2" style={{borderRadius:"54px"}}>{item.skills[0]}</span> 
                                <span className="f-color-white b-g-green ml-1 px-1" style={{borderRadius:"54px"}}>{item.skills[1]}</span>
                                <span className= "ml-1 px-1" >+{item.skills.length-2}</span> */}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-14">
                                {item.ActiveJob}
                              </div>
                            </Col>

                            <Col xl="1" lg="1" className="">
                              <div className="font-size-14">
                                {item.HighestDegree}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-14">
                                {item.designation ? item.designation : ""}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <ul className="skill-tags-container pl-0 mb-0">
                              {item.college_courses && item.college_courses.length>0?(<><li className="d-inline-block mr-2 skill-tag" >{item.college_courses[0].degree}</li> 
                                {item.college_courses[1]?(<li className="d-inline-block mr-2 skill-tag" >{item.college_courses[1].degree}</li>):""}
                                {item.college_courses.length>2?(<span className= "ml-1 px-1" >+{item.college_courses.length-2}</span>):""}</>):""}
                              </ul>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-14">{item.email}</div>
                              <div className="font-size-14">{item.phone}</div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-14">{item.city}</div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-16">
                                <span>
                                  <i
                                    className={`dripicons-dot ${
                                      item.req_verification_status ===
                                      "Verified"
                                        ? "light-green-text"
                                        : item.req_verification_status ===
                                          "Failed"
                                        ? "failed-text"
                                        : "pedning-text"
                                    }`}
                                  ></i>
                                  <span className="light-green-text font-size-12 px-1 text">
                                    {item.req_verification_status}{" "}
                                  </span>
                                </span>
                              </div>
                            </Col>
                            <Col className="setting_w">
                              <Dropdown
                                isOpen={item.menu}
                                toggle={() => changePendingMenuState(index)}
                                className="d-inline-block"
                              >
                                <DropdownToggle
                                  className="btn header-item waves-effect"
                                  id="page-header-user-dropdown"
                                  tag="button"
                                >
                                  <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="green-text-color"
                                  right
                                >
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href={`/public/college/profile/${item.UserId}`}
                                    target="_blank"
                                  >
                                    View Profile
                                  </DropdownItem>

                                  {/* <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e)}
                                  >
                                    Block User
                                  </DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })
                : penddingList &&
                  props.data.map((item, index) => {
                    return (
                      <Row key={index} className="mt-2 font-weight-normal">
                        <Col xl="12" lg="12" className="text-left">
                          <Row className="row-items green-text-color">
                            <Col xl="2" lg="2" className="">
                              <span>
                                <img
                                  src={
                                    item.image_url ? item.image_url : profile
                                  }
                                  style={{ width: "36px", height: "36px" }}
                                />
                              </span>
                              <span className="font-size-14 ml-2">
                                {item.name}
                              </span>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-14">
                                {item.designation ? item.designation : ""}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <ul className="skill-tags-container pl-0 mb-0">
                              {item.college_courses && item.college_courses.length>0?(<><li className="d-inline-block mr-2 skill-tag" >{item.college_courses[0].degree}</li> 
                                {item.college_courses[1]?(<li className="d-inline-block mr-2 skill-tag" >{item.college_courses[1].degree}</li>):""}
                                {item.college_courses.length>2?(<span className= "ml-1 px-1" >+{item.college_courses.length-2}</span>):""}</>):""}
                              </ul>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-14">
                                {item.location}
                              </div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-14">{item.email}</div>
                            </Col>
                            <Col xl="2" lg="2" className="">
                              <div className="font-size-14">
                                {item.recruiter_phone}
                              </div>
                            </Col>
                            <Col xl="1" lg="1" className="">
                              <div className="font-size-14">{item.city}</div>
                            </Col>
                            <Col className="setting_w">
                              <Dropdown
                                isOpen={item.menu}
                                toggle={() => changePendingMenuState(index)}
                                className="d-inline-block"
                              >
                                <DropdownToggle
                                  className="btn header-item waves-effect"
                                  id="page-header-user-dropdown"
                                  tag="button"
                                >
                                  <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="green-text-color"
                                  right
                                >
                                  <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href={`/public/college/profile/${item.UserId}`}
                                    target="_blank"
                                  >
                                    View Profile
                                  </DropdownItem>

                                  {/* <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e)}
                                  >
                                    Block User
                                  </DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
            </Col>
          )}
        </Row>
      </TabPane>
      <BlockUserModal
        modal_center={modal_center}
        centered={true}
        tog_center={() => tog_center()}
        onModalSubmit={rejectionSubmit}
      />
    </TabContent>
  );
};

export default ApplicationTab;
