import React from "react";
import { withRouter } from "react-router-dom";

const Users = (props) => {
    return (
        <div className={`user-card ml-3 ${props.active === "Jobs" ? "b-g-green" : ""}`}>
            <div className={`container py-3 `}>
                <div className="row">
                    <div className={`ml-2 font-size-20 ${props.active === "Jobs" ? "f-color-white" : ""}`}>Jobs</div>
                    <div className="ml-auto">
                        <button
                            className=" btn btn-outline-green btn-outline-shadow waves-effect waves-light font-size-12 mr-2 px-4 b-g-white"
                            type="button"
                            onClick={() => props.history.push("/jobwizard")}
                        >
                            Add
                        </button>
                    </div>
                </div>
                <div className={`row ${props.active === "Jobs" ? "f-color-white" : ""}`}>
                    <span className="mx-auto font-size-24 font-weight-bold">
                        {props.fetchJobs.open + props.fetchJobs.close + props.fetchJobs.incomplete}{" "}
                    </span>
                </div>
                <div className={`row pl-2 mt-2 ${props.active === "Jobs" ? "f-color-white" : ""}`}>
                    {/* <span className="mx-auto"><b className="mx-1">40</b>/50 need assessment</span> */}
                </div>
            </div>
        </div>
    );
};
export default withRouter(Users);
