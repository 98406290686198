import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import classnames from "classnames";

const JobDetailTab = (props) => {
  return (
    <TabContent className="mt-3" activeTab={props.activeTab}>
      <TabPane tabId="1" className="detail-tab">
        <Row>
          <Col lg="9">
            <div className="font-size-18">Roles & Responsibilites</div>
            <div className="mt-3 font-size-14 word-wrap">{props.jobDetails.roles}</div>

            <div className="border-bottom-dark mt-2 mb-3"> </div>

            <div className="mt-3 font-size-18">
              Additional details related to the job
            </div>
            <div className="mt-3 font-size-14 word-wrap">
              {props.jobDetails.job_details}
            </div>

            <div className="border-bottom-dark mt-2 mb-3"> </div>

            <div className="mt-3 font-size-18">Other details</div>
            <div className="mt-3 font-size-14 word-wrap">{props.jobDetails.perks}</div>
          </Col>
          <Col lg="3">
            <Card>
              <CardBody>
                <p className="green-text-color border-bottom pb-2 font-size-14 font-weight-medium">
                  Job Details
                </p>
                <Row className="green-text-color">
                  <Col sm={12}>
                    {props.jobDetails.education_level && (
                      <div className="mb-2">
                        <i className="fas fa-graduation-cap fa-lg" />
                        <span className="font-size-12 pl-2">
                          {props.jobDetails.education_level}
                        </span>
                      </div>
                    )}
                    {(props.jobDetails.min_exp || props.jobDetails.max_exp) && (
                      <div className="mb-2">
                        <i className="mdi mdi-briefcase-outline font-size-22" />
                        {props.jobDetails.min_exp && props.jobDetails.max_exp && (
                          <span className="font-size-12 pl-2">
                            {props.jobDetails.min_exp}-
                            {props.jobDetails.max_exp} years
                          </span>
                        )}
                      </div>
                    )}
                    {props.jobDetails.diversity && (
                      <div className="mb-2">
                        <i className="mdi mdi-gender-male-female font-size-22" />
                        <span className="font-size-12 pl-2">
                          {props.jobDetails.diversity}
                        </span>
                      </div>
                    )}
                    {(props.jobDetails.min_age || props.jobDetails.max_age) && (
                      <div className="mb-2">
                        <i className="mdi mdi-cake font-size-22" />
                        {props.jobDetails.min_age && props.jobDetails.max_age && (
                          <span className="font-size-12 pl-2">
                            {props.jobDetails.min_age}-
                            {props.jobDetails.max_age} years
                          </span>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
                {console.log(props)}
                <p className="mt-2 green-text-color border-bottom pb-2 font-size-14 font-weight-medium">
                  Skills{" "}
                  {props.jobDetails &&
                    props.jobDetails.skills_good &&
                    props.jobDetails.skills_must && (
                      <span>
                        (
                        {props.jobDetails.skills_good.length +
                          props.jobDetails.skills_must.length}
                        )
                      </span>
                    )}
                </p>
                <Row className="green-text-color">
                  {props.jobDetails &&
                    props.jobDetails.skills_must &&
                    props.jobDetails.skills_must.length > 0 && (
                      <Col sm={12} className="mt-2">
                        <p className="mb-2">Must have</p>
                        <ul className="skill-tags-container pl-0 mb-0">
                          {props.jobDetails.skills_must.map((item, index) => {
                            return (
                              <li
                                className={classnames(
                                  "d-inline-block mr-2 skill-tag"
                                )}
                                key={index}
                              >
                                <span> {item} </span>
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                    )}
                  {props.jobDetails &&
                    props.jobDetails.skills_good &&
                    props.jobDetails.skills_good.length > 0 && (
                      <Col sm={12} className="mt-4">
                        <p className="mb-2">Good to have</p>
                        <ul className="skill-tags-container pl-0">
                          {props.jobDetails.skills_good.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className={classnames(
                                  "d-inline-block mr-2 skill-tag"
                                )}
                              >
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                    )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </TabContent>
  );
};

export default JobDetailTab;
