export const INVITE_USER = 'INVITE_USER';
export const INVITE_SUCCESS = 'INVITE_SUCCESS';
export const API_ERROR = "API_ERROR";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const CHANGE_USER_PAGE = "CHANGE_USER_PAGE";

const initialState = {
    stats: { users: { candidates: 0, clients: 0, admin: 0, }, num_jobs: 0, num_assessments: 0, },
    error: null,
    loading_invite: false,
    loading: true,
    loading_user: false,
    loaded_user: false,
    candidate: [],
    client: [],
    admin: [],
    student: [],
    college: [],
    candidates: [],
    loading_jobs: true,
}

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD:
            state = {
                ...state,
                loading: true,
            }
            break;
        case FETCH_DASHBOARD_SUCCESS:
            state = {
                ...state,
                loading: false,
                stats: action.payload,
            }
        case INVITE_USER:
            state = {
                ...state,
                loading_invite: true
            }
            break;
        case INVITE_SUCCESS:
            state = {
                ...state,
                loading_invite: false
            }
            break;
        case FETCH_USER:
            state = {
                ...state,
                loading_user: true
            }
            break;
        case FETCH_USER_SUCCESS:
            state = {
                ...state,
            }
            state[action.payload.role] = [...state[action.payload.role], ...action.payload.users,];
            state["loaded_user"] = action.payload.loaded_user;
            state["loading_user"] = action.payload.loading_user;
            break;
        case CHANGE_USER_PAGE:
            state = {
                ...state,
                loaded_user: false,
            }
            break;
        case FETCH_JOBS:
            state = {
                ...state,
                loading_jobs: true
            }
            break;
        case FETCH_JOBS_SUCCESS:
            var jobs = {}
            jobs[action.payload.status] = [...action.payload.status];
            state = {
                ...state,
                ...jobs
            }
        case API_ERROR:
            if (action.payload === undefined) {
                action.payload = "Something went wrong...Please try again later"
            }
            state = { ...state, error: action.payload, loading: false };
            break;


    }
    return state;
}


export default dashboard;