import React, { useState } from "react";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import classnames from "classnames";
import toastr from "toastr";
import api from "../../config/axios";

const Courses = (props) => {
    const [savedRows, setSavedRows] = useState([]);
    const [modal_center, setmodal_center] = useState(false);
    const [editedData, setEditedData] = useState();
    const [editIndex, setEditIndex] = useState();

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const openModal = () => {
        if (editedData) setEditedData("");
        tog_center();
    };

    const editRow = (index) => {
        const tempEditedData = props.savedRowsCourses.filter((v) => {
            console.log(v.id);
            return v.id === index;
        });
        console.log(tempEditedData);
        if (tempEditedData.length > 0) setEditedData(tempEditedData[0]);
        setEditIndex(index);
        tog_center(true);
    };

    const deleteRow = (index) => {
        var tempERow = [...props.savedRowsCourses];
        if (index > -1) {
            tempERow = tempERow.filter((e) => e.id !== index);
        }
        api.delete(`/api/courses/delete/${index}`, {
            data: {
                id: index,
            },
            headers: {
                token: `${localStorage.getItem("token")}`,
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => {
                toastr.success("Course Deleted Successfully!", "", { timeOut: 2000 });
            })
            .catch((e) => {
                toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            });
        props.setSavedRowsCourses(tempERow);
    };

    const updateCouse = (data, type) => {
        if (type === "edit") {
            props.editCourse(data);
            tog_center(false);
        } else if (type === "save") {
            props.createCourse(data, "course");
            tog_center(false);
        }
    };

    return (
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="6" className="courses-tab">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>Courses completed</h6>
                        </div>
                    </Col>
                </Row>
                <Row className="m-0 mt-2 mb-4">
                    {props.savedRowsCourses.length > 0 &&
                        props.savedRowsCourses.map((data, index) => {
                            return (
                                <Col key={index} lg="12" className="added-row mt-2">
                                    <Row>
                                        <Col lg="10" className="">
                                            <h5> {data.title}</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col lg="8">
                                            <ul className="skills-learns mb-0">
                                                {data.topic ? (
                                                    <li className={classnames("d-inline-block mr-3 cursor-pointer")}>
                                                        <span className="skills-items py-1 px-2">{data.topic}</span>
                                                    </li>
                                                ) : (
                                                    ""
                                                )}
                                            </ul>
                                        </Col>
                                        {data.link ? (
                                            <Col lg="4" className="text-right">
                                                <a href={data.link} target="_blank">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-shadow waves-effect w-xs waves-light font-size-12"
                                                    >
                                                        VIEW COURSE CERTIFICATE
                                                    </button>
                                                </a>
                                                {/* <span className="cursor-pointer text-under-line">VIEW COURSE CERTIFICATE</span> */}
                                            </Col>
                                        ) : (
                                            ""
                                        )}
                                    </Row>
                                </Col>
                            );
                        })}
                </Row>
            </TabPane>
        </TabContent>
    );
};

export default Courses;
