import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  CustomInput,
} from "reactstrap";
import Common from "../../helpers/common";

const BlockUserModal = (props) => {
  const [backgroundVerification, setBackgroundVerification] = useState();
  const [otherfield, setOtherfield] = useState();

  const [charComplete, setCharComplete] = useState(0);
  const ChangeHandler = (value, type) => {
    switch (type) {
      case "backgroundVerification":
        setBackgroundVerification(value);
        break;
      case "otherfield":
        setOtherfield(value);
        break;
      default:
        break;
    }
  };
  const handleTextarea = (event) => {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = Common.trimStart(event.target.value);
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    if (WordCount <= 500) setCharComplete(WordCount);
  };

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="add-bg-verification"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0 font-size-22" id="myModalLabel">
          Block User
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <div className="font-weight-light font-size-16">
              Select a reason for blocking the candidate
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mt-3 mb-3" check>
              <Label className="cust-radio-label" check>
                <Input
                  type="radio"
                  name="radio1"
                  value="Background not verified"
                  onChange={(event) =>
                    ChangeHandler(event.target.value, "backgroundVerification")
                  }
                />{" "}
                Inappropriate for the platform
              </Label>
            </FormGroup>
            <FormGroup className="mt-3 mb-3" check>
              <Label className="cust-radio-label" check>
                <Input
                  type="radio"
                  name="radio1"
                  value="Skill set mismatch"
                  onChange={(event) =>
                    ChangeHandler(event.target.value, "backgroundVerification")
                  }
                />{" "}
                Adult Content
              </Label>
            </FormGroup>
            <FormGroup className="mt-3 mb-3" check>
              <Label className="cust-radio-label" check>
                <Input
                  type="radio"
                  name="radio1"
                  value="Aptitude test"
                  onChange={(event) =>
                    ChangeHandler(event.target.value, "backgroundVerification")
                  }
                />{" "}
                Fake or Spam
              </Label>
            </FormGroup>

            {backgroundVerification === "Other" && (
              <FormGroup>
                <Input
                  type="text"
                  onChange={(event) =>
                    ChangeHandler(event.target.value, "otherfield")
                  }
                />
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-0 text-left">
              <Label className="font-weight-light font-size-14">
                Additional Notes
              </Label>
              <Input
                placeholder="Let others know what type of person you are"
                type="textarea"
                value={props.about || ""}
                maxLength="500"
                onChange={(event) => {
                  handleTextarea(event);
                  props.OnchangeCallBack(event.target.value, "about");
                }}
                name="text"
              />
              <div className="text-right">
                <span className="font-size-12 font-weight-light">
                  {" "}
                  {charComplete}/500{" "}
                </span>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-0 text-left">
              <Label className=" font-size-14">
                Additional Actions you can perform
              </Label>
              <CustomInput
                type="checkbox"
                id="exampleCustomCheckbox"
                label="Reject all applications of a user"
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn bg-green waves-effect waves-light"
          onClick={() =>
            props.onModalSubmit({ backgroundVerification, otherfield })
          }
        >
          Block User
        </button>
      </div>
    </Modal>
  );
};

export default BlockUserModal;
