import axios from "axios";
const API_END_POINT = "https://api.zunavish.com/api";
const headers = {
  "Content-Type": "application/json",
  headers: {
    "Access-Control-Allow-Origin": "*",
    token: `${localStorage.getItem("token")}`,
  },
};

export default class RestApi {
  static getProfileData() {
    console.log("Hey");
    return axios.get(API_END_POINT + "/users/user", headers);
  }

  static saveOnboardingPersonalDetails(data) {
    return axios.put(API_END_POINT + "/onboarding/personal_details", data, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    });
  }

  static getJobDetails(id) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };

    return axios.get(API_END_POINT + `/jobs?id=${id}`, headers);
  }

  static getJobApplicationByStatus(id, status) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };

    return axios.get(
      API_END_POINT + `/jobs/applications?id=${id}&status=${status}`,
      headers
    );
  }

  static updateJobApplicationStatus(data) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.put(API_END_POINT + `/jobs/change_status`, data, headers);
  }

  static addJobPost(data) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };

    return axios.post(API_END_POINT + "/jobs/add", data, headers);
  }

  static getJobListByStatus(data) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.get(
      API_END_POINT + `/jobs/all?status=${data.status}&page=${data.pagenumber}`,
      headers
    );
  }

  static updateJobStatus(data) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.put(API_END_POINT + `/jobs/update`, data, headers);
  }

  static deleteJobPost(data) {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `${localStorage.getItem("token")}`,
    };
    return fetch(API_END_POINT + "/jobs/delete", {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static inviteUser(mail, type, company) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.post(
      API_END_POINT + "/admin/users/invite",
      { email: mail, role: type, orgName: company },
      headers
    );
  }
  static accept_background_verification(id) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.post(
      API_END_POINT + "/admin/users/verify",
      { id: id, reason: "", is_verified:true },
      headers
    );
  }
  static reject_background_verification(id) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.post(
      API_END_POINT + "/admin/users/verify",
      { id: id, reason: "not good enough", is_verified:false },
      headers
    );
  }
  static remind(id) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.post(
      API_END_POINT + "/admin/remindUser",
      { id: id},
      headers
    );
  }
  static getUpdatedStatusCount() {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.get(API_END_POINT + "/admin/dashboard", headers);
  }

  static getPublicProfileData(id) {
    return axios.get(API_END_POINT + "/users/user/public", { params: { id } });
  }

  static getJobList(value) {
    return axios.get(API_END_POINT + `/admin/search?query=${value}`, headers);
  }

  static getOrganizationsList() {
    return axios.get(API_END_POINT + `/organizations/all`, headers);
  }

  static getCandidateSearchList(value) {
    return axios.get(
      API_END_POINT + `/admin/search/candidates?query=${value}`,
      headers
    );
  }
  static getStudentSearchList(value) {
    return axios.get(
      API_END_POINT + `/admin/search/students?query=${value}`,
      headers
    );
  }
  static getClientSearchList(value) {
    return axios.get(
      API_END_POINT + `/admin/search/clients?query=${value}`,
      headers
    );
  }
  static getCollegeSearchList(value) {
    return axios.get(
      API_END_POINT + `/admin/search/colleges?query=${value}`,
      headers
    );
  }
  static getAdminSearchList(value) {
    return axios.get(
      API_END_POINT + `/admin/search/admins?query=${value}`,
      headers
    );
  }
  static getProfileCollegePublic(id) {
    return axios.get(API_END_POINT + `/college/profileAdmin?id=${id}`, headers);
  }
}
