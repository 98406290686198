import React, { useState, useEffect } from "react";
import {
    TabContent,
    TabPane,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal
} from "reactstrap";
import resume from "../../assets/images/resume.png";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ApiService from "../../config/restapi";
import getFormatedDate from "../../helpers/getFormatedDate";
import { useHistory } from "react-router";

const ViewApplicationDetail = (props) => {
    const history = useHistory();
    const [penddingList, setPenddingList] = useState([]);
    const [holdList, setHoldList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [rejectedList, setRejectedList] = useState([]);
    const [modal_center, setmodal_center] = useState(false);
    const [rejectedData, setRejectedData] = useState();
    const [applicationCount, setApplicationCount] = useState({});

    const [search, setSearch] = useState("");
    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    const OpenRejectionModal = (event, item) => {
        event.stopPropagation();
        tog_center();
        setRejectedData({
            ...item,
        });
    };

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const rejectionSubmit = (data, status) => {
        if (rejectedData && rejectedData.id) {
            updateApplicationStatus(rejectedData.id, status);
            tog_center();
        }
    };

    function changePendingMenuState(index) {
        let tempData = [...penddingList];
        tempData[index].menu = !tempData[index]["menu"];
        setPenddingList([...tempData]);
    }

    function changeHoldMenuState(index) {
        let tempData = [...holdList];
        tempData[index].menu = !tempData[index]["menu"];
        setHoldList([...tempData]);
    }

    function changeSelectedMenuState(index) {
        let tempData = [...selectedList];
        tempData[index].menu = !tempData[index]["menu"];
        setSelectedList([...tempData]);
    }

    function changeRejectedMenuState(index) {
        let tempData = [...rejectedList];
        tempData[index].menu = !tempData[index]["menu"];
        setRejectedList([...tempData]);
    }

    function getJobApplicationByStatus(id, status) {
        ApiService.getJobApplicationByStatus(id, status).then((res) => {
            if (res && res.status === 200 && res.data) {
                if (status === "applied") {
                    setPenddingList(res.data.applications);
                    setApplicationCount({
                        acceptedCount: res.data.acceptedCount,
                        appliedCount: res.data.appliedCount,
                        onholdCount: res.data.onholdCount,
                        rejectedCount: res.data.rejectedCount,
                    });
                } else if (status === "hold") {
                    setHoldList(res.data.applications);
                } else if (status === "select") {
                    setSelectedList(res.data.applications);
                } else {
                    setRejectedList(res.data.applications);
                }
            } else {
                toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            }
        });
    }

    useEffect(() => {
        if (props.id) {
            getJobApplicationByStatus(props.id, "applied");
            getJobApplicationByStatus(props.id, "hold");
            getJobApplicationByStatus(props.id, "select");
            getJobApplicationByStatus(props.id, "reject");
        }
    }, [props]);

    function updateApplicationStatus(id, status) {
        ApiService.updateJobApplicationStatus({ id, status }).then((res) => {
            if (res && res.status === 200 && res.data.success) {
                getJobApplicationByStatus(props.id, "applied");
                getJobApplicationByStatus(props.id, "hold");
                getJobApplicationByStatus(props.id, "select");
                getJobApplicationByStatus(props.id, "reject");
                toastr.success("Job Application Status Updated Successfully", "", {
                    timeOut: 2000,
                });
            } else {
                toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            }
        });
    }

    return (
        <>
            <Modal
                isOpen={props.modal_center}
                centered={props.centered}
                className='add-bg-verification'
            >
                <div className="modal-header">
                    <h6 className="modal-title mt-0 font-size-22" id="myModalLabel">
                        Applications Summary
                    </h6>
                    <button
                        type="button"
                        onClick={() => { props.tog_center(false) }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    {props.isMobile ? (
                        <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
                            <Col lg="12" xl="12" className="text-left">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-center">
                                                    <i className="bx bx-cog font-size-17 align-middle mr-1"></i>
                                                </th>
                                            </tr>
                                        </thead>

                                        
                                        {/* Hold Section Mobile*/}

                                        <tbody>
                                            <tr className="application_status_header">
                                                <td colSpan="7" className="text-left">
                                                    <div className="form-group m-0 font-weight-bold">
                                                        On Hold ({applicationCount.onholdCount}){" "}
                                                    </div>
                                                </td>
                                            </tr>
                                            {holdList &&
                                                holdList
                                                    .filter((item) => {
                                                        if (search == "") {
                                                            return item;
                                                        } else if (
                                                            item.user.name
                                                                .toString()
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase())
                                                        ) {
                                                            return item;
                                                        }
                                                    })
                                                    .map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className=" ">
                                                                        {item.user.name ? item.user.name : "--"}
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}
                                        </tbody>

                                        {holdList && holdList.length === 0 && (
                                            <tbody className="mt-2 font-weight-normal">
                                                <tr>
                                                    <td>
                                                        <div>
                                                            <div className=" ">No Result</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}

                                        {/* Selected Section Mobile*/}

                                        <tbody>
                                            <tr className="application_status_header">
                                                <td colSpan="7" className="text-left">
                                                    <div className="form-group m-0 font-size-18 font-weight-bolder">
                                                        Selected ({applicationCount.acceptedCount}){" "}
                                                    </div>
                                                </td>
                                            </tr>
                                            {selectedList &&
                                                selectedList
                                                    .filter((item) => {
                                                        if (search == "") {
                                                            return item;
                                                        } else if (
                                                            item.user.name
                                                                .toString()
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase())
                                                        ) {
                                                            return item;
                                                        }
                                                    })
                                                    .map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className=" ">
                                                                        {item.user.name ? item.user.name : "--"}
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}
                                        </tbody>

                                        {selectedList && selectedList.length === 0 && (
                                            <tbody className="mt-2 font-weight-normal">
                                                <tr>
                                                    <td >
                                                        <div >
                                                            <div className=" ">No Result</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}

                                        {/* Rejected Section Mobile*/}

                                        <tbody>
                                            <tr className="application_status_header">
                                                <td colSpan="7" className="text-left">
                                                    <div className="form-group m-0 ">
                                                        Rejected ({applicationCount.rejectedCount}){" "}
                                                    </div>
                                                </td>
                                            </tr>
                                            {rejectedList &&
                                                rejectedList
                                                    .filter((item) => {
                                                        if (search == "") {
                                                            return item;
                                                        } else if (
                                                            item.user.name
                                                                .toString()
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase())
                                                        ) {
                                                            return item;
                                                        }
                                                    })
                                                    .map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className=" ">
                                                                        {item.user.name ? item.user.name : "--"}
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}
                                        </tbody>

                                        {rejectedList && rejectedList.length === 0 && (
                                            <tbody className="mt-2 font-weight-normal">
                                                <tr>
                                                    <td>
                                                        <div >
                                                            <div className=" ">No Result</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
                            <Col xl="12" lg="12" className="text-left">

                                {/* Hold Section */}
                                <Row className="mt-2 font-weight-medium">
                                    <Col xl="12" lg="12" className="text-left">
                                        <div className="form-group m-0 font-size-18 font-weight-bolder">
                                            On Hold ({applicationCount.onholdCount}){" "}
                                        </div>
                                    </Col>
                                </Row>
                                {holdList &&
                                    holdList
                                        .filter((item) => {
                                            if (search == "") {
                                                return item;
                                            } else if (
                                                item.user.name
                                                    .toString()
                                                    .toLowerCase()
                                                    .includes(search.toLowerCase())
                                            ) {
                                                return item;
                                            }
                                        })
                                        .map((item, index) => {
                                            return (
                                                <Row key={index} className="mt-2 font-weight-normal">
                                                    <Col xl="12" lg="12" className="text-left">
                                                        <Row className="row-items green-text-color">
                                                            <Col xl="3" lg="3" className="">
                                                                <div className="">
                                                                    {item.user.name ? item.user.name : "--"}
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            );
                                        })}

                                {holdList && holdList.length === 0 && (
                                    <Row className="mt-2 font-weight-normal">
                                        <Col>
                                            <div >
                                                <div className=" ">No Result</div>
                                            </div>
                                        </Col>
                                    </Row>
                                )}

                                {/* Selected Section */}
                                <Row className="mt-2 font-weight-medium">
                                    <Col xl="12" lg="12" className="text-left">
                                        <div className="form-group m-0 font-size-18 font-weight-bolder ">
                                            Selected ({applicationCount.acceptedCount}){" "}
                                        </div>
                                    </Col>
                                </Row>
                                {selectedList &&
                                    selectedList
                                        .filter((item) => {
                                            if (search == "") {
                                                return item;
                                            } else if (
                                                item.user.name
                                                    .toString()
                                                    .toLowerCase()
                                                    .includes(search.toLowerCase())
                                            ) {
                                                return item;
                                            }
                                        })
                                        .map((item, index) => {
                                            return (
                                                <Row key={index} className="mt-2 font-weight-normal">
                                                    <Col xl="12" lg="12" className="text-left">
                                                        <Row className="row-items green-text-color">
                                                            <Col xl="3" lg="3" className="">
                                                                <div className=" ">
                                                                    {item.user.name ? item.user.name : "--"}
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            );
                                        })}

                                {selectedList && selectedList.length === 0 && (
                                    <Row className="mt-2 font-weight-normal">
                                        <Col >
                                            <div >
                                                <div className=" ">No Result</div>
                                            </div>
                                        </Col>
                                    </Row>
                                )}

                                {/* Rejected Section */}
                                <Row className="mt-2 font-weight-medium">
                                    <Col xl="12" lg="12" className="text-left">
                                        <div className="form-group m-0 font-size-18 font-weight-bolder">
                                            Rejected ({applicationCount.rejectedCount}){" "}
                                        </div>
                                    </Col>
                                </Row>
                                {rejectedList &&
                                    rejectedList
                                        .filter((item) => {
                                            if (search == "") {
                                                return item;
                                            } else if (
                                                item.user.name
                                                    .toString()
                                                    .toLowerCase()
                                                    .includes(search.toLowerCase())
                                            ) {
                                                return item;
                                            }
                                        })
                                        .map((item, index) => {
                                            return (
                                                <Row key={index} className="mt-2 font-weight-normal">
                                                    <Col xl="12" lg="12" className="text-left">
                                                        <Row className="row-items green-text-color">
                                                            <Col xl="3" lg="3" className="">
                                                                <div className=" ">
                                                                    {item.user.name ? item.user.name : "--"}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            );
                                        })}

                                {rejectedList && rejectedList.length === 0 && (
                                    <Row className="mt-2 font-weight-normal">
                                        <Col >
                                            <div >
                                                <div className=" ">No Result</div>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    )}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => { props.tog_center() }}
                        className="btn bg-green waves-effect"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn bg-green waves-effect waves-light"
                        onClick={() => { history.push(props.link); props.tog_center(); }}
                    >Job Details </button>
                </div>
            </Modal>
        </>
    );
};

export default ViewApplicationDetail;
