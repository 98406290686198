import React, { useState, useEffect } from 'react';
import {  Modal, Row, Col, FormGroup, Label, Input, InputGroup } from "reactstrap";


const RejectedModal = (props) => {

    const [backgroundVerification, setBackgroundVerification] = useState();
    const [otherfield, setOtherfield] = useState();

    const ChangeHandler = (value,type)=>{
        switch (type) {
            case 'backgroundVerification':
                setBackgroundVerification(value);
                break;
            case 'otherfield':
                setOtherfield(value);
                break;
            default:
                break;
        }
    }

    return (
        <Modal
            isOpen={props.modal_center}
            centered={props.centered}
            className='add-bg-verification'
        >
            <div className="modal-header">
                <h6 className="modal-title mt-0 font-size-22" id="myModalLabel">
                    Background Verification 
                </h6>
                <button
                    type="button"
                    onClick={() => { props.tog_center(false) } }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row >
                    <Col lg="12">
                        <div className='font-weight-light font-size-16'>
                            Select a reason for rejecting the candidate
                        </div>
                    </Col>
                </Row>           
                <Row >
                    <Col lg="12">
                        <FormGroup className='mt-3 mb-3' check>
                            <Label  className="cust-radio-label" check>
                                <Input type="radio" name="radio1" value="Background not verified" 
                                    onChange={(event)=>ChangeHandler(event.target.value,'backgroundVerification')} />{' '}
                                Background not verified
                            </Label>
                        </FormGroup>
                        <FormGroup className='mt-3 mb-3' check>
                            <Label  className="cust-radio-label" check>
                                <Input type="radio" name="radio1" value="Skill set mismatch" 
                                    onChange={(event)=>ChangeHandler(event.target.value,'backgroundVerification')} />{' '}
                                Skill set mismatch
                            </Label>
                        </FormGroup>
                        <FormGroup className='mt-3 mb-3' check>
                            <Label  className="cust-radio-label" check>
                                <Input type="radio" name="radio1" value="Aptitude test" 
                                    onChange={(event)=>ChangeHandler(event.target.value,'backgroundVerification')}/>{' '}
                                Aptitude test
                            </Label>
                        </FormGroup>
                        <FormGroup className='mt-3 mb-3' check>
                            <Label  className="cust-radio-label" check>
                                <Input type="radio" name="radio1" value="Interview"  
                                    onChange={(event)=>ChangeHandler(event.target.value,'backgroundVerification')}/>{' '}
                                Interview
                            </Label>
                        </FormGroup>
                        <FormGroup className='mt-3 mb-3' check>
                            <Label  className="cust-radio-label" check>
                                <Input type="radio" name="radio1" value="Psychometric Assessment Results mismatch" 
                                    onChange={(event)=>ChangeHandler(event.target.value,'backgroundVerification')}/>{' '}
                                Psychometric Assessment Results mismatch
                            </Label>
                        </FormGroup>
                        <FormGroup className='mt-3 mb-3' check>
                            <Label  className="cust-radio-label" check>
                                <Input type="radio" name="radio1" value="Compensation mismatch" 
                                  onChange={(event)=>ChangeHandler(event.target.value,'backgroundVerification')}/>{' '}
                                Compensation mismatch
                            </Label>
                        </FormGroup>
                        <FormGroup className='mt-3 mb-1' check>
                            <Label  className="cust-radio-label" check>
                                <Input type="radio" name="radio1" value="Other" 
                                  onChange={(event)=>ChangeHandler(event.target.value,'backgroundVerification')}/>{' '}
                                Other
                            </Label>
                        </FormGroup>
                        {backgroundVerification === 'Other' && <FormGroup>
                            <Input type="text"  onChange={(event)=>ChangeHandler(event.target.value,'otherfield')}/>
                        </FormGroup>}
                    </Col>
                </Row>           
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => { props.tog_center() }}
                    className="btn bg-green waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn bg-green waves-effect waves-light"
                    onClick={()=>props.onModalSubmit({backgroundVerification,otherfield},'reject')}
                >Reject Candidate </button>
            </div>
        </Modal>
      );
}

export default RejectedModal;