import React, { useState } from 'react';
import { TabContent, TabPane, Row, Col } from "reactstrap";
import SavedRows from './SavedRow';

const Employment = (props) => {

    const [modal_center, setmodal_center] = useState(false);



    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    return (
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="3" className="employment-tab">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>
                                Employment details
                            </h6>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        {props.savedRowsEmp && props.savedRowsEmp.length > 0 && props.savedRowsEmp.map((data, index) => {
                            return (<SavedRows
                                data={data}
                                index={index}
                                type="employment"
                                key={index}
                            />)
                        })}
                    </Col>
                </Row>
            </TabPane>

        </TabContent>
    )
}

export default Employment;