import React from 'react';
import { Container, Row, Col } from "reactstrap";
import AboutYourselfWizard from './AboutYourselfWizard';
import logo from '../../../assets/images/logoz.png';
import {useHistory} from "react-router-dom";


const AboutYourself = (props) =>{
    const history = useHistory();
    console.log(props);
    function onCompleteAboutYourselfCallBack(){
        history.push("/");
    }


    return(
        <React.Fragment>
            <div className="page-content p-0 mt-0">
                <div className='logo-fixed'>
                    <img src={logo} alt='logo'></img>
                </div>
                <Container className="about-page-auth" fluid>
                    <Row>
                        <Col xs="12" xl="3" sm="3"></Col>
                        <Col xs="12" xl="6" sm="6" className="text-center">
                            <h5 className="mb-0">Welcome, {props.user && props.user.name} </h5>
                            <div className="mt-3">
                                <p className="pb-3 font-size-11"> Complete your profile and start exploring jobs that suit you the best </p> 
                            </div>
                            <AboutYourselfWizard
                                onCompleteAboutYourselfCallBack={()=>onCompleteAboutYourselfCallBack()}
                            /> 
                        </Col>
                        <Col xs="12" xl="3" sm="3"></Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default AboutYourself;