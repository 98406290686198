import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Common from "../../helpers/common";

const AddUserModal = (props) => {
  const [mail, setMail] = useState();
  const [type, setType] = useState("");
  const [company, setCompany] = useState();

  function handleValidSubmit() {
    props.onModalSubmit({ mail, type: type.toLowerCase(), company });
  }

  useEffect(() => {
    setType("Client");
    setMail("");
    setCompany("");
  }, [props.modal_center]);

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="add-user-modal"
      size="lg"
    >
      <AvForm
        className="f"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div className="modal-header">
          <h6 className="modal-title mt-0 font-size-22" id="myModalLabel">
            Invite User
          </h6>
          <button
            type="button"
            onClick={() => {
              props.tog_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg="6">
              <AvField
                type="select"
                name="select"
                label="Role*"
                required
                value={type || ""}
                onChange={(e) => setType(e.target.value)}
              >
                <option>Admin</option>
                <option>Client</option>
                <option>User</option>
              </AvField>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <AvField
                className="mt-3 mb-3"
                name="email"
                label="Email Address*"
                placeholder="Type name or email address"
                type="email"
                required
                onChange={({ target }) => {
                  target.value = Common.trimStart(target.value);
                  setMail(target.value);
                }}
                value={mail || ""}
              />
            </Col>
          </Row>
          {type === "Client" && (
            <Row>
              <Col lg="6">
                <AvField
                  className="mt-3 mb-3"
                  name="company"
                  label="Company*"
                  type="text"
                  required
                  onChange={({ target }) => {
                    target.value = Common.trimStart(target.value);

                    setCompany(target.value);
                  }}
                  value={company || ""}
                />
              </Col>
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              props.tog_center();
            }}
            className={
              !mail || !type
                ? `light-green-text-color btn no-border-rad btn-outline-light-green`
                : `btn no-border-rad btn-outline-green`
            }
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={
              !mail || !type
                ? `btn no-border-rad white-text-color bg-light-green`
                : `btn bg-green white-text-color no-border-rad `
            }
            // onClick={() => props.onModalSubmit({ mail, type })}
          >
            Invite
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

export default AddUserModal;
