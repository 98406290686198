import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import BlockUserModal from "./BlockUserModal";
import profile from "../../assets/images/users/blank_profile.png";
import Api from "../../config/restapi";
import Common from "../../helpers/common";

const ClientsTab = (props) => {
  const [search, setSearch] = useState("");

  const [searchList, setSearchList] = useState([]);

  function startSearch(value) {
    Api.getClientSearchList(value).then((res) => {
      if (res && res.status === 200) {
        setSearchList({ ...res.data });
      } else if (res.data.success === "false") {
        console.log(res);
        // toastr.error(res.data.message, "", { timeOut: 2000 });
      } else {
        console.log(res);
        // toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  useEffect(() => {
    props.fetchUser(
      "client",
      props.data.length % 10 == 0
        ? props.data.length / 10
        : Math.floor(props.data.length / 10) + 1
    );
  }, []);
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      props.loading ||
      props.loaded
    )
      return;
    if (!props.loading) {
      props.fetchUser(
        "client",
        props.data.length % 10 == 0
          ? props.data.length / 10
          : Math.floor(props.data.length / 10) + 1
      );
    }
  };

  const [modal_center, setmodal_center] = useState(false);

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const rejectionSubmit = (data) => {
    tog_center();
  };

  return (
    <TabContent className="mt-3" activeTab={props.activeTab}>
      <TabPane tabId="2" className="application-tab">
        <Row>
          <Col xl="2" className="text-left">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Company Name"
                  aria-label="Recipient's username"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    if (!search) {
                      console.log("  ");
                    } else {
                      startSearch(search);
                    }
                    // console.log(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
          {props.isMobile ? (
            <Col xl="12" lg="12" className="text-left">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"> Company Name</th>
                      <th scope="col"> Location</th>
                      <th scope="col"> Email</th>
                      <th scope="col"> Phone No.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {search && searchList
                      ? search &&
                        searchList &&
                        searchList.clients &&
                        searchList.clients.map((com, index) => {
                          // console.log(com);
                          return (
                            <tr key={index}>
                              <td>
                                <div className="font-size-16">
                                  <span>
                                    <img
                                      src={
                                        (com.organization &&
                                          com.organization.logo_url) ||
                                        profile
                                      }
                                      style={{ width: "36px", height: "36px" }}
                                    />
                                  </span>
                                  <div className="font-size-14 mt-1">
                                    {com.organization && com.organization.name}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  <div className="font-size-16">
                                    {com.organization &&
                                      com.organization.location}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="font-size-12">{com.email}</div>
                              </td>
                              <td>
                                <div className="font-size-12">{com.phone}</div>
                              </td>
                            </tr>
                          );
                        })
                      : props.data.map((com, index) => {
                          // console.log(com);
                          return (
                            <tr key={index}>
                              <td>
                                <div className="font-size-16">
                                  <span>
                                    <img
                                      src={
                                        (com.organization &&
                                          com.organization.logo_url) ||
                                        profile
                                      }
                                      style={{ width: "36px", height: "36px" }}
                                    />
                                  </span>
                                  <div className="font-size-14 mt-1">
                                    {com.organization && com.organization.name}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  <div className="font-size-16">
                                    {com.organization &&
                                      com.organization.location}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="font-size-12">{com.email}</div>
                              </td>
                              <td>
                                <div className="font-size-12">{com.phone}</div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </Col>
          ) : (
            <Col xl="12" lg="12" className="text-left">
              <Row className="row-header font-weight-medium font-size-16">
                <Col xl="3" lg="3" className="">
                  Company Name
                </Col>
                <Col xl="2" lg="2" className="">
                  Location
                </Col>
                <Col xl="2" lg="2" className="">
                  Email
                </Col>
                <Col xl="2" lg="2" className="">
                  Phone No.
                </Col>
              </Row>
              {/* Pendding Section  */}
              {search && searchList
                ? search &&
                  searchList &&
                  searchList.clients &&
                  searchList.clients.map((com, index) => {
                    // console.log(com);
                    return (
                      <div>
                        <Row key={index} className="mt-2 font-weight-normal">
                          <Col xl="12" lg="12" className="text-left">
                            <Row className="row-items green-text-color">
                              <Col xl="3" lg="3" className="">
                                <div className="font-size-16">
                                  <span>
                                    <img
                                      src={
                                        (com.organization &&
                                          com.organization.logo_url) ||
                                        profile
                                      }
                                      style={{ width: "36px", height: "36px" }}
                                    />
                                  </span>
                                  <span className="font-size-14 ml-2">
                                    {com.organization && com.organization.name}
                                  </span>
                                </div>
                              </Col>
                              <Col xl="2" lg="2" className="">
                                <div className="font-size-16">
                                  <div className="font-size-16">
                                    {com.organization &&
                                      com.organization.location}
                                  </div>
                                </div>
                              </Col>
                              {/** 
                                                    <Col xl="2" className=''>
                                                        <div className='font-size-16'>
                                                            {com.ActiveJob}
                                                        </div>
                                                    </Col> */}
                              <Col xl="2" lg="2" className="">
                                <div className="font-size-12">{com.email}</div>
                              </Col>
                              <Col>
                                <div className="font-size-12">{com.phone}</div>
                              </Col>
                              {/* <Col xl="1" className=''>
                                                        <div className='font-size-16'>
                                                            {com.location}
                                                        </div>
                                                    </Col> */}
                              {/* <Col xl="1" className=''>
                                                        <div className='font-size-16'>
                                                            <span>
                                                                <i className={`dripicons-dot ${item.req_verification_status === 'Verified' ? 'light-green-text' :
                                                                    (item.req_verification_status === 'Failed' ? 'failed-text' : 'pedning-text')}`}></i>
                                                                <span className='light-green-text font-size-12 text px-1'>{com.req_verification_status} </span>
                                                            </span>
                                                        </div>
                                                    </Col>

                                                    <Col xl="1" className=''>
                                                        <Dropdown isOpen={item.com} toggle={() => changePendingMenuState(index, com)} className="d-inline-block" >
                                                            <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                                                                <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className='green-text-color' right>
                                                                <DropdownItem className='green-text-color' tag="a" href="#">View Profile</DropdownItem>
                                                                <DropdownItem className='green-text-color' tag="a" href="#">Select Candidate</DropdownItem>
                                                                <DropdownItem className='green-text-color' tag="a" href="#">Keep on hold</DropdownItem>
                                                                <DropdownItem className='green-text-color' tag="a" href="#" onClick={(e) => OpenRejectionModal(e)}>Reject Application</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </Col> */}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                : props.data.map((com, index) => {
                    // console.log(com);
                    return (
                      <div>
                        <Row key={index} className="mt-2 font-weight-normal">
                          <Col xl="12" lg="12" className="text-left">
                            <Row className="row-items green-text-color">
                              <Col xl="3" lg="3" className="">
                                <div className="font-size-16">
                                  <span>
                                    <img
                                      src={
                                        (com.organization &&
                                          com.organization.logo_url) ||
                                        profile
                                      }
                                      style={{ width: "36px", height: "36px" }}
                                    />
                                  </span>
                                  <span className="font-size-14 ml-2">
                                    {com.organization && com.organization.name}
                                  </span>
                                </div>
                              </Col>
                              <Col xl="2" lg="2" className="">
                                <div className="font-size-16">
                                  <div className="font-size-16">
                                    {com.organization &&
                                      com.organization.location}
                                  </div>
                                </div>
                              </Col>
                              {/** 
                                                    <Col xl="2" className=''>
                                                        <div className='font-size-16'>
                                                            {com.ActiveJob}
                                                        </div>
                                                    </Col> */}
                              <Col xl="2" lg="2" className="">
                                <div className="font-size-12">{com.email}</div>
                              </Col>
                              <Col>
                                <div className="font-size-12">{com.phone}</div>
                              </Col>
                              {/* <Col xl="1" className=''>
                                                        <div className='font-size-16'>
                                                            {com.location}
                                                        </div>
                                                    </Col> */}
                              {/* <Col xl="1" className=''>
                                                        <div className='font-size-16'>
                                                            <span>
                                                                <i className={`dripicons-dot ${item.req_verification_status === 'Verified' ? 'light-green-text' :
                                                                    (item.req_verification_status === 'Failed' ? 'failed-text' : 'pedning-text')}`}></i>
                                                                <span className='light-green-text font-size-12 text px-1'>{com.req_verification_status} </span>
                                                            </span>
                                                        </div>
                                                    </Col>

                                                    <Col xl="1" className=''>
                                                        <Dropdown isOpen={item.com} toggle={() => changePendingMenuState(index, com)} className="d-inline-block" >
                                                            <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                                                                <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className='green-text-color' right>
                                                                <DropdownItem className='green-text-color' tag="a" href="#">View Profile</DropdownItem>
                                                                <DropdownItem className='green-text-color' tag="a" href="#">Select Candidate</DropdownItem>
                                                                <DropdownItem className='green-text-color' tag="a" href="#">Keep on hold</DropdownItem>
                                                                <DropdownItem className='green-text-color' tag="a" href="#" onClick={(e) => OpenRejectionModal(e)}>Reject Application</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </Col> */}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
            </Col>
          )}
        </Row>
      </TabPane>
      <BlockUserModal
        modal_center={modal_center}
        centered={true}
        tog_center={() => tog_center()}
        onModalSubmit={rejectionSubmit}
      />
    </TabContent>
  );
};

export default ClientsTab;
