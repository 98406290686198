import React, { useState } from 'react';
import { TabContent, TabPane, Row, Col } from "reactstrap";
import classnames from 'classnames';
import getFormatedDate from '../../helpers/getFormatedDate';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Jobs = (props) => {

    return (
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="8" className="job-tab">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>
                                Jobs Applied
                            </h6>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2' style={{ minWidth: "1600px", overflow: "auto" }}>
                    <Col sm="12">
                        <Row className='row-header font-weight-medium font-size-16 border-bottom pb-1'>
                            <Col xl="3" className='font-size-14'>
                                Company
                            </Col>
                            <Col xl="2" className='font-size-14'>
                                Status
                            </Col>
                            <Col xl="3" className='font-size-14'>
                                Date of Job Application
                            </Col>
                        </Row>
                        {props.userData && props.userData.jobs &&
                            props.userData.jobs.map((item, index) => {
                                return (
                                    <Row key={index} className='mt-2 font-weight-normal'>
                                        <Col xl="12" className='pl-4 pr-4 text-left'>
                                            <Row className='row-items green-text-color cursor-pointer'>
                                                <Col xl="3" className='pl-0'>
                                                    {item.company}
                                                </Col>
                                                <Col xl="2">
                                                    {item.job_applications.status}
                                                </Col>
                                                <Col xl="3" className=''>
                                                    {item.job_applications.updatedAt ? getFormatedDate(item.job_applications.updatedAt, 'll') : '--'}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })

                        }
                    </Col>
                </Row>
            </TabPane>


        </TabContent>
    )
}


export default Jobs;