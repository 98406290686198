import React, { useState } from "react";
import {
    Form,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Modal,
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import { connect } from "react-redux";
import { inviteuser } from "../../redux/actions/dashboard";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import AddUserModal from "./AddUserModal";
import ApiService from "../../config/restapi";

const Users = (props) => {
    const [modal_center, setmodal_center] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    const handlesubmit = (data) => {
        let company = data.type === "client" ? data.company : "";
        // props.inviteuser(data.mail, data.type, company);
        let type = data.type === "user" ? "candidate" : data.type;
        ApiService.inviteUser(data.mail, type, company).then((res) => {
            if (res && res.status === 200) {
                if (res.validated || res.data.success == "true") {
                    tog_center();
                    toastr.success("Invitation Sent Successfully", "", { timeOut: 2000 });
                } else {
                    toastr.error(res.data.message, "", { timeOut: 2000 });
                }
            } else {
                toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            }
        });
    };
    var totalUsers = props.stats.candidates + props.stats.clients + props.stats.admin;
    if (totalUsers <= 0) {
    }
    return (
        <div className="user-card ml-3 ">
            <div className={`container py-4 ${props.active === "Users" ? "b-g-green" : ""}`}>
                <div className="row">
                    <div className={`ml-2  ${props.active === "Users" ? "f-color-white" : ""}`}>
                        <span className="font-size-24 font-weight-bold px-1">{totalUsers}</span>
                        <span>Users</span>
                    </div>
                    <div className="ml-auto">
                        <button
                            className="btn btn-outline-green btn-outline-shadow waves-effect waves-light font-size-12 mr-2 px-4 b-g-white"
                            type="submit"
                            onClick={() => {
                                tog_center();
                            }}
                        >
                            Invite Users
                        </button>
                        <AddUserModal
                            modal_center={modal_center}
                            centered={true}
                            tog_center={() => tog_center()}
                            onModalSubmit={handlesubmit}
                        />
                    </div>
                </div>
                <div className={`count_font mt-4 row pl-2 pt-3 ${props.active === "Users" ? "f-color-white" : ""}`}>
                    <span className="mr-1">
                        <b className="mx-1">{props.stats.candidates}</b>Candidates
                    </span>
                    <span className="mx-1">
                        |<b className="mx-1">{props.stats.clients}</b>Client HR's
                    </span>
                    <span className="mx-1">
                        |<b className="mx-1">{props.stats.admin}</b>Admins
                    </span>
                </div>
            </div>
        </div>
    );
};
const mapstateToprops = (state) => {
    return state;
};
export default connect(mapstateToprops, { inviteuser })(Users);
