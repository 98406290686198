import React, { useEffect, useState } from "react";
import { NavItem, NavLink, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import PersonalDetails from "./PersonalDetails";
import { withRouter } from "react-router-dom";
import ApiService from "../../../config/restapi";
import toastr from "toastr";

import getAge from "../../../helpers/getAge";
import Common from "../../../helpers/common";

const AboutYourselfWizard = (props) => {
  //Personal Dtails States

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [dobDate, setDobDate] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [Marital, setMarital] = useState();
  const [children, setChildren] = useState();
  const [nchild, setnchild] = useState();
  const [city, setcity] = useState();
  const [age, setAge] = useState();
  const [about, setabout] = useState();
  const [country, setCountry] = useState();
  const [countrycode, setCountryCode] = useState();
  const [profileData, setProfileData] = useState();

  const [activeTab, setactiveTab] = useState(1);

  function checkname(name) {
    if (name === "" || !name) {
      return { message: "Name should not be blank", valid: false };
    } else if (/^[a-zA-Z. ]+$/.test(name)) {
      return { message: "", valid: true };
    } else {
      return { message: "Please enter a valid name", valid: false };
    }
  }

  function checkEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return { message: "", valid: true };
    }
    return { message: "Please enter a valid email address", valid: false };
  }

  function checkDob(age) {
    if (age) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your date of birth", valid: false };
    }
  }

  function checkmobileNumber(number) {
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  function checkEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return { message: "", valid: true };
    }
    return { message: "Please enter a valid email address", valid: false };
  }

  function checkCity(value) {
    if (value === "" || !value || value === "Select") {
      return { message: "Select a city", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  const OnchangeCallBack = (value, type) => {
    console.log(value, type);
    switch (type) {
      case "name":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setName(value);
        break;
      case "email":
        value = Common.trimStart(value.replace(/[^\w\s\.\,@]/gi, ""));
        setEmail(value);
        break;
      case "mobile":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));
        setMobileNumber(value);
        break;
      case "Marital":
        setMarital(value);
        break;
      case "Children":
        if (value === "No") {
          setnchild(0);
          setChildren(false);
        } else {
          setChildren(value);
        }
        break;
      case "noofChildren":
        setnchild(value);
        break;
      case "city":
        if (value === "Select") {
          setcity("");
          break;
        }
        setcity(value);
        break;
      case "age":
        setAge(value);
        break;
      case "about":
        value = Common.trimStart(value.replace(/[^\w\s\.\,]/gi, ""));
        setabout(value);
        break;
      case "dob":
        setDobDate(value);
        setAge(getAge(value));
        break;
      case "country":
        if (value === "Select") {
          setCountry("");
          setcity("");
          break;
        }
        setCountry(value);
        break;
      case "isd_code":
        setCountryCode(value);
        break;
      default:
        break;
    }
  };

  const onSkipClick = (tab) => {
    props.onCompleteAboutYourselfCallBack();
  };

  const onNextClick = (tab) => {
    if (tab === 1) {
      let Obj = {
        name,
        email,
        age,
        city,
        country,
        children_count: nchild,
        has_children: children,
        phone: mobileNumber,
        marital_status: Marital,
        bio: about,
        date_of_birth: dobDate,
        isd_code: countrycode,
      };

      if (
        !checkname(name).valid ||
        !checkEmail(email).valid ||
        !checkmobileNumber(mobileNumber).valid ||
        !checkDob(age).valid ||
        !checkCity(city).valid ||
        !checkCity(country).valid
      ) {
        console.log(name, email, mobileNumber, age, city, country);
        if (name === null) setName("");
        if (city === null) setcity("");
        if (country === null) setCountry("");
        if (mobileNumber === null) setMobileNumber("");
        if (age === null) setAge("");
        toastr.error("Please enter all the required information.");
        return;
      }

      if (mobileNumber && mobileNumber.length === 10 && mobileNumber[0] >= 1) {
        if (age < 30) {
          toastr.error("Age must be greater than 30");
          return;
        }
        ApiService.saveOnboardingPersonalDetails(Obj).then((res) => {
          if (res && res.status === 200 && res.data) {
            console.log(res.data);
            onSkipClick();
          }
        });
      } else {
        toastr.error("Enter a valid 10 digit mobile number", "", {
          timeOut: 2000,
        });
      }
    }
  };

  function getProfileData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.data) {
        console.log(res.data);
        setName(res.data.name);
        setEmail(res.data.email);
        setMobileNumber(res.data.phone);
        setMarital(res.data.marital_status);
        setChildren(res.data.has_children);
        setnchild(res.data.children_count);
        setcity(res.data.city);
        setAge(res.data.age);
        setabout(res.data.bio);
        setDobDate(new Date(res.data.date_of_birth));
        setCountry(res.data.country);
        setCountryCode(res.data.isd_code);
        setProfileData({ ...res.data });
      }
    });
  }
  let dependency = 0;
  useEffect(() => {
    getProfileData();
  }, [dependency]);
  return (
    <div id="basic-pills-wizard" className="twitter-bs-wizard">
      <Card>
        <CardBody>
          {activeTab === 1 && (
            <PersonalDetails
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              mobileNumber={mobileNumber}
              name={name}
              email={email}
              marital={Marital}
              age={age}
              about={about}
              city={city}
              children={children}
              nchild={nchild}
              dobDate={dobDate}
              country={country}
              countrycode={countrycode}
            />
          )}
        </CardBody>
      </Card>
      <div className="text-right">
        <button
          className={classnames(
            "btn btn-outline-grey waves-effect waves-light w-lg mr-2"
          )}
          type="submit"
          onClick={() => onSkipClick(activeTab)}
        >
          Skip
        </button>
        <button
          className="btn bg-green waves-effect waves-light w-lg"
          type="submit"
          onClick={() => onNextClick(activeTab)}
        >
          {activeTab === 3 ? "Complete" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default withRouter(AboutYourselfWizard);
