import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Alert,
} from "reactstrap";
import UsersCard from "./UsersCard";
import JobsCard from "./JobsCard";
import AssessmentCard from "./AssessmentCard";
import PendingCard from "./Pending";
import UsersTable from "./UsersTable";
import JobsTable from "./Jobs/JobsTable";
import AssessmentTable from "./AssessmentTable";
import PendingTable from "./PendingTable";

import {
  fetchDashboard,
  fetchUser,
  changePage,
} from "../../redux/actions/dashboard";

const Dashboard = (props) => {
  useEffect(() => {
    props.fetchDashboard();
  }, []);
  const [tab, setTab] = useState("");
  if (props.loading) {
    return <Fragment></Fragment>;
  } else if (props.error != null) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="d-flex  justify-content-center">
              <Alert color="danger">{props.error}</Alert>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="page-content admin-dashboard-wrapper">
      <Row>
        <Col
          xl="6"
          lg="6"
          className="pl-4 pr-4 text-left green-text-color font-weight-medium"
        >
          Dashboard
        </Col>
      </Row>
      <Row className="mb-4 mt-2">
        <Col
          className="mobile_side_padding"
          xl="3"
          lg="3"
          onClick={(e) => {
            setTab("Users");
          }}
        >
          <UsersCard active={tab} stats={props.stats.users} />
        </Col>
        <Col
          className="mobile_side_padding"
          xl="3"
          lg="3"
          onClick={(e) => setTab("Jobs")}
        >
          <JobsCard
            active={tab}
            fetchJobs={props.stats.jobs}
            stats={props.stats.num_jobs}
          />
        </Col>
        <Col
          className="mobile_side_padding"
          xl="3"
          lg="3"
          onClick={(e) => setTab("Assessment")}
        >
          <AssessmentCard
            active={tab}
            fetchAssessment={"Add Assessments Redux"}
            state={props.stats.num_assessments}
          />
        </Col>
        <Col
          className="mobile_side_padding"
          xl="3"
          lg="3"
          onClick={(e) => setTab("Pending")}
        >
          <PendingCard
            active={tab}
            fetchJobs={props.stats.pending}
            state={props.stats.num_assessments}
          />
        </Col>
      </Row>
      {tab === "Users" ? (
        <UsersTable
          {...props}
          stats={props.stats}
          client={props.client}
          candidate={props.candidate}
          admin={props.admin}
          loading={props.loading_user}
          loaded={props.loaded_user}
          fetchUser={props.fetchUser}
          changePage={props.changePage}
        />
      ) : (
        ""
      )}
      {tab === "Jobs" ? <JobsTable {...props} /> : ""}
      {/* {tab === "Assessment" ? <AssessmentTable/> : "" */}
      {/* {tab === "Pending" ? <PendingTable {...props} /> : ""} */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state.dashboard,
  };
};
export default connect(mapStateToProps, {
  fetchDashboard,
  fetchUser,
  changePage,
})(Dashboard);
