import React from 'react';
import { FormGroup, Label, Input } from "reactstrap";

const JobRelated = (props) =>{

    return(
        <FormGroup className="text-left">
            <Label for="basicpill-firstname-input1"> {props.labelName} 
            {props.isOptional && <span className='font-size-11 font weight-light text-muted'> (Optional)</span>}
            </Label>
            <Input type="textarea" value={props.value ? props.value : '' } placeholder={props.placeholder ? props.placeholder : ''} maxLength={props.maxLength} onChange={(event)=>props.onChangeTextareaHandler(event)} />
            {props.isCharLimitShow && 
                <div className="text-right">
                    <span className="font-size-12 font-weight-light"> {props.charComplete}/{props.charlimitLength} </span>
                </div>
            }
        </FormGroup>
    )
}

export default  JobRelated;