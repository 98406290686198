export const EmploymentTypes = [
    {
        value: 1,
        label: 'Full-Time Employee'
    },
    {
        value: 2,
        label: 'Part-Time Employee'
    },
    {
        value: 3,
        label: 'Temporary Employee'
    },
    {
        value: 4,
        label: 'Seasonal Employee'
    }
];

export const Locations = [
    {
      "value": 1,
      "label": "Mumbai"
    },
    {
      "value": 2,
      "label": "Delhi"
    },
    {
      "value": 3,
      "label": "Bangalore"
    },
    {
      "value": 4,
      "label": "Hyderabad"
    },
    {
      "value": 5,
      "label": "Ahmedabad"
    },
    {
      "value": 6,
      "label": "Chennai"
    },
    {
      "value": 7,
      "label": "Kolkata"
    },
    {
      "value": 8,
      "label": "Surat"
    },
    {
      "value": 9,
      "label": "Pune"
    },
    {
      "value": 10,
      "label": "Jaipur"
    },
    {
      "value": 11,
      "label": "Lucknow"
    },
    {
      "value": 12,
      "label": "Kanpur"
    },
    {
      "value": 13,
      "label": "Nagpur"
    },
    {
      "value": 14,
      "label": "Indore"
    },
    {
      "value": 15,
      "label": "Thane"
    },
    {
      "value": 16,
      "label": "Bhopal"
    },
    {
      "value": 17,
      "label": "Visakhapatnam"
    },
    {
      "value": 18,
      "label": "Pimpri-Chinchwad"
    },
    {
      "value": 19,
      "label": "Patna"
    },
    {
      "value": 20,
      "label": "Vadodara"
    },
    {
      "value": 21,
      "label": "Ghaziabad"
    },
    {
      "value": 22,
      "label": "Ludhiana"
    },
    {
      "value": 23,
      "label": "Agra"
    },
    {
      "value": 24,
      "label": "Nashik"
    },
    {
      "value": 25,
      "label": "Faridabad"
    },
    {
      "value": 26,
      "label": "Meerut"
    },
    {
      "value": 27,
      "label": "Rajkot"
    },
    {
      "value": 28,
      "label": "Kalyan-Dombivali"
    },
    {
      "value": 29,
      "label": "Vasai-Viral"
    },
    {
      "value": 30,
      "label": "Varanasi"
    },
    {
      "value": 31,
      "label": "Srinagar"
    },
    {
      "value": 32,
      "label": "Aurangabad"
    },
    {
      "value": 33,
      "label": "Dhanbad"
    },
    {
      "value": 34,
      "label": "Amritsar"
    },
    {
      "value": 35,
      "label": "Navi Mumbai"
    },
    {
      "value": 36,
      "label": "Allahabad"
    },
    {
      "value": 37,
      "label": "Ranchi"
    },
    {
      "value": 38,
      "label": "Howrah"
    },
    {
      "value": 39,
      "label": "Coimbatore"
    },
    {
      "value": 40,
      "label": "Jabalpur"
    },
    {
      "value": 41,
      "label": "Gwalior"
    },
    {
      "value": 42,
      "label": "Vijayawada"
    },
    {
      "value": 43,
      "label": "Jodhpur"
    },
    {
      "value": 44,
      "label": "Madurai"
    },
    {
      "value": 45,
      "label": "Raipur"
    },
    {
      "value": 46,
      "label": "Kota"
    }
  ]
  
export const EducationList = [
  {
      value: 1,
      label: 'X'
  },
  {
      value: 2,
      label: 'XII'
  },
  {
      value: 3,
      label: 'Graduation and above'
  }
]