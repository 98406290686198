class Common {
  constructor() {
    this.loaderIns = null;
  }

  static setLoaderRef(loaderIns) {
    this.loaderIns = loaderIns;
  }

  static getLoaderRef() {
    return this.loaderIns;
  }

  static showLoader() {
    return this.loaderIns ? this.loaderIns.showLoader() : "";
  }
  static hideLoader() {
    return this.loaderIns ? this.loaderIns.hideLoader() : "";
  }

  static trimStart(str) {
    if (!str) return str;
    return str.replace(/^[\s\uFEFF\xA0]+/g, "");
  }

  static getSortedArray(arr, prop) {
    if (arr) {
      arr.sort(function (a, b) {
        if (a[prop] < b[prop]) {
          return -1;
        } else if (a[prop] > b[prop]) {
          return 1;
        } else {
          return 0;
        }
      });
      return arr;
    } else {
      return [];
    }
  }
}

export default Common;
