import React, { useState, useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Label,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";
import phone_icon from "../../assets/images/phone_icon.png";
import email_icon from "../../assets/images/email_icon.png";
import logo from "../../assets/images/Zunavish_Logo_SVG.svg";

import ApiService from "../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import default_company_logo from "../../assets/images/users/blank_profile.svg";

import SavedRows from './SavedRows';

const CompnaySizes = [
    {
        value: 1,
        label: "0-10 employees",
        modifyName: "Start up/ Small Enterprise",
    },
    {
        value: 2,
        label: "10-50 employees",
        modifyName: "Mid sized",
    },
    {
        value: 3,
        label: "50-200+ employees",
        modifyName: "Large scale/ Global",
    },
];

const Profile = ({ location }) => {
    const [modal_center, setmodal_center] = useState(false);
    const [modal_about, setmodal_about] = useState(false);
    const history = useHistory();
    // const [savedRowsEducation, setSavedRowsEducation] = useState([{
    //   degree:"B.Tech",
    //   courses:['Computer Science','Electronics and Communication'],
    //   registeredStudCount:200
    // }]);

    const [profile, setProfile] = useState({
        college: {},
        college_course: []
    });
    const [editCourseData, setEditCourseData] = useState();

    useEffect(() => {
        var params = location.pathname.split('/').slice(-1).pop()

        ApiService.getProfileCollegePublic(params).then((res) => {
            if (res && res.status === 200 && res.data) {
                console.log(res.data);
                setProfile(res.data.college);
            } else {
                toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            }
        });
    }, []);

    function tog_about() {
        setmodal_about(!modal_about);
        removeBodyCss();
    }

    const openModalAbout = () => {
        setEditCourseData('');
        tog_about();
    };

    const openEditModalAbout = (data, index) => {
        setEditCourseData(data)
        tog_about();
    };

    function updateAboutInfo() { }

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    const openModal = () => {
        tog_center();
    };




    function getModifyName(name) {
        let temModifyName = "";
        CompnaySizes.map((item) => {
            if (item.label === name) {
                temModifyName = item.modifyName;
            }
        });
        return temModifyName;
    }

    return (
        <React.Fragment>
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/dashboard" className="logo logo-dark">
                                    <img src={logo} alt="" />
                                </Link>

                                <Link to="/dashboard" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logo} alt="" height="19" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
            <div className="profile-page college-profile page-content my-5 pt-sm-5">
                <Container fluid>
                    <Row>
                        <Col xl="12" className="pl-0 pr-0">
                            <div className="top-container">
                                {console.log('profile', profile)}
                                <div className='ux-designer'> {profile.college.name}</div>
                                <div>
                                    <img
                                        className="ml-4 shift-image rounded-circle avatar-md"
                                        src={
                                            profile.college.logo_url ? profile.college.logo_url : default_company_logo
                                        }
                                        style={{ background: "#fff" }}
                                        alt="Header Avatar"
                                    />
                                </div>
                                <div className="d-flex justify-content-between company-info">
                                    <div className="d-flex mx-5 px-4 green-text-color mobile_profile_header">
                                        <div className="mr-3 mobile_profile_email">
                                            <div className="web_display">
                                                <div className="f-14 mb-2 px-2 mob_display">
                                                    <img src={email_icon} alt="email" />
                                                    <span className="ml-2">{profile.college.recruiter_email}</span>
                                                </div>
                                                <div className="ml-2 mob_display">
                                                    <img src={phone_icon} alt="email" />
                                                    <span className="ml-2">{profile.college.recruiter_phone}</span>
                                                </div>
                                            </div>

                                            <div className="web_display">
                                                <div className="f-14 mb-2 px-2 mob_display mobile_top_spacing">
                                                    {/* <img src={company_img} alt="company" /> */}
                                                    <i className="fas fa-map-marker-alt mb-2 pl-1 pr-2"></i>
                                                    <span className="ml-2">{profile.college.location ? profile.college.location : '--'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start position-relative">
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="about-section">
                        <Col xl="12" className="p-0">
                            <div style={{ marginTop: "16px" }} className="d-flex  justify-content-between">
                                <h4>
                                    Degrees and Courses
                                </h4>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" className='pl-0 pr-0'>
                            {profile.college_courses && profile.college_courses.length > 0 && profile.college_courses.map((data, index) => {
                                return (<SavedRows
                                    data={data}
                                    index={index}
                                    type="coursedegree"
                                    key={index}
                                    editRow={() => openEditModalAbout(data, index)}
                                />)
                            })}
                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Profile)
);
