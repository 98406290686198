export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';

const initialState = {
    loading: true,
    error: null,
    password : {},
}

const changepassword = (state = initialState, action) => {
    switch (action.type) {
        
        case CHANGE_PASSWORD:
            state = { loading: false, error: null, password : { ...action.payload } }
            break;
        case CHANGE_PASSWORD_LOADING:
            state = { ...state, loading: true };
            break;
        case CHANGE_PASSWORD_ERROR:
            state = { ...state, loading: false, error: action.payload };
            break;
        default:
            break;
    }
    return state;
}
export default changepassword;