import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// Redux
import { withRouter, Link } from 'react-router-dom';


// users
import user1 from '../../../assets/images/users/blank_profile.svg';

const ProfileMenu = (props) => {

   // Declare a new state variable, which we'll call "menu"
   const [menu, setMenu] = useState(false);
  
  return (
    <React.Fragment>
                <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="header-profile-user" src={user1} alt="Header Avatar" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {/* <DropdownItem tag="a"  href="/profile"> <i className="bx bx-user font-size-16 align-middle mr-1"></i>Profile</DropdownItem> */}
                        <DropdownItem tag="a" href="/setting"><i className="bx bx-cog font-size-17 align-middle mr-1"></i>Settings</DropdownItem>
                        <div className="dropdown-divider"></div>
                        <Link to="#" className="dropdown-item" onClick={()=>{
                            localStorage.removeItem('token');
                            props.history.push('/login')
                        }}>
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>Logout</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
  );
}


export default withRouter(ProfileMenu);

