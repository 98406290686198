import React,{Component} from 'react';
import './loader.css';

class Loader extends Component{

    render(){
        return(
            <div className="myload"> 
              <div className="loader" ></div>
            </div>
        );
    }

}
export default Loader;