import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ApiService from "../../config/restapi";
// Redux Store
import { toggleLeftmenu } from "../../redux/actions/layout";
import AddUserModal from "../../pages/Dashboard/AddUserModal";
import { withRouter, useHistory } from "react-router-dom";
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/Zunavish_Logo_SVG.svg";
import Common from "../../helpers/common";

const Header = (props) => {
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [menu, setMenu] = useState(false);

  const [menu1, setMenu1] = useState(false);
  const [joblist, setJobList] = useState("");

  const [candidatelist, setCandidateList] = useState("");
  const [modal_center, setmodal_center] = useState(false);
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
}
function removeBodyCss() {
    document.body.classList.add("no_padding");
}

const handlesubmit = (data) => {
  let company = data.type === "client" ? data.company : "";
  // props.inviteuser(data.mail, data.type, company);
  let type = data.type === "user" ? "candidate" : data.type;
  ApiService.inviteUser(data.mail, type, company).then((res) => {
      if (res && res.status === 200) {
          if (res.validated || res.data.success == "true") {
              tog_center();
              toastr.success("Invitation Sent Successfully", "", { timeOut: 2000 });
          } else {
              toastr.error(res.data.message, "", { timeOut: 2000 });
          }
      } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
  });
};

  function startSearch(value) {
    ApiService.getJobList(value).then((res) => {
      if (res && res.status === 200) {
        setJobList({ ...res.data });
      } else if (res.data.success === "false") {
        console.log(res);
        toastr.error(res.data.message, "", { timeOut: 2000 });
      } else {
        console.log(res);
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
    // ApiService.getCandidateList(value).then((res) => {
    //   if (res && res.status === 200) {
    //     setCandidateList({ ...res.data });
    //   } else {
    //     console.log(res);
    //     toastr.error("Something Went Wrong", "", { timeOut: 2000 });
    //   }
    // });
  }
  let history = useHistory();
  function toJobs(id) {
    history.push(`/jobdetails/${id}`);
    window.location.reload();
  }

  // const toJobs = (id) => {
  //   history.push(`/jobdetails/${id}`);
  // };
  // function check() {
  //   history.push("/setting");
  // }

  return (
    <React.Fragment>
      {console.log(candidatelist)}
      {console.log(joblist)}
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <img src={logo} alt="" />
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="19" />
                </span>
              </Link>
            </div>
            {/* <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => { props.toggleLeftmenu(!props.leftMenu); }}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button> */}
          </div>
          <div className="d-flex justify-content-start flex-grow-1 shift-right">
            <form
              className="app-search d-none d-lg-block"
              // onBlur={() => {
              //   setJobList("");
              //   setCandidateList("");
              // }}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Jobs, Assessments and Courses"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    console.log(e.target.value);
                    if (search) {
                      startSearch(e.target.value);
                    } else {
                      setJobList("");
                      setCandidateList("");
                    }
                  }}
                />
                <span className="bx bx-search-alt"></span>
              </div>
              {console.log(props)}
              <div
                className={
                  search && joblist
                    ? "dropdown-menu dropdown-menu-lg dropdown-search p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-search p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                {joblist.jobs ? (
                  <div
                    style={{
                      margin: "8px",
                      listStyle: "none",
                      color: "#000",
                      opacity: "0.8",
                    }}
                  >
                    {joblist &&
                      joblist.jobs &&
                      joblist.jobs.length > 0 &&
                      joblist.jobs
                        .filter((item) => {
                          if (search == "") {
                            console.log("IF");
                          } else if (
                            item.company
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            item.title
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          // console.log(item);
                          return (
                            <li
                              className="hoverChange"
                              key={index}
                              onClick={() => {
                                toJobs(item.id);
                              }}
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "inline",
                                    fontSize: "16px",
                                    marginRight: "2px",
                                  }}
                                >
                                  {item.title}
                                </div>
                              </div>
                              <div
                                style={{
                                  color:
                                    "rgba(156, 163, 175, var(--tw-text-opacity))",
                                }}
                              >
                                #{item.id}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#74b496",
                                }}
                              >
                                <i className="fas fa-map-marker-alt pr-2" />
                                {item.location || "Remote Job"}
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: "4px",
                                  borderTop: "1px solid #eeee",
                                }}
                              />
                            </li>
                          );
                        })}
                  </div>
                ) : (
                  <ul
                    style={{ listStyle: "none", color: "#000", opacity: "0.8" }}
                  >
                    <li
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {joblist.message}
                    </li>
                  </ul>
                )}
              </div>
            </form>
          </div>
          <div className="d-flex align-items-center justify-content-center font-size-14 header-font">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                onClick={() => setIsSearch(!isSearch)}
                // onBlur={() => setIsSearch(!isSearch)}
                // onChange={(e) => {
                //   setSearch(e.target.value);
                //   console.log(e.target.value);
                //   if (search) {
                //     startSearch();
                //   } else {
                //     setJobList("");
                //     setCandidateList("");
                //   }
                // }}
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form
                  className="p-3"
                  // onBlur={() => {
                  //   setJobList("");
                  //   setCandidateList("");
                  // }}
                >
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        aria-label="Recipient's username"
                        onChange={(e) => {
                          e.target.value = Common.trimStart(e.target.value);
                          setSearch(e.target.value);
                          console.log(e.target.value);
                          if (search) {
                            startSearch(e.target.value);
                          } else {
                            setJobList("");
                            setCandidateList("");
                          }
                        }}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      isSearch && search && joblist
                        ? "dropdown-menu dropdown-menu-lg dropdown-search p-0 show"
                        : "dropdown-menu dropdown-menu-lg dropdown-search p-0"
                    }
                    aria-labelledby="page-header-search-dropdown"
                  >
                    {joblist.jobs ? (
                      <ul
                        style={{
                          listStyle: "none",
                          color: "#000",
                          opacity: "0.8",
                        }}
                      >
                        {joblist &&
                          joblist.jobs &&
                          joblist.jobs.length > 0 &&
                          joblist.jobs
                            .filter((item) => {
                              if (search == "") {
                                console.log("IF");
                              } else if (
                                item.company
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                item.title
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return item;
                              }
                            })
                            .map((item, index) => {
                              console.log(item);
                              return (
                                <li
                                  key={index}
                                  onClick={() => {
                                    console.log("HI");
                                    toJobs(item.id);
                                  }}
                                  style={{
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.title}
                                </li>
                              );
                            })}
                        {/* {candidatelist &&
                          candidatelist.candidates &&
                          candidatelist.candidates.length > 0 &&
                          candidatelist.candidates
                            .filter((item) => {
                              if (search == "") {
                                console.log("IF");
                              } else if (
                                item.name
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                item.email
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return item;
                              }
                            })
                            .map((item, index) => {
                              console.log(item);
                              return (
                                <li
                                  key={index}
                                  onClick={() => {
                                    console.log("HI");
                                    toJobs(item.id);
                                  }}
                                  style={{
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.name}
                                </li>
                              );
                            })} */}
                      </ul>
                    ) : (
                      <ul
                        style={{
                          listStyle: "none",
                          color: "#000",
                          opacity: "0.8",
                        }}
                      >
                        <li
                          style={{
                            padding: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {joblist.message}
                        </li>
                      </ul>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="d-inline-block d-lg-none font-size-18 green-text-color cursor-pointer">
              <Dropdown
                isOpen={menu1}
                toggle={() => setMenu1(!menu1)}
                className="d-inline-block header-dropdown-add"
              >
                <DropdownToggle
                  className="btn header-item waves-effect"
                  tag="button"
                >
                  <i className="fa fa-angle-down header_down_arrow"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a" href="/dashboard">
                    Home
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="d-none d-lg-block">
              <NavLink
                className="header-item plus_sign"
                activeClassName="active"
                to="/dashboard"
              >
                Home
              </NavLink>
            </div>
            {/* <div className="d-inline-block pl-5">
                  <NavLink className="header-item" activeClassName="active" to="/assessment" >ASSESSMENTS</NavLink>                
              </div> */}
            {/* <div className="d-inline-block pl-5">
                  <NavLink className="header-item" activeClassName="active" to="/courses" >Courses</NavLink>                
              </div> */}
            <div className="d-inline-block font-size-18 green-text-color cursor-pointer">
              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block header-dropdown-add"
              >
                <DropdownToggle
                  className="btn header-item waves-effect"
                  tag="button"
                >
                  +
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a" href="#" onClick={() => {
                                tog_center();
                            }}>
                    User
                  </DropdownItem>
                  <AddUserModal
                            modal_center={modal_center}
                            centered={true}
                            tog_center={() => tog_center()}
                            onModalSubmit={handlesubmit}
                        />
                  <DropdownItem tag="a" href="/jobwizard">
                    Job
                  </DropdownItem>
                  <DropdownItem tag="a" href="#">
                    Assessment
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div
              className="mr-2"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/notifications")}
            >
              <NotificationDropdown />
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, { toggleLeftmenu })(Header);
