import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import ApiService from "../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import getFormatedDate from "../../helpers/getFormatedDate";
import { withRouter } from "react-router-dom";
import profile from "../../assets/images/user.png";

const assesmentList = {
  jobs: [
    {
      name: "Sai Ram abccbzj zxbzXV xbczxbc hzjckzhxvjk hzxkvjHkj",
      degree: "Macular Degree chxjxb bdjsabsd sjfsdkf sfjknsdfjk",
      type: "General",
      domain: "Design",
      skills_must: ["Design", "Problem Solving0", "test1", "test2"],
      requestedby: ["Naga Sai Ram", "Naga Sai Ram"],
      addedby: "Naga Sai Ram",
      addedon: "July 20, 2020",
    },
    {
      name: "Sai Ram",
      degree: "Macular Dege...",
      type: "General",
      domain: "Design",
      skills_must: ["Design", "Problem Solving0", "test1", "test2"],
      requestedby: ["Naga Sai Ram", "Naga Sai Ram"],
      addedby: "Naga Sai Ram",
      addedon: "July 20, 2020",
    },
    {
      name: "Sai Ram",
      degree: "Macular Dege...",
      type: "General",
      domain: "Design",
      skills_must: ["Design", "Problem Solving0", "test1", "test2"],
      requestedby: ["Naga Sai Ram", "Naga Sai Ram"],
      addedby: "Naga Sai Ram",
      addedon: "July 20, 2020",
    },
    {
      name: "Sai Ram",
      degree: "Macular Dege...",
      type: "General",
      domain: "Design",
      skills_must: ["Design", "Problem Solving0", "test1", "test2"],
      requestedby: [
        "Naga Sai Ram",
        "Naga Sai Ram",
        "Naga Sai Ram 3",
        "Naga Sai Ram 4",
      ],
      addedby: "Naga Sai Ram",
      addedon: "July 20, 2020",
    },
  ],
};

const AssessmentTable = (props) => {
  const [jobList, setJobList] = useState(assesmentList);

  function changeMenuState(index) {
    let tempData = { ...jobList };
    tempData.jobs[index].menu = !tempData.jobs[index]["menu"];
    setJobList({ ...tempData });
  }

  useEffect(() => { }, []);

  return (
    <React.Fragment>
      <div className="page-content job-dashbord mt-0 pt-0">
        <Row className="mt-0">
          <Col xl="2" className="pl-4 pr-4 text-left">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Assessments"
                  aria-label="Recipient's username"
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4" style={{ minWidth: "1600px", overflow: "auto" }}>
          <Col xl="12" className="pl-4 pr-4 text-left">
            <Row className="row-header font-weight-medium font-size-16">
              <Col xl="2" className="">
                Name
              </Col>
              <Col xl="1" className="">
                Type
              </Col>
              <Col xl="1" className="">
                Domain
              </Col>
              <Col xl="2" className="">
                Skill Tags
              </Col>
              <Col xl="2" className="">
                Requested by
              </Col>
              <Col xl="2" className="">
                Added by
              </Col>
              <div className="job-w">Added On</div>
              {/* <Col> 
                                <i className="bx bx-cog font-size-17 align-middle mr-1"></i>
                            </Col> */}
            </Row>
          </Col>
        </Row>
        {jobList &&
          jobList.jobs &&
          jobList.jobs.length > 0 &&
          jobList.jobs.map((item, index) => {
            return (
              <Row key={index} className="mt-2 font-weight-normal">
                <Col xl="12" className="pl-4 pr-4 text-left">
                  <Row className="row-items green-text-color cursor-pointer">
                    <Col xl="2" className="">
                      <div
                        className="font-size-16 cut-text-ellipsis"
                        title={item.degree}
                      >
                        {item.degree}
                      </div>
                      <div className="font-size-11 d-flex align-items-center">
                        <div className="avatar-xs d-flex align-items-center">
                          <span className="rounded-circle">
                            <img
                              src={profile}
                              alt=""
                              className="rounded-circle"
                              height="25"
                            />
                          </span>
                        </div>
                        <div
                          className="cut-text-ellipsis ml-1"
                          title={item.name}
                        >
                          {item.name}
                        </div>
                      </div>
                    </Col>
                    <Col xl="1" className="">
                      <div className="font-size-16">{item.type}</div>
                    </Col>
                    <Col xl="1" className="text-truncate">
                      <div className="font-size-16 cut-text-ellipsis">
                        {item.domain}
                      </div>
                    </Col>
                    <Col xl="2" className="">
                      <div className="font-size-16 d-flex">
                        {item && item.skills_must && (
                          <ul className="skills-learns pl-0 mb-0">
                            {item.skills_must &&
                              item.skills_must.slice(0, 2).map((item, idx) => {
                                return (
                                  <li
                                    className={classnames(
                                      "d-inline-block mr-2 skills-items skill-container"
                                    )}
                                    key={idx}
                                  >
                                    <span> {item} </span>
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                        {item.skills_must && item.skills_must.length > 2 && (
                          <span className="font-size-12 light-green-text mt-1">
                            {" "}
                            +
                            {
                              item.skills_must.slice(2, item.skills_must.length)
                                .length
                            }
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col xl="2" className="d-flex">
                      <div className="avatar-s">
                        <span className="rounded-circle">
                          <img
                            src={profile}
                            alt=""
                            className="rounded-circle"
                            height="45"
                          />
                        </span>
                      </div>
                      <div className="font-size-16 ml-1 cut-text-ellipsis">
                        {item.requestedby &&
                          item.requestedby.slice(0, 1).map((item, index) => {
                            return (
                              <span key={index}>{item ? item : "--"}</span>
                            );
                          })}
                        {item.requestedby && item.requestedby.length > 1 && (
                          <span className="font-size-12 light-green-text ml-1">
                            +
                            {
                              item.requestedby.slice(1, item.requestedby.length)
                                .length
                            }
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col xl="2" className="d-flex">
                      <div className="avatar-s">
                        <span className="rounded-circle">
                          <img
                            src={profile}
                            alt=""
                            className="rounded-circle"
                            height="45"
                          />
                        </span>
                      </div>
                      <div className="font-size-16 cut-text-ellipsis ml-1">
                        {item.addedby ? item.addedby : "--"}
                      </div>
                    </Col>
                    <div className="job-w">
                      <div className="font-size-16">
                        {/* {activeTab === '1' && (item.createdAt ? getFormatedDate(item.createdAt,'ll') : '--')} */}
                        {item.addedon}
                      </div>
                    </div>
                    <Col>
                      <Dropdown
                        isOpen={item.menu}
                        toggle={(event) => {
                          event.stopPropagation();
                          changeMenuState(index);
                        }}
                        className="d-inline-block"
                      >
                        <DropdownToggle
                          className="btn header-item waves-effect"
                          id="page-header-user-dropdown"
                          tag="button"
                        >
                          <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                        </DropdownToggle>
                        <DropdownMenu className="green-text-color" right>
                          <DropdownItem
                            className="green-text-color"
                            tag="a"
                            href="#"
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            className="green-text-color"
                            tag="a"
                            href="#"
                          >
                            Recommend to job post
                          </DropdownItem>
                          <DropdownItem
                            className="green-text-color"
                            tag="a"
                            href="#"
                          >
                            Delete
                          </DropdownItem>{" "}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        {jobList && jobList.jobs && jobList.jobs.length === 0 && (
          <Row className="mt-2 font-weight-normal">
            <Col xl="12" className="pl-4 pr-4 text-center">
              <div className="no-result">No Result</div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(AssessmentTable);
