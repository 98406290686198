import React, { useState } from 'react';
import { TabContent, TabPane, Row, Col } from "reactstrap";
import classnames from 'classnames';
import api from '../../config/axios';
import toastr from 'toastr';

const Certification = (props) => {

    const [savedRows, setSavedRows] = useState([]);
    const [modal_center, setmodal_center] = useState(false);
    const [skillsLearned, setSkillsLearned] = useState([])
    const [editedData, setEditedData] = useState();
    const [editIndex, setEditIndex] = useState();


    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }


    const openModal = () => {
        if (editedData) setEditedData('');
        tog_center();
    }

    const editRow = (index) => {
        const tempEditedData = props.savedRowsCertificates.filter((v) => {
            console.log(v.id);
            return (v.id === index)

        });
        console.log(tempEditedData);
        if (tempEditedData.length > 0) setEditedData(tempEditedData[0]);
        setEditIndex(index);
        tog_center(true);

    }

    const deleteRow = (index) => {
        var tempERow = [...props.savedRowsCertificates];
        if (index > -1) {
            tempERow = tempERow.filter((e) => e.id !== index);
        }
        api.delete(`/api/certificates/delete/${index}`, {
            data: {
                id: index
            },
            headers: {
                token: `${localStorage.getItem("token")}`,
                'Access-Control-Allow-Origin': '*'
            },

        }).then((res) => {
            toastr.success("Certificate Deleted Successfully!", "", { timeOut: 2000 });
        }).catch((e) => {
            toastr.error("Something Went Wrong", "", { timeOut: 2000 });

        });
        props.setSavedRowsCertificate(tempERow);
    }

    const updateCouse = (data, type) => {
        if (type === 'edit') {
            props.editCertificate(data);
            tog_center(false);
        }
        else if (type === 'save') {
            props.createCertificate(data, 'certificate');
            tog_center(false);
        }
    }

    return (
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="7" className="courses-tab">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>
                                Certificates
                            </h6>

                        </div>
                    </Col>
                </Row>
                <Row>
                    {props.savedRowsCertificates.length > 0 && props.savedRowsCertificates.map((data, index) => {
                        return (
                            <Col key={data.id} lg="12" className="added-row mt-2">
                                <Row>
                                    <Col lg="10" className="">
                                        <span className='font-weight-bold'> {data.title}</span>
                                        {/* <span className='font-weight-light font-size-11 pl-2'> {data.score}% marks </span> */}
                                    </Col>
                                    <Col lg="2" className="text-right">
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg="8" >
                                        {data.topic ?
                                            <ul className="skills-learns mb-0">

                                                <li className={classnames("d-inline-block mr-3 cursor-pointer")}>
                                                    <span className="skills-items"> {data.topic}</span>
                                                </li>

                                            </ul> : ""
                                        }
                                    </Col>
                                    {data.link ?
                                        <Col lg="4" className="text-right">
                                            <a href={data.link} target="_blank">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-shadow waves-effect w-xs waves-light font-size-12"
                                                >
                                                    VIEW CERTIFICATE
                                    </button>
                                            </a>
                                            {/* <span className="cursor-pointer text-under-line">VIEW COURSE CERTIFICATE</span> */}
                                        </Col>
                                        : ""
                                    }
                                </Row>
                            </Col>
                        )
                    })}
                </Row>
            </TabPane>

        </TabContent>
    )
}

export default Certification;