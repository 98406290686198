import React from "react";
const Users = (props) => {
    return (
        <div className={`user-card ml-3 ${props.active === "Assessment" ? "b-g-green" : ""}`}>
            <div className="container py-3">
                <div className="row">
                    <div className={`ml-2 font-size-18  ${props.active === "Assessment" ? "f-color-white" : ""}`}>
                        Assessment Tests
                    </div>
                    {/* <div className="ml-auto">
                        <button
                            className=" btn btn-outline-green  btn-outline-shadow waves-effect waves-light font-size-12 mr-2 px-4 b-g-white"
                            type="submit"
                        >
                            Add
                        </button>
                    </div> */}
                </div>
                <div className={`row ${props.active === "Assessment" ? "f-color-white" : ""} `}>
                    {/* <span className="mx-auto font-size-24 font-weight-bold">Comming Soon</span> */}
                    <span className="mx-auto mt-2  font-size-14 font-weight-bold">Coming Soon</span>
                </div>
                {/* <div className={` row pl-2 pt-3 ${props.active==="Assessment"? "f-color-white":""}`}>
    <span className="mr-1"><b className="mx-1">4</b>Candidates</span>
    <span className="mx-1">|<b  className="mx-1">3</b>Client HR's</span>
    <span className="mx-1">|<b  className="mx-1">4</b>Admins</span>
</div> */}
            </div>
        </div>
    );
};
export default Users;
