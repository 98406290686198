import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BlockUserModal from "./BlockUserModal";
import { withRouter, useHistory } from "react-router-dom";

import profile from "../../assets/images/users/blank_profile.png";
import Api from "../../config/restapi";
import toastr from "toastr";
import Common from "../../helpers/common";

const ApplicationTab = (props) => {
  const [penddingList, setPenddingList] = useState([]);
  const [modal_center, setmodal_center] = useState(false);
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);
  let history = useHistory();

  function startSearch(value) {
    Api.getStudentSearchList(value).then((res) => {
      if (res && res.status === 200) {
        setSearchList({ ...res.data });
      } else if (res.data.success === "false") {
        console.log(res);
        // toastr.error(res.data.message, "", { timeOut: 2000 });
      } else {
        console.log(res);
        // toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    props.fetchUser(
      "student",
      props.data.length % 10 == 0
        ? props.data.length / 10
        : Math.floor(props.data.length / 10) + 1
    );
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      props.loading ||
      props.loaded
    )
      return;
    if (!props.loading) {
      props.fetchUser(
        "student",
        props.data.length % 10 == 0
          ? props.data.length / 10
          : Math.floor(props.data.length / 10) + 1
      );
    }
  };

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const rejectionSubmit = (data) => {
    tog_center();
  };

  function changePendingMenuState(index) {
    let tempData = props.data ? [...props.data] : [];
    tempData[index].menu = !tempData[index]["menu"];
    setPenddingList([...tempData]);
  }
  function accept_background_verification(id) {
    Api.accept_background_verification(id).then((res) => {
      if (res && res.status === 200) {
        toastr.success("Profile verified");
      } else {
        toastr.error("Something went wrong");
      }
    });
  }

  function reject_background_verification(id) {
    Api.reject_background_verification(id).then((res) => {
      if (res && res.status === 200) {
        toastr.success("Profile Marked as Unverified");
      } else {
        toastr.error("Something went wrong");
      }
    });
  }
  function remind(id) {
    Api.remind(id).then((res) => {
      if (res && res.status === 200) {
        toastr.success("Sent Reminder Successfully");
      } else {
        toastr.error("Something went wrong");
      }
    });
  }
  return (
    <TabContent className="mt-3" activeTab={props.activeTab}>
      <TabPane tabId="5" className="application-tab">
        <Row>
          <Col xl="2" className="text-left">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Students Name"
                  aria-label="Recipient's username"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    if (!search) {
                      console.log("  ");
                    } else {
                      startSearch(search);
                    }
                    // console.log(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2" style={{ minWidth: "1600px", overflow: "auto" }}>
          {props.isMobile ? (
            <Col xl="12" lg="12" className="text-left">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"> Candidate Name</th>
                      <th scope="col"> Skills</th>
                      <th scope="col"> Active Job Applications</th>
                      <th scope="col"> Highest Education</th>
                      <th scope="col"> Job Role</th>
                      <th scope="col"> Contact Info</th>
                      <th scope="col"> Location</th>
                      <th scope="col"> Background Check</th>
                      <th className="text-center">
                        <i className="bx bx-cog font-size-17 align-middle"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {search && searchList
                      ? search &&
                        searchList &&
                        searchList.students &&
                        searchList.students
                          // .filter((item) => {
                          //   if (search == "") {
                          //     return item;
                          //   } else if (
                          //     item.name
                          //       .toString()
                          //       .toLowerCase()
                          //       .includes(search.toLowerCase())
                          //   ) {
                          //     return item;
                          //   }
                          // })
                          .map((item, index) => {
                            // console.log(item);
                            return (
                              <tr key={index}>
                                <td>
                                  <span>
                                    <img
                                      src={
                                        item.image_url
                                          ? item.image_url
                                          : profile
                                      }
                                      style={{ width: "36px", height: "36px" }}
                                    />
                                  </span>
                                  <div className="font-size-14 mobile_candidate_name">
                                    {item.name}
                                  </div>
                                </td>
                                <td></td>
                                <td>
                                  <div className="font-size-14">
                                    {item.ActiveJob}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-14">
                                    {item.HighestDegree}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-14">
                                    {item.designation ? item.designation : ""}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-14">
                                    {item.email}
                                  </div>
                                  <div className="font-size-14">
                                    {item.phone}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-14">
                                    {item.city}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-16">
                                    <span>
                                      <i
                                        className={`dripicons-dot ${
                                          item.is_verified === true
                                            ? "light-green-text"
                                            : item.is_verified === false
                                            ? "failed-text"
                                            : "pedning-text"
                                        }`}
                                      ></i>
                                      <span className="light-green-text font-size-12 px-1 text">
                                        {item.is_verified}{" "}
                                      </span>
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <Dropdown
                                    isOpen={item.menu}
                                    toggle={() => changePendingMenuState(index)}
                                    className="d-inline-block"
                                  >
                                    <DropdownToggle
                                      className="btn header-item waves-effect"
                                      id="page-header-user-dropdown"
                                      tag="button"
                                    >
                                      <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="green-text-color"
                                      right
                                    >
                                      <DropdownItem
                                        className="green-text-color"
                                        onClick={() =>
                                          history.push(
                                            `/public/candidate/profile/${item.id}`
                                          )
                                        }
                                      >
                                        View Profile
                                      </DropdownItem>
                                      <DropdownItem
                                        className="green-text-color"
                                        onClick={() =>
                                          accept_background_verification(
                                            item.id
                                          )
                                        }
                                      >
                                        Accept Background Verification
                                      </DropdownItem>
                                      <DropdownItem
                                        className="green-text-color"
                                        onClick={() =>
                                          reject_background_verification(
                                            item.id
                                          )
                                        }
                                      >
                                        Reject Background Verification
                                      </DropdownItem>
                                      <DropdownItem
                                        className="green-text-color"
                                        onClick={() => remind(item.id)}
                                      >
                                        Complete Profile Reminder
                                      </DropdownItem>
                                      {/* <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e)}
                                  >
                                    Block User
                                  </DropdownItem> */}
                                    </DropdownMenu>
                                  </Dropdown>
                                </td>
                              </tr>
                            );
                          })
                      : penddingList &&
                        props.data
                          // .filter((item) => {
                          //   if (search == "") {
                          //     return item;
                          //   } else if (
                          //     item.name
                          //       .toString()
                          //       .toLowerCase()
                          //       .includes(search.toLowerCase())
                          //   ) {
                          //     return item;
                          //   }
                          // })
                          .map((item, index) => {
                            // console.log(item);
                            return (
                              <tr key={index}>
                                <td>
                                  <span>
                                    <img
                                      src={
                                        item.image_url
                                          ? item.image_url
                                          : profile
                                      }
                                      style={{ width: "36px", height: "36px" }}
                                    />
                                  </span>
                                  <div className="font-size-14 mobile_candidate_name">
                                    {item.name}
                                  </div>
                                </td>
                                <td></td>
                                <td>
                                  <div className="font-size-14">
                                    {item.ActiveJob}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-14">
                                    {item.HighestDegree}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-14">
                                    {item.designation ? item.designation : ""}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-14">
                                    {item.email}
                                  </div>
                                  <div className="font-size-14">
                                    {item.phone}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-14">
                                    {item.city}
                                  </div>
                                </td>
                                <td>
                                  <div className="font-size-16">
                                    <span>
                                      <i
                                        className={`dripicons-dot ${
                                          item.is_verified === true
                                            ? "light-green-text"
                                            : item.is_verified === false
                                            ? "failed-text"
                                            : "pedning-text"
                                        }`}
                                      ></i>
                                      <span className="light-green-text font-size-12 px-1 text">
                                        {item.is_verified}{" "}
                                      </span>
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <Dropdown
                                    isOpen={item.menu}
                                    toggle={() => changePendingMenuState(index)}
                                    className="d-inline-block"
                                  >
                                    <DropdownToggle
                                      className="btn header-item waves-effect"
                                      id="page-header-user-dropdown"
                                      tag="button"
                                    >
                                      <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="green-text-color"
                                      right
                                    >
                                      <DropdownItem
                                        className="green-text-color"
                                        tag="a"
                                        href={`/public/candidate/profile/${item.id}`}
                                        target="_blank"
                                      >
                                        View Profile
                                      </DropdownItem>

                                      {/* <DropdownItem
                                              className="green-text-color"
                                              tag="a"
                                              href="#"
                                              onClick={(e) => OpenRejectionModal(e)}
                                            >
                                              Block User
                                            </DropdownItem> */}
                                    </DropdownMenu>
                                  </Dropdown>
                                </td>
                              </tr>
                            );
                          })}
                  </tbody>
                </table>
              </div>
            </Col>
          ) : (
            <Col xl="12" lg="12" className="text-left">
              <Row className="row-header font-weight-medium font-size-16">
                <Col xl="2" lg="2" className="font-size-14">
                  Candidate Name
                </Col>
                <Col xl="2" lg="2" className="font-size-14">
                  Skills
                </Col>
                <Col xl="1" lg="1" className="font-size-14">
                  Active Job Applications
                </Col>
                <Col xl="1" lg="1" className="font-size-14">
                  Highest Education
                </Col>
                <Col xl="1" lg="1" className="font-size-14">
                  Job Role
                </Col>
                <Col xl="2" lg="2" className="font-size-14">
                  Contact Info
                </Col>
                <Col xl="1" lg="1" className="font-size-14">
                  Location
                </Col>
                <Col xl="1" lg="1" className="font-size-14">
                  Background Check
                </Col>
                {/* <Col className="setting_w">
                                    <i className="bx bx-cog font-size-17 align-middle"></i>
                                </Col> */}
              </Row>

              {search && searchList
                ? search &&
                  searchList &&
                  searchList.students &&
                  searchList.students
                    // .filter((item) => {
                    //   if (search == "") {
                    //     return item;
                    //   } else if (
                    //     item.name
                    //       .toString()
                    //       .toLowerCase()
                    //       .includes(search.toLowerCase())
                    //   ) {
                    //     return item;
                    //   }
                    // })
                    .map((item, index) => {
                      // console.log(item);
                      return (
                        <Row key={index} className="mt-2 font-weight-normal">
                          <Col xl="12" lg="12" className="text-left">
                            <Row className="row-items green-text-color">
                              <Col xl="2" lg="2" className="">
                                <span>
                                  <img
                                    src={
                                      item.image_url ? item.image_url : profile
                                    }
                                    style={{ width: "36px", height: "36px" }}
                                  />
                                </span>
                                <span className="font-size-14 ml-2">
                                  {item.name}
                                </span>
                              </Col>
                              <Col xl="2" lg="2" className="">
                              <ul className="skill-tags-container pl-0 mb-0">
                              {item.skills.length>0?(<><li className="d-inline-block mr-2 skill-tag" >{JSON.parse(item.skills[0]).label}</li> 
                                {item.skills[1]?(<li className="d-inline-block mr-2 skill-tag" >{JSON.parse(item.skills[1]).label}</li>):""}
                                {item.skills.length>2?(<span className= "ml-1 px-1" >+{item.skills.length-2}</span>):""}</>):""}
                              
                              </ul>
                              </Col>
                              <Col xl="1" lg="1" className="">
                              <div className="font-size-14">
                                {item.jobs && item.jobs.length>0 && item.jobs.filter((item)=>{return item.status==="open"}).length}
                              </div>
                              </Col>

                              <Col xl="1" lg="1" className="">
                                <div className="font-size-14">
                                <div className="font-size-14">
                              {item.educations && item.educations.length>0 && item.educations[0].course}
                              </div>
                                </div>
                              </Col>
                              <Col xl="1" lg="1" className="">
                                <div className="font-size-14">
                                  {item.designation ? item.designation : ""}
                                </div>
                              </Col>
                              <Col xl="2" lg="2" className="">
                                <div className="font-size-14">{item.email}</div>
                                <div className="font-size-14">{item.phone}</div>
                              </Col>
                              <Col xl="1" lg="1" className="">
                                <div className="font-size-14">{item.city}</div>
                              </Col>
                              <Col xl="1" lg="1" className="">
                                <div className="font-size-16">
                                  <span>
                                    <i
                                      className={`dripicons-dot ${
                                        item.is_verified === true
                                          ? "light-green-text"
                                          : item.is_verified === false
                                          ? "failed-text"
                                          : "pedning-text"
                                      }`}
                                    ></i>
                                    <span className="light-green-text font-size-12 px-1 text">
                                      {item.is_verified}{" "}
                                    </span>
                                  </span>
                                </div>
                              </Col>
                              <Col className="setting_w">
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={() => changePendingMenuState(index)}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    <DropdownItem
                                      className="green-text-color"
                                      onClick={() =>
                                        history.push(
                                          `/public/candidate/profile/${item.id}`
                                        )
                                      }
                                    >
                                      View Profile
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      onClick={() =>
                                        accept_background_verification(item.id)
                                      }
                                    >
                                      Accept Background Verification
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      onClick={() =>
                                        reject_background_verification(item.id)
                                      }
                                    >
                                      Reject Background Verification
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      onClick={() => remind(item.id)}
                                    >
                                      Complete Profile Reminder
                                    </DropdownItem>
                                    {/* <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e)}
                                  >
                                    Block User
                                  </DropdownItem> */}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    })
                : penddingList &&
                  props.data
                    // .filter((item) => {
                    //   if (search == "") {
                    //     return item;
                    //   } else if (
                    //     item.name
                    //       .toString()
                    //       .toLowerCase()
                    //       .includes(search.toLowerCase())
                    //   ) {
                    //     return item;
                    //   }
                    // })
                    .map((item, index) => {
                      // console.log(item);
                      return (
                        <Row key={index} className="mt-2 font-weight-normal">
                          <Col xl="12" lg="12" className="text-left">
                            <Row className="row-items green-text-color">
                              <Col xl="2" lg="2" className="">
                                <span>
                                  <img
                                    src={
                                      item.image_url ? item.image_url : profile
                                    }
                                    style={{ width: "36px", height: "36px" }}
                                  />
                                </span>
                                <span className="font-size-14 ml-2">
                                  {item.name}
                                </span>
                              </Col>
                              <Col xl="2" lg="2" className="">
                              <ul className="skill-tags-container pl-0 mb-0">
                              {item.skills.length>0?(<><li className="d-inline-block mr-2 skill-tag" >{JSON.parse(item.skills[0]).label}</li> 
                                {item.skills[1]?(<li className="d-inline-block mr-2 skill-tag" >{JSON.parse(item.skills[1]).label}</li>):""}
                                {item.skills.length>2?(<span className= "ml-1 px-1" >+{item.skills.length-2}</span>):""}</>):""}
                              
                              </ul>
                              </Col>
                              <Col xl="1" lg="1" className="">
                                <div className="font-size-14">
                                {item.jobs && item.jobs.length>0 && item.jobs.filter((item)=>{return item.status==="open"}).length}
                                </div>
                              </Col>

                              <Col xl="1" lg="1" className="">
                                
                                <div className="font-size-14" style={{    wordBreak: "break-all"}}>
                              {item.educations && item.educations.length>0 && item.educations[0].course}
                              </div>
                                
                              </Col>
                              <Col xl="1" lg="1" className="">
                                <div className="font-size-14">
                                  {item.designation ? item.designation : ""}
                                </div>
                              </Col>
                              <Col xl="2" lg="2" className="">
                                <div className="font-size-14">{item.email}</div>
                                <div className="font-size-14">{item.phone}</div>
                              </Col>
                              <Col xl="1" lg="1" className="">
                                <div className="font-size-14">{item.city}</div>
                              </Col>
                              <Col xl="1" lg="1" className="">
                                <div className="font-size-16">
                                  <span>
                                    <i
                                      className={`dripicons-dot ${
                                        item.is_verified === true
                                          ? "light-green-text"
                                          : item.is_verified === false
                                          ? "failed-text"
                                          : "pedning-text"
                                      }`}
                                    ></i>
                                    <span className="light-green-text font-size-12 px-1 text">
                                      {item.is_verified}{" "}
                                    </span>
                                  </span>
                                </div>
                              </Col>
                              <Col className="setting_w">
                                <Dropdown
                                  isOpen={item.menu}
                                  toggle={() => changePendingMenuState(index)}
                                  className="d-inline-block"
                                >
                                  <DropdownToggle
                                    className="btn header-item waves-effect"
                                    id="page-header-user-dropdown"
                                    tag="button"
                                  >
                                    <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="green-text-color"
                                    right
                                  >
                                    <DropdownItem
                                      className="green-text-color"
                                      onClick={() =>
                                        history.push(
                                          `/public/candidate/profile/${item.id}`
                                        )
                                      }
                                    >
                                      View Profile
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      onClick={() =>
                                        accept_background_verification(item.id)
                                      }
                                    >
                                      Accept Background Verification
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      onClick={() =>
                                        reject_background_verification(item.id)
                                      }
                                    >
                                      Reject Background Verification
                                    </DropdownItem>
                                    <DropdownItem
                                      className="green-text-color"
                                      onClick={() => remind(item.id)}
                                    >
                                      Complete Profile Reminder
                                    </DropdownItem>
                                    {/* <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e)}
                                  >
                                    Block User
                                  </DropdownItem> */}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
            </Col>
          )}
        </Row>
      </TabPane>
      <BlockUserModal
        modal_center={modal_center}
        centered={true}
        tog_center={() => tog_center()}
        onModalSubmit={rejectionSubmit}
      />
    </TabContent>
  );
};

export default ApplicationTab;
