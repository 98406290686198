import api from "../../config/axios";
import {

    INVITE_USER,
    INVITE_SUCCESS,
    CHANGE_USER_PAGE,
    API_ERROR,
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_DASHBOARD,
    FETCH_DASHBOARD_SUCCESS,
    FETCH_JOBS,
    FETCH_JOBS_SUCCESS

} from "../dashboard";
export const inviteuser = (mail, type, company) => async dispatch => {
    dispatch({
        type: INVITE_USER,
    });

    try {
        const response = await api.post('/api/admin/users/invite', { email: mail, role: type, company });
        if (response.status === 200) {
            if (response.data.success) {

                //history.push('/aboutyourself');
            } else {
                dispatch({
                    type: API_ERROR,
                });
            }

        }
        else {
            dispatch({
                type: API_ERROR,
            });
        }
    }
    catch {
        dispatch({
            type: API_ERROR,
        });
    }

}
export const fetchDashboard = () => async dispatch => {
    dispatch({ type: FETCH_DASHBOARD });
    try {
        const response = await api.get('/api/admin/dashboard',{
            headers: {
                token: localStorage.getItem('token'),
            }
        });
        if (response.status === 200) {
            if (response.data.success) {
                dispatch({
                    type: FETCH_DASHBOARD_SUCCESS,
                    payload: {
                        ...response.data,
                    }
                })

            } else {
                dispatch({
                    type: API_ERROR,
                });
            }

        }
        else {
            dispatch({
                type: API_ERROR,
            });
        }
    }
    catch {
        dispatch({
            type: API_ERROR,
        });
    }

}

export const changePage = () => dispatch => {
    dispatch({ type: CHANGE_USER_PAGE });
}

export const fetchUser = (role, page) => async dispatch => {
    dispatch({ type: FETCH_USER, payload: role });
    try {
        const response = await api.get('/api/admin/users?type=' + role + '&page='+page, {
            headers: {
                token: localStorage.getItem('token'),
            }
        });
        if (response.status === 200) {
            if (response.data.success) {
                var payload = {};
                payload[role] = response.data.users;
                dispatch({
                    type: FETCH_USER_SUCCESS,
                    payload: {
                        role: role,
                        users:response.data.users,
                        loading_user: false,
                        loaded_user: (response.data.users ? (response.data.users.length === 0?true: false):true),

                    }
                })

            } else {
                dispatch({
                    type: API_ERROR,
                });
            }

        }
        else {
            dispatch({
                type: API_ERROR,
            });
        }
    }
    catch {
        dispatch({
            type: API_ERROR,
        });
    }

}
export const fetchjobs = (status, offset) => async dispatch => {
    dispatch({ type: FETCH_JOBS, payload: status});
    try {
        const response = await api.get('/api/admin/users?status=' +status , {
            headers: {
                token: localStorage.getItem('token'),
            }
        });
        if (response.status === 200) {
            if (response.data.success) {
                dispatch({
                    type: FETCH_JOBS_SUCCESS,
                    payload: {
                        status,
                        users: response.data.users,
                    }
                })

            } else {
                dispatch({
                    type: API_ERROR,
                });
            }

        }
        else {
            dispatch({
                type: API_ERROR,
            });
        }
    }
    catch {
        dispatch({
            type: API_ERROR,
        });
    }

}