import React, { useState, useEffect } from "react";
import { NavItem, NavLink, Card, CardBody, Row, Col } from "reactstrap";
import logo from "../../../assets/images/Zunavish_Logo_SVG.svg";
import JobForm from "./JobForm";
import classnames from "classnames";
import ApiService from "../../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Locations, EducationList } from "../../../helpers/constant_variables";
import Common from "../../../helpers/common";

const EmploymentTypes = [
  {
    value: 1,
    label: "Full-Time Employee",
  },
  {
    value: 2,
    label: "Part-Time Employee",
  },
  {
    value: 3,
    label: "Temporary Employee",
  },
  {
    value: 4,
    label: "Seasonal Employee",
  },
];

const Domains = [
  {
    value: 1,
    label: "IT/ITES",
  },
  {
    value: 2,
    label: "E-COM",
  },
  {
    value: 3,
    label: "Health care",
  },
  {
    value: 4,
    label: "Infrastructure",
  },
  {
    value: 5,
    label: "Banking and Finance",
  },
  {
    value: 6,
    label: "Others",
  },
];

const DiversityList = [
  {
    value: 1,
    label: "Male",
  },
  {
    value: 2,
    label: "Female",
  },
  {
    value: 3,
    label: "NA",
  },
];

const MaxAgeList = [
  {
    value: 1,
    label: "20",
  },
  {
    value: 2,
    label: "30",
  },
  {
    value: 3,
    label: "40",
  },
  {
    value: 4,
    label: "50",
  },
];

const MinAgeList = [
  {
    value: 1,
    label: "18",
  },
  {
    value: 2,
    label: "21",
  },
  {
    value: 3,
    label: "30",
  },
  {
    value: 4,
    label: "40",
  },
];

const AboutYourself = (props) => {
  const wizardHeadersNames = [
    { name: "Job details", key: "pd-width" },
    { name: "Select Assessment Tests (Coming Soon)", key: "ed-width" },
  ];

  const [wizardHeaders, setwizardHeaders] = useState([]);
  const [activeTab, setactiveTab] = useState(1);

  const [roleCharComplete, setRoleCharComplete] = useState(0);
  const [otherDetailsCharComplete, setOtherDetailsCharComplete] = useState(0);
  const [specificationCharComplete, setSpecificationCharComplete] = useState(0);
  const [orgList,setOrgList] = useState([])
  const [orgObj,setOrgObj] = useState({})

  useEffect(() => {
    ApiService.getOrganizationsList().then((res) => {
      let newarr = res.data.map((org,index)=>{
        return{
          value:org.id,
          label:org.name
        }
      })
      setOrgList(newarr);
    }).catch(error=>{
      console.log(error)
    })
  }, [])

  const [jobfields, setJobfields] = useState({
    roleandResponsibilty: "",
    otherDetails: "",
    specification: "",
    title: "",
    company: "",
    orgId:"",
    location: "",
    isRemoteLoc: false,
    locationDisabled: false,
    emp_type: "",
    skills_good: [],
    skills_must: [],
  });

  const onChangeTextareaHandler = (event, type) => {
    if (
      type === "roles" ||
      type === "otherdetails" ||
      type === "specification"
    ) {
      // var charCount = event.target.value.length;
      var regex = /\s+/gi;
      event.target.value = Common.trimStart(event.target.value);
      var WordCount =
        event.target.value === ""
          ? 0
          : event.target.value.trim().replace(regex, " ").split(" ").length;
    }
    switch (type) {
      case "roles":
        event.target.value = Common.trimStart(
          event.target.value.replace(/[^\w\s]/gi, "")
        );
        if (WordCount <= 500){
          setRoleCharComplete(WordCount);
          setJobfields({
            ...jobfields,
            roleandResponsibilty: event.target.value,
          });
        } 
        break;
        
      case "otherdetails":
        event.target.value = Common.trimStart(
          event.target.value.replace(/[^\w\s]/gi, "")
        );
        if (WordCount <= 500){
          setOtherDetailsCharComplete(WordCount);
          setJobfields({ ...jobfields, otherDetails: event.target.value });
        }
        break;
      case "specification":
        event.target.value = Common.trimStart(
          event.target.value.replace(/[^\w\s]/gi, "")
        );
        if (WordCount <= 500){
          setSpecificationCharComplete(WordCount);
          setJobfields({ ...jobfields, specification: event.target.value });
        } 
        break;
      case "title":
        event.target.value = Common.trimStart(
          event.target.value.replace(/[^a-zA-Z\s]/gi, "")
        );
        setJobfields({ ...jobfields, title: event.target.value });
        break;
      case "company":
        console.log(event.label,event)
        setJobfields({ ...jobfields, company: event.label,orgId:event.value });
        setOrgObj(event)
        break;
      case "location":
        setJobfields({
          ...jobfields,
          location: event.label,
          locationObj: event,
        });
        break;
      case "remote":
        if (event.target.checked) {
          setJobfields({
            ...jobfields,
            locationDisabled: true,
            location: "",
            locationObj: { label: "Select" },
            isRemoteLoc: event.target.checked,
          });
        } else {
          setJobfields({
            ...jobfields,
            locationDisabled: false,
            isRemoteLoc: event.target.checked,
          });
        }
        break;
      case "emptype":
        setJobfields({
          ...jobfields,
          emp_type: event.label,
          emp_type_obj: event,
        });
        break;
      case "diversity":
        setJobfields({
          ...jobfields,
          diversity: event.label,
          diversityObj: event,
        });
        break;
      case "domain":
        setJobfields({ ...jobfields, domain: event.label, domainObj: event, d_skills: [...event.skills], });
        
        break;
      case "maxage":
        setJobfields({ ...jobfields, max_age: event.label, maxAgeObj: event });
        break;
      case "minage":
        setJobfields({ ...jobfields, min_age: event.label, minAgeObj: event });
        break;
      case "minexp":
        setJobfields({ ...jobfields, minexp: event.target.value });
        break;
      case "maxexp":
        setJobfields({ ...jobfields, maxexp: event.target.value });
        break;
      case "edetails":
        setJobfields({
          ...jobfields,
          education_details: event.label,
          edetailsObj: event,
        });
        break;
      case "mustskills":
        const tempData = [...event];
        setJobfields({
          ...jobfields,
          skills_must: [...tempData],
        });
        break;
      case "goodskills":
        const tempData1 = [...event];
        setJobfields({
          ...jobfields,
          skills_good: [...tempData1],
        });
        break;
      default:
        break;
    }
  };

  const RemoveSkill = (index, type) => {
    if (index > -1) {
      if (type === "mustskills") {
        const tempData = { ...jobfields };
        tempData.skills_must && tempData.skills_must.splice(index, 1);
        setJobfields({
          ...tempData,
        });
      } else {
        const tempData = { ...jobfields };
        tempData.skills_good && tempData.skills_good.splice(index, 1);
        setJobfields({
          ...tempData,
        });
      }
    }
  };



  useEffect(() => {
    if (jobfields.domain) {
      const getDomainIndex = findWithAttr(Domains, "label", jobfields.domain);
      const results = Domains[getDomainIndex].skills.filter((person) => {
        if (jobfields.mustSkillVal) {
          return person.label.toLowerCase().includes(jobfields.mustSkillVal);
        } else if (jobfields.goodSkillVal) {
          return person.label.toLowerCase().includes(jobfields.goodSkillVal);
        } else {
          return Domains[getDomainIndex].skills;
        }
      });
      setJobfields({
        ...jobfields,
        d_skills: results,
      });
    }

    wizardHeadersNames.map((obj, index) => {
      if (activeTab === index + 1) {
        obj["active"] = "active";
        obj["key"] = wizardHeadersNames[index].key;
      } else {
        delete obj.active;
        delete obj.key;
      }
      if (activeTab === 2) {
        if (activeTab === 3)
          wizardHeadersNames[activeTab - 3]["solidImage"] = "solid-line-circle";
        wizardHeadersNames[activeTab - 2]["solidImage"] = "solid-line-circle";
        wizardHeadersNames[activeTab - 2]["lessWidthD"] = "less-width-d";
      }
    });
    setwizardHeaders([...wizardHeadersNames]);
    if (props.location && props.location.state) {
      let empIndex = findWithAttr(
        EmploymentTypes,
        "label",
        props.location.state.employment_type
      );
      let domianIndex = findWithAttr(
        Domains,
        "label",
        props.location.state.domain
      );
      let diversityIndex = findWithAttr(
        DiversityList,
        "label",
        props.location.state.diversity
      );
      let minIndex = findWithAttr(
        MinAgeList,
        "label",
        props.location.state.min_age && props.location.state.min_age.toString()
      );
      let maxIndex = findWithAttr(
        MaxAgeList,
        "label",
        props.location.state.max_age && props.location.state.max_age.toString()
      );
      let locationIndex = findWithAttr(
        Locations,
        "label",
        props.location.state.location
      );
      let ed_Index = findWithAttr(
        EducationList,
        "label",
        props.location.state.education_level
      );

      setJobfields({
        ...props.location.state,
        roleandResponsibilty: props.location.state.roles,
        otherDetails: props.location.state.job_details,
        specification: props.location.state.perks,
        emp_type_obj: EmploymentTypes[empIndex],
        domainObj: Domains[domianIndex],
        diversityObj: DiversityList[diversityIndex],
        minAgeObj: MinAgeList[minIndex],
        maxAgeObj: MaxAgeList[maxIndex],
        locationObj: Locations[locationIndex],
        isRemoteLoc: props.location.state.is_remote_loc,
        minexp: props.location.state.min_exp,
        maxexp: props.location.state.max_exp,
        edetailsObj: EducationList[ed_Index],
        locationDisabled: props.location.state.is_remote_loc,
      });
    }
  }, [activeTab]);

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  const PublishJob = () => {
    let obj = {
      ...jobfields,
      roles: jobfields.roleandResponsibilty,
      job_details: jobfields.otherDetails,
      perks: jobfields.specification,
      employment_type: jobfields.emp_type,
      status: "open",
      min_exp: jobfields.minexp,
      max_exp: jobfields.maxexp,
      education_level: jobfields.education_details,
      is_remote_loc: jobfields.isRemoteLoc,
    };
    if (props.location && props.location.state) {
      obj.id = Number(props.location.state.id);
      ApiService.updateJobStatus(obj).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          toastr.success("Job Published Successfully.", "", { timeOut: 2000 });
          props.history.push("/dashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    } else {
      ApiService.addJobPost(obj).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          props.history.push("/dashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    }
  };

  const SaveDraft = () => {
    let obj = {
      ...jobfields,
      roles: jobfields.roleandResponsibilty,
      job_details: jobfields.otherDetails,
      perks: jobfields.specification,
      employment_type: jobfields.emp_type,
      status: "incomplete",
      min_exp: jobfields.minexp,
      max_exp: jobfields.maxexp,
      education_level: jobfields.education_details,
      is_remote_loc: jobfields.isRemoteLoc,
    };
    if (props.location && props.location.state) {
      obj.id = props.location.state.id;
      ApiService.updateJobStatus(obj).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          toastr.success("Job Updated Successfully.", "", { timeOut: 2000 });
          props.history.push("/dashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    } else {
      ApiService.addJobPost(obj).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          props.history.push("/dashboard");
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    }
  };

  const WizardHeaders = (item1, idx) => {
    return (
      <NavItem key={idx}>
        <NavLink className={`text-center ${item1.active ? item1.active : ""}`}>
          <span
            className={classnames(
              "step-number mr-1",
              { [`${item1.key}`]: item1.key },
              { [`${item1.lessWidthD}`]: item1.lessWidthD },
              { [`${item1.solidImage}`]: item1.solidImage }
            )}
          >
            {idx + 1}
          </span>
          {item1.name ? (
            <div className={classnames("font-size-14 mt-2")}>
              {" "}
              {item1.name}{" "}
            </div>
          ) : (
            ""
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content p-0 mt-0 job-assessment">
        <div className="logo-fixed">
          <a class="logo logo-dark" href="/dashboard">
            <img src={logo} alt="logo"></img>
          </a>
        </div>
        <div className="about-page-auth">
          <Row>
            <Col xs="12" xl="3" sm="3"></Col>
            <Col xs="12" xl="6" sm="6" className="text-center">
              <h5 className="mb-0 font-size-24">New Job</h5>
              <div
                id="basic-pills-wizard"
                className="twitter-bs-wizard job-wizard"
              >
                <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified mb-3">
                  {wizardHeaders.map((item, idx) => WizardHeaders(item, idx))}
                </ul>
                <div className="mt-2">{props.title}</div>
                <Card className="mt-4">
                  <CardBody>
                    {activeTab === 1 ? (
                      <JobForm
                        activeTab={activeTab}
                        jobfields={jobfields}
                        onChangeTextareaHandler={onChangeTextareaHandler}
                        roleCharComplete={roleCharComplete}
                        setRoleCharComplete={setRoleCharComplete}
                        setOtherDetailsCharComplete={setOtherDetailsCharComplete}
                        setSpecificationCharComplete={setSpecificationCharComplete}
                        otherDetailsCharComplete={otherDetailsCharComplete}
                        specificationCharComplete={specificationCharComplete}
                        RemoveSkill={RemoveSkill}
                        orgList={orgList}
                        orgObj={orgObj}
                        setJobfields={setJobfields}
                        findWithAttr={findWithAttr}
                      />
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xs="12" xl="3" sm="3"></Col>
          </Row>
          <div className="job-footer text-right">
            <button
              className="btn btn-outline-green waves-effect waves-light w-lg mr-2 mt-2"
              type="button"
              disabled={
                !jobfields.title ||
                (jobfields.location
                  ? !jobfields.location
                  : !jobfields.isRemoteLoc) ||
                !jobfields.company
                  ? true
                  : false
              }
              onClick={() => SaveDraft()}
            >
              Save as draft
            </button>
            <button
              className="btn bg-green waves-effect waves-light w-lg mr-2 mt-2"
              type="button"
              disabled={
                !jobfields.title ||
                !jobfields.domain ||
                !jobfields.roleandResponsibilty ||
                (jobfields.maxexp && jobfields.minexp?!(Number(jobfields.minexp)<Number(jobfields.maxexp)):false) ||
                (jobfields.location
                  ? !jobfields.location
                  : !jobfields.isRemoteLoc) ||
                !jobfields.company
                  ? true
                  : false
              }
              onClick={() => PublishJob()}
            >
              Publish without Assessment
            </button>
            {/* <button className="btn bg-green waves-effect waves-light w-lg mt-2 mr-2"
                            type="button"
                        >Proceed to Add Assessment</button> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutYourself;
